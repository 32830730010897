import L from 'leaflet';
import $ from 'jquery';

import sidebar_html from './sidebar.html';

const SIDEBAR_CONTAINER_ID = 'sidebar-container';
const SIDEBAR_CONTENT_ID = 'sidebar-content';
const SIDEBAR_BUTTON_ID = 'sidebar-button';

/**
 * Create and control a sidebar for leaflet
 * Currently just a wrapper for leaflet-sidebar, with helper functions
 */
export class Sidebar {
	private _hidden: boolean = true;
	public panels: {};

	constructor(options?: any) {

		// The sidebar button
		let div_button = L.DomUtil.create('button','sidebar-button',document.body);
		div_button.id = SIDEBAR_BUTTON_ID;
		div_button.type = 'button';
		div_button.innerHTML = 'Settings';
		div_button.onclick = () => {
			this.show();
		};

	    // The sidebar (with content)
	    let div_sidebar = L.DomUtil.create('div','sidebar-container-hidden',document.body);
		div_sidebar.id = SIDEBAR_CONTAINER_ID;
	    div_sidebar.innerHTML = sidebar_html;
	    document.getElementById('sidebar-close-button').onclick = ()=>{this.hide()};
	}

	get div_button(): HTMLElement {
		return document.getElementById(SIDEBAR_BUTTON_ID);
	}

	get div_sidebar(): HTMLElement {
		return document.getElementById(SIDEBAR_CONTAINER_ID);
	}

	get div_sidebar_content(): HTMLElement {
		return document.getElementById(SIDEBAR_CONTENT_ID);
	}

	/**
	 * Add a menu item ("pane") in the sidebar.  Note this repo switches 
	 * between "pane" (the verbiage of the original library "sidebar-v2") 
	 * and "panel" (that used exclusively in the "leaflet-sidebar-v2" fork).
	 * In this repo we'll use "panel"
	 *   
	 * @param {[type]} options: L.Control.PanelOptions [description]
	 */
	
	public addPanel(options: any) {
	    console.log('adding Panel:');
	    console.log(options);
	    let d = document.createElement('div');
	    return this.div_sidebar.appendChild(d);
	}

	public toggle() {
		console.log('toggling...');
		if (this._hidden) {
			this.show();
		} else {
			this.hide();
		}
	}

	public hide() {
		this.div_sidebar.className = 'sidebar-container-hidden';		
		this.div_button.className = 'sidebar-button';
		this._hidden = true;
	}

	public show(id?: string) {
	    this.div_sidebar.className = 'sidebar-container';
	    this.div_button.className = 'sidebar-button-hidden';
		this._hidden = false;
	}

	public panel(id: string) {
		let panel = undefined;
		/*
		for (let i=0; i<this._sidebar._panes.length; i++) {
			if(this._sidebar._panes[i].id===id) {
				panel = this._sidebar._panes[i];
				break;
			}
		}
		*/
		return panel;
	}


	/**
	 * Creates a dropdown
	 * @param {any[]}    values       The values that can be selected in the dropdown
	 * @param {any}      defaultValue The default value (can be an array of defaults).  Can also be a default label (or array of labels)
	 * @param {string[]} labels       Labels for the values.  If none, then values.toString() are used
	 * @param {string} id       	  HTML id to assign to this HTML Element
	 * @param {Function} callback     Function to call when the dropdown is changed.
	 * @return {HTMLElement}       	  A <select class='form-control'> element
	 */
	public dropdown(values: any[], defaultValue: any, labels?: string[], id?: string, callback?: Function, multiple?: boolean, selectHTMLElement?: HTMLElement): HTMLElement {
		let sel = selectHTMLElement;
		if (sel == undefined) {
	  		sel = document.createElement('select');
		}
		sel.className = 'form-control';
		if (multiple) {
			sel.multiple = true;
		}

		if (!Array.isArray(defaultValue)) {
			defaultValue = [defaultValue];
		}
		for (let i=0; i<values.length; i++) {
			let value = values[i];
			let label = (labels && labels[i])? labels[i] : value.toString();
			let opt = document.createElement('option','sidebar-text');
			opt.innerHTML = label;
			opt.value = value;
			opt.selected = (defaultValue.indexOf(value)>-1) || (defaultValue.indexOf(label)>-1);
			sel.appendChild(opt);
		}	
		// add optional ID
		if (id) {
			sel.id = id;
		}
		// add optional callback
		if (callback) {
			sel.addEventListener("change", function(evt) {
				let chosen = [];
				for (let i=0; i<this.options.length; i++) {
					let opt = this.options[i];
					if (opt.selected) {
						chosen.push({text: opt.text, value: values[i]});
					}
				}
				callback(chosen);
			});
		}
		
		//Makes this work on IE10 Touch devices by stopping it from firing a mouseout event when the touch is released
		//sidebar.div.setAttribute('aria-haspopup', true); // NO! this makes sidebar disappear when try to interact with it!

		return sel;
	}

	public numberBox(label: string, value: string, id?: string, callback?: Function, boxHTMLElement?: HTMLElement): HTMLElement {
		let box = boxHTMLElement;
		if (box == undefined) {			
			box = document.createElement('input');
		}

		box.className = 'form-control';
		box.type = 'number';
		box.value = value;
		// add optional ID
		if (id) {
			box.id = id;
		}
		// add optional callback
		if (callback) {
			box.addEventListener("change", function(evt) {
				callback(this.value);
			});
		}

		return box;
	}

	public checkBox(parent: HTMLElement, label: string, checked: boolean, id?: string, callback?: Function) {
		if (!id) {
			id = (label+'checkbox').replace(/\s/g,'_');
		}
		id = id.replace(/\s/g,'_');

		// Create a div and get it on the page so that we can use getElementById on its children elements
		let sourceFormGroup = document.createElement('div');
		sourceFormGroup.id = id;
		parent.appendChild(sourceFormGroup);

		/*		
		let cb = document.createElement('div');
		cb.className = 'form-check';
		let inp = document.createElement('input');
		inp.className = 'form-check-input';
		inp.type = 'checkbox';
		inp.checked = checked;
		inp.id = 'sdhfasdfasdf';
		cb.appendChild(inp);
		let lbl = document.createElement('label');
		lbl.className = 'form-check-label sidebar-check-label';
		lbl.innerText = label;
		cb.appendChild(lbl);
		inp.addEventListener("change", function(evt) {
			callback(evt.target.checked);
		});
		sourceFormGroup.appendChild(cb);
		*/

		// make unique checkbox id, for later manipulation:	
		let inpId = (id + Date.now()).replace(/\s/g,'_');
		// create form
		sourceFormGroup.innerHTML = `
		<div class="form-check">
			<input class="form-check-input" type="checkbox" id=${inpId}>
			<label class="form-check-label sidebar-check-label">${label}</label>
		</div>
		`;
		// get the input and add the callbacks and other things we can't do in HTML
		let inp = document.getElementById(inpId);
		inp.checked = checked;
		inp.addEventListener("change", function(evt) {
			console.log('Changed!');
			console.log(evt);
			callback(evt.target.checked);
		});
		
		return sourceFormGroup; // return checkbox, in case someone wants to manipulate it further
	}

	/**
	 * [text description]
	 * @param  {string}      s     [description]
	 * @param  {string}      level [description]
	 * @return {HTMLElement}       [description]
	 */
	public text(s: string, level: string): HTMLElement {
		// utility function to add a header label
		var header = document.createElement(level);
		header.innerHTML = s;
		return header;
	}
}
