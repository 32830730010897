// This file creates the definitions for the weather layers.
import {sprintf} from 'sprintf-js';

import {
	ColorScale, 
	NullColorScale
} from './color-scale';

export interface iDatum {
	label: string,
	value?: any,
	units?: string
}

/**
 * These are the enums that identify the different types of data we may grab from data sources.
 * We are using the enum type here (instead of just doing string comparisons) because when new
 * data types are added, DataSource and Collection classes may need to be edited to support them.
 * Note the style VAR="VAR" is a string enum, which is just easier to debug than an int enum.
 * @type {Object}
 */
export enum wzDataEnums {
	AIR_TEMPERATURE = "AIR_TEMPERATURE",
	BATTERY_TEMPERATURE = "BATTERY_TEMPERATURE",
	BATTERY_PERCENT = "BATTERY_PERCENT",
	BATTERY_VOLTAGE = "BATTERY_VOLTAGE",
	BAROMETRIC_PRESSURE = "BAROMETRIC_PRESSURE",
	STATION_PRESSURE = "STATION_PRESSURE",
	HUMIDITY = "HUMIDITY",
	WIND_SPEED = "WIND_SPEED",
	PEAK_GUST_SPEED = "PEAK_GUST_SPEED",
	WIND_DIRECTION = "WIND_DIRECTION",

	SPECIFIC_HUMIDITY = "SPECIFIC_HUMIDITY",
	PRESSURE_AT_SEA_LEVEL = "PRESSURE_AT_SEA_LEVEL",
	ABSOLUTE_HUMIDITY = "ABSOLUTE_HUMIDITY",
	DEW_POINT = "DEW_POINT",

	SEA_SURFACE_TEMPERATURE = "SEA_SURFACE_TEMPERATURE",
	WAVE_HEIGHT = "WAVE_HEIGHT",
	AVERAGE_WAVE_PERIOD = "AVERAGE_WAVE_PERIOD",
	DOMINANT_WAVE_PERIOD = "DOMINANT_WAVE_PERIOD",
	DOMINANT_WAVE_DIRECTION = "DOMINANT_WAVE_DIRECTION",

	IMAGE = "IMAGE",
	AUDIO = "AUDIO",

	INPUT_VISIBILITY = "INPUT_VISIBILITY",
	INPUT_MOBILITY = "INPUT_MOBILITY",
	INPUT_SUN = "INPUT_SUN",
	INPUT_PRECIPITATION = "INPUT_PRECIPITATION",
	INPUT_WIND = "INPUT_WIND",
	INPUT_PRESENTWEATHER = "INPUT_PRESENTWEATHER",
	INPUT_CLOUDTYPE = "INPUT_CLOUDTYPE",
	INPUT_CLOUDHEIGHT = "INPUT_CLOUDHEIGHT",
	INPUT_NOTES = "INPUT_NOTES",

	OW_PAR0_NEG = "OW_PAR0_NEG",
	OW_TRANSMISSION = "OW_TRANSMISSION",
	OW_SALINITY = "OW_SALINITY",
	OW_O2_SATURATION = "OW_O2_SATURATION",
	OW_DEPTH = "OW_DEPTH",
	OW_NITRATE = "OW_NITRATE",
	OW_CURRENT_DIRECTION = "OW_CURRENT_DIRECTION",
	OW_O2_PERCENT_SATURATION = "OW_O2_PERCENT_SATURATION",
	OW_PAR0_PLUS = "OW_PAR0_PLUS",
	OW_CDOM = "OW_CDOM",
	OW_CONDUCTIVITY = "OW_CONDUCTIVITY",
	OW_PRECIP = "OW_PRECIP",
	OW_CURRENT_SPEED = "OW_CURRENT_SPEED",
	OW_CHLOROPHYLL = "OW_CHLOROPHYLL",
	OW_DISSOLVED_O2 = "OW_DISSOLVED_O2",
	OW_TIDAL_HEIGHT = "OW_TIDAL_HEIGHT",
	OW_TEMPERATURE = "OW_TEMPERATURE",
	OW_PH = "OW_PH",
	OW_TURBIDITY = "OW_TURBIDITY",

	GEOLOCATION_BEARING = "GEOLOCATION_BEARING",
	GEOLOCATION_ALTITUDE = "GEOLOCATION_ALTITUDE",
	//GEOLOCATION_ERROR = "GEOLOCATION_ERROR",
	//GEOLOCATION_LONG = "GEOLOCATION_LONG",
	//GEOLOCATION_LAT = "GEOLOCATION_LAT",
	GEOLOCATION_SPEED = "GEOLOCATION_SPEED",
	GEOLOCATION_ACCURACY = "GEOLOCATION_ACCURACY",

	SENSOR_ORIENTATION = "SENSOR_ORIENTATION",
	SENSOR_AZIMUTH = "SENSOR_AZIMUTH",
	SENSOR_PITCH = "SENSOR_PITCH",
	SENSOR_ROLL = "SENSOR_ROLL",
	SENSOR_ACCELEROMETER = "SENSOR_ACCELEROMETER",
	//SENSOR_ROTATION_VECTOR = "SENSOR_ROTATION_VECTOR",
	SENSOR_MAGNETIC_FIELD = "SENSOR_MAGNETIC_FIELD",
	//SENSOR_PROXIMITY = "SENSOR_PROXIMITY",
	SENSOR_GRAVITY = "SENSOR_GRAVITY",
	SENSOR_LINEAR_ACCELERATION = "SENSOR_LINEAR_ACCELERATION",
	SENSOR_LIGHT = "SENSOR_LIGHT",
	//SENSOR_GYROSCOPE = "SENSOR_GYROSCOPE",
	//SENSOR_MAGNETIC_FIELD_UNCAL = "SENSOR_MAGNETIC_FIELD_UNCAL",
	//SENSOR_STEP_COUNTER = "SENSOR_STEP_COUNTER",

	FULL_REPORT = "FULL_REPORT",
}

var CONVERSIONS = {
	// pressure:
	'Pa': {'type': 'pressure', 'label': 'Pa', 'magnitude': 1, 'refval': 0},
	'hPa': {'type': 'pressure', 'label': 'hPa', 'magnitude': 1/100, 'refval': 0},
	'kPa': {'type': 'pressure', 'label': 'kPa', 'magnitude': 1/1000, 'refval': 0},
	'bar': {'type': 'pressure', 'label': 'bar', 'magnitude': 1/100000, 'refval': 0},
	'mbar': {'type': 'pressure', 'label': 'mbar', 'magnitude': 1/100, 'refval': 0},
	'atm': {'type': 'pressure', 'label': 'atm', 'magnitude': 1/101325, 'refval': 0},
	'psi': {'type': 'pressure', 'label': 'psi', 'magnitude': 1/6894.76, 'refval': 0},
	'inHg': {'type': 'pressure', 'label': 'inHg', 'magnitude': 1/3386.39, 'refval': 0},
	// distance:
	'm': {'type': 'distance', 'label': 'm', 'magnitude': 1, 'refval': 0},
	'km': {'type': 'distance', 'label': 'km', 'magnitude': 1/1000, 'refval': 0},
	'ft': {'type': 'distance', 'label': 'ft', 'magnitude': 3.28084, 'refval': 0},
	'kft': {'type': 'distance', 'label': 'kft', 'magnitude': 3.28084/1000, 'refval': 0},
	'yd': {'type': 'distance', 'label': 'yd', 'magnitude': 1.09361, 'refval': 0},
	'mi': {'type': 'distance', 'label': 'mi', 'magnitude': 1/1609.34, 'refval': 0},
	'nmi': {'type': 'distance', 'label': 'nmi', 'magnitude': 1/1852,  'refval': 0},
	// speed:
	'm/s': {'type': 'speed', 'label': 'm/s', 'magnitude': 1, 'refval': 0},
	'mph': {'type': 'speed', 'label': 'mph', 'magnitude': 2.23694, 'refval': 0},
	'kt': {'type': 'speed', 'label': 'kt', 'magnitude': 1.94384, 'refval': 0},
	'ft/s': {'type': 'speed', 'label': 'ft/s', 'magnitude': 3.28084, 'refval': 0},
	'kph': {'type': 'speed', 'label': 'kph', 'magnitude': 3.6, 'refval': 0},
	// temperature:
	'C': {'type': 'temperature', 'label': '&deg;C', 'magnitude': 1, 'refval': 0},
	'K': {'type': 'temperature', 'label': 'K', 'magnitude': 1, 'refval': 273.15},
	'F': {'type': 'temperature', 'label': '&deg;F', 'magnitude': 1.8, 'refval': 32},
	'R': {'type': 'temperature', 'label': 'R', 'magnitude': 1.8, 'refval': 459.67},
	// fraction:
	'fraction': {'type': 'fraction', 'label': '', 'magnitude': 1, 'refval': 0 },
	'%': {'type': 'fraction', 'label': '%', 'magnitude': 100, 'refval': 0 },
	'ppt': {'type': 'fraction', 'label': 'ppt', 'magnitude': 1000, 'refval': 0 },
	'ppm': {'type': 'fraction', 'label': 'ppm', 'magnitude': 1e6, 'refval': 0 },
	'ppb': {'type': 'fraction', 'label': 'ppb', 'magnitude': 1e9, 'refval': 0 },
	// heading:
	'degrees': {'type': 'heading', 'label': '&deg;', 'magnitude': 180, 'refval': 0},
	'radians': {'type': 'heading', 'label': 'rad', 'magnitude': 3.1415926, 'refval': 0},
	// illuminance:
	'lux': {'type': 'illuminance', 'label': 'lux', 'magnitude': 1, 'refval': 0},
	// turbidity:
	'NTU': {'type': 'turbidity', 'label': 'NTU', 'magnitude': 1, 'refval': 0},
	// salinity:
	'PSU': {'type': 'salinity', 'label': 'PSU', 'magnitude': 1, 'refval': 0},
	'g/kg': {'type': 'salinity', 'label': 'g/kg', 'magnitude': 1, 'refval': 0},
};



/**
 * DataLayer class.  Instances of this class define how a given data type should be labeled in any selector menus,
 * and define how the values should be mapped to colors and displayed in the legend.
 */
export class DataLayer {
	public dataType: wzDataEnums;
	public label: string;
	public categoryName: string;
	public colorScale: ColorScale;
	public overlays: any[] | null;
	public afwaLayer: any[] | null;
	public showLegend: boolean = true;
	public units: string | undefined;

	private conversions: {} = CONVERSIONS;

	/**
	 * @param {string}
	 * @param {string}
	 * @param {string}
	 * @param {ColorScale}
	 * @param {any[]}
	 * @param {any[]}
	 */
	constructor(dataType: wzDataEnums, label: string, categoryName: string, units?: string, showLegend?: boolean, colorScale?: ColorScale, overlays?: any[], afwaLayer?: any[]) {
		this.dataType = dataType;
		this.label = label;
		this.categoryName = categoryName;
		this.units = units;
		if (colorScale) {
			this.colorScale = colorScale;
			this.showLegend = true;
		} else {
			this.colorScale = new NullColorScale('#0000FF');
			this.showLegend = false;
		}
		this.overlays = overlays ? overlays : null;
		this.afwaLayer = afwaLayer ? afwaLayer : null;

	}

	public toJSON(): {type: string; config: any} {
		let cfg = {
			dataType: this.dataType,
			label: this.label,
			categoryName: this.categoryName,
			units: this.units,
			showLegend: this.showLegend
		}
		if (this.showLegend) {
			cfg['colorScale'] = this.colorScale;			
		}
		if (this.overlays) {
			cfg['overlays'] = this.overlays;
		}
		if (this.afwaLayer) {
			cfg['afwaLayer'] = this.afwaLayer;
		}
		return {type: '', config: cfg};
	}

	public convertValue(value: any, units?: string): any {
		let newValue;
		let newUnits = this.units;
		if (value && !isNaN(value) && units && !(units=='')) {
			if (units && newUnits && this.conversions[units] && this.conversions[newUnits] && this.conversions[units].type === this.conversions[newUnits].type) {
				newValue = this.conversions[newUnits].refval + (value-this.conversions[units].refval)*this.conversions[newUnits].magnitude/this.conversions[units].magnitude;
			} else {
				newValue = value;
			}
		} else {
			newValue = value;
		}
		return newValue;
	}

	public unitPossibilities(): any {
		let possibilities = [];
		if (this.units && this.conversions && this.conversions[this.units]) {
			for (let key in this.conversions) {
				if (this.conversions[key].type === this.conversions[this.units].type) {
					possibilities.push(key);
				}
			}			
		}
		return possibilities;
	}

	public get prettyUnits(): string {
		if (this.units && this.conversions && this.conversions[this.units]) {
			return this.conversions[this.units].label;
		} else {
			return '';
		}
	}

	public prettyValueWithUnits(value: any): string {
	    if(isFinite(value)) {
	     	return this.prettyValue(value) + ' ' + this.prettyUnits;
    	} else {
      		return value;      
    	}
	}

	public prettyValue(value: any, digitShift: number=0): string {
		if(isFinite(value)) {
			if (0) {// 'g' notation?
				let digits = 4;
				/*
				// is 4 too few, based on legend?
				let rangeFrac = Math.abs(this.colorScale.max - this.colorScale.min);
				let denom = Math.max(Math.abs(this.colorScale.max),Math.abs(this.colorScale.min))
				if (rangeFrac && denom) {
					rangeFrac = rangeFrac/denom;
					while (rangeFrac>.0001) {
						rangeFrac = rangeFrac/10;
						digits++;
					}
				}
				*/
				return sprintf(`%.${digits+digitShift}g`,value);
			} else { // 'f' notation
				let digits = 1;
				let biggest = Math.max(Math.abs(this.colorScale.max),Math.abs(this.colorScale.min))
				if (biggest) {
					while (biggest<80) {
						biggest = biggest*10;
						digits++;
					}
				}
				return sprintf(`%2.${digits+digitShift}f`,value);
			}
		} else {
			return value;
		}
	}
}