/**
 * Leaflet Legend Component
 */

import L from 'leaflet';
import {
  DataLayer
} from '../../core/data-layer';

/**
 * ColorMapLegend: A class that contains data for a legend. 
 */
export class ColorMapLegend extends L.Control{

  public cmap:number[][];
  public range:number[];
  public title:string;
  public values:number[];
  public nDivs: number;
  public colorIndex:Function;
  public dataLayer: DataLayer;

  constructor(dataLayer: DataLayer,
              position:'bottomright'|'topleft'|'topright'|'bottomleft'|undefined='bottomright') {

    super({position: position});

    // set color map
    this.cmap = dataLayer.colorScale.cMap_rgb;
    this.range = [dataLayer.colorScale.min, dataLayer.colorScale.max];

    // set parameters
    this.title = dataLayer.colorScale.legendTitle.replace('<units>',dataLayer.prettyUnits);
    this.values = dataLayer.colorScale.legendVals;
    this.nDivs = dataLayer.colorScale.nDivs;
    this.colorIndex = (i:number) => dataLayer.colorScale.getColorByIndex(i);
    this.dataLayer = dataLayer;
  }

  onAdd(map: L.Map) {
    let div = L.DomUtil.create('div', 'info legend');

    // loop through our density intervals and generate a label with a colored square for each interval
    var html = '<center><b><jjj>' + this.title + '</jjj></b></center>';
    html += '<table class="legendTable">';
    for (var i = this.nDivs-1; i >=0; i--) {
      var value = this.values[i];
      var color = this.colorIndex(i);
      html += '<tr><td><iii style="background:' + color + '"></iii></td><td><b>' + this.dataLayer.prettyValue(value, -1) + '</b></td></tr>';
    }
    html += '</table>';
    div.innerHTML = html;
    return div;
  };
}
