/**
 * Time Slider Component
 */

import L from 'leaflet';
import $ from 'jquery';
import moment from 'moment';
import 'moment-timezone';
import 'moment-round';
import 'eonasdan-bootstrap-datetimepicker';

import {Webmap} from '../../webmap';
import {
  DEBOUNCE_TIME,
  TIME_SHIFT_INTERVALS,
  TIME_WINDOWS
} from '../../webmap-config';


import HTML from './time-slider.html';

/**
 * TimeSlider: A class that contains data for start and end time
 */
export class TimeSlider {

  private div:any;

  constructor() {
    this.div = this.create();
  }

  create() {
    let div = L.DomUtil.create('div', 'timesliders', document.body);
    div.innerHTML = HTML;
    return div;
  }

  initialize(wm: Webmap) {

    let startDTPhtmlid = '#startDateTimePicker';
    let endDTPhtmlid = '#endDateTimePicker';

    let defStart = moment(wm.queryParams.startTime).tz(wm.timezone);
    let defEnd = moment(wm.queryParams.endTime).tz(wm.timezone);

    // Hide most settings by default
    // show when the user presses the cog item
    $('.complexdatetime').hide()
    $('.datepickers').show()  // this shows the basic datetime picker once the app loads
    $('.datetimesettings').on("click",(ev)=>{
      ev.preventDefault();
      wm.showDateTimeSettings = !wm.showDateTimeSettings;
      
      if (wm.showDateTimeSettings) {
        $('.complexdatetime').show()
        $('.simpledatetime').hide()
      } else {
        $('.complexdatetime').hide()
        $('.simpledatetime').show()
      }
    });

    /**
     * Helper function to update the "Custom" time window
     * so that if someone moves the end date, the start date
     * will move too.
     */
    const updateCustomTimeWindow = function() {
      console.log('Defining a new custom time range!');
      const startTime = moment(wm.startDTP.date());
      const endTime = moment(wm.endDTP.date());
      const dur = moment.duration(endTime.diff(startTime));
      console.log(dur.humanize());
      wm.timeControl.timeWindow.value = dur;
    }


    // Set up the defaults:
    wm.startDTP = $(startDTPhtmlid)
      .datetimepicker({
        defaultDate: defStart, // overrides "useCurrent"
        ignoreReadonly: true,
        timeZone: wm.timezone,
        format: 'MM/DD/YYYY HH:mm zz',
      })
      .data('DateTimePicker');
    wm.endDTP = $(endDTPhtmlid)
      .datetimepicker({
        defaultDate: defEnd, // overrides "useCurrent"
        ignoreReadonly: true,
        timeZone: wm.timezone,
        format: 'MM/DD/YYYY HH:mm zz',
      })
      .data('DateTimePicker');


    // Add the time shift interval dropdown:
    let timeShiftElem = document.getElementById("timeStepSelect");

    // Which one of these matches the value from the config?
    let selectedOption = wm.matchDuration(wm.timeControl.timeShift, TIME_SHIFT_INTERVALS);
    for (let i=0; i<TIME_SHIFT_INTERVALS.length; i++) {
      let tsi = TIME_SHIFT_INTERVALS[i];
      let opt = document.createElement('option');
      opt.innerHTML = tsi.label;
      opt.value = tsi.label;
      opt.selected = (i===selectedOption);
      timeShiftElem.appendChild(opt);
    }
    // Set the time shift interval callback:
    timeShiftElem.addEventListener("change", function(evt) {
      let selection = [];
      for (let i=0; i<this.options.length; i++) {
        let opt = this.options[i];
        if (opt.selected) {
          selection.push(TIME_SHIFT_INTERVALS[i]);
        }
      }
      wm.timeControl.timeShift = selection[0];
    });


    // Add the time window dropdown
    let timeWindowElem = document.getElementById("timeWindowSelect");
    // Which one of these durations matches the difference between the starttime and endtime shown in the datetimepicker?
    selectedOption = wm.matchDuration(wm.timeControl.timeWindow, TIME_WINDOWS);
    for (let i=0; i<TIME_WINDOWS.length; i++) {
      let ts = TIME_WINDOWS[i];
      let opt = document.createElement('option');
      opt.innerHTML = ts.label;
      opt.value = ts.label;
      opt.selected = (i===selectedOption);
      timeWindowElem.appendChild(opt);
    }
    wm.timeControl.timeWindow = TIME_WINDOWS[selectedOption];

    // Set the time window callback:
    // hide the startDateTime container by default
    $('#startDateTimePickerCalendar').hide();
    timeWindowElem.addEventListener("change", function(evt) {
      let selection = [];
      for (let i=0; i<this.options.length; i++) {
        let opt = this.options[i];
        if (opt.selected) {
          selection.push(TIME_WINDOWS[i]);
        }
      }
      wm.timeControl.timeWindow = selection[0];
      if (selection[0].label=='Custom') {
        $('#startDateTimePickerCalendar').show();
      } else {
        $('#startDateTimePickerCalendar').hide();
        let prevEnd = moment(wm.endDTP.date());
        wm.startDTP.date(prevEnd.subtract(selection[0].value));
      }
      // Adjust the start datetimepicker for the new range:
    });

    // Set up the actions:
    $(startDTPhtmlid).on("dp.change", (e) => {
      // The start time has changed, either by the user 
      // clicking on the calendar or because it was programatically
      // changed in response to the end time being changed.
      if (wm.timeControl.timeWindow.label=="Custom") {
        // If someone manually changed the start time,
        // Make sure we keep track of the dt between
        // the start time and the end time.
        // Note that this gets called even when the start
        // time changes in response to the end time changing,
        // but in that case calling the function below will just
        // set the value to its already-existing value.
        updateCustomTimeWindow();
      }
      window.clearTimeout(wm.bounceTimeout);
      wm.bounceTimeout = window.setTimeout(()=>{
        wm.queryHasChanged();
      },
      DEBOUNCE_TIME);
    });

    $(endDTPhtmlid).on("dp.change", (e) => {
      // The end time has changed, either by the user 
      // clicking on the calendar or because it was programatically
      // changed in response to arrow clicks or because the time is
      // locked to "now".
      const endTime = moment(wm.endDTP.date());
      let isShowing = wm.showDateTimeSettings;
      let isCustom = (wm.timeControl.timeWindow.label=='Custom');
      if (isShowing && isCustom) {
        // The user has set a custom date range and currently has
        // the datetimepicker expanded.  Let them change the start
        // date and end date separately, so DO NOT change the start
        // date.
        // However, still record the difference between the start
        // time and the new end time.
        updateCustomTimeWindow();
      } else {
        // Update the start time too.
        if (wm.timeControl.timeWindow.value) {
          let timeWindow = wm.timeControl.timeWindow.value; 
          wm.startDTP.date(endTime.subtract(timeWindow));
        } else {
          console.log("Can't move the start date a custom amount, duration is undefined");
        }
      }
      // Make sure the dates don't cross
      wm.startDTP.maxDate(e.date);
      window.clearTimeout(wm.bounceTimeout);
      wm.bounceTimeout = window.setTimeout(()=>{
        wm.queryHasChanged();
      },
      DEBOUNCE_TIME);
    });

    $('.timeshiftback').on('click',()=>{
      let prevStart = moment(wm.startDTP.date());
      let prevEnd = moment(wm.endDTP.date());
      let timeShift = wm.timeControl.timeShift.value; 
      let fwdButtons = document.getElementsByClassName('timeshiftfwd');
      /*
      for (let fb in fwdButtons) {
        fb.disabled = false;
      }
      */
      wm.unlockTimeRangeToNow();
      wm.endDTP.date(prevEnd.subtract(timeShift));
    });

    $('.timeshiftfwd').on('click',()=>{
      console.log('fwd.');
      let prevStart = moment(wm.startDTP.date());
      let prevEnd = moment(wm.endDTP.date());
      let timeShift = wm.timeControl.timeShift.value;
      let newEnd = moment(prevEnd).add(timeShift);
      if (newEnd.diff(moment())>0) {
        console.log('Moving beyond now.');
        newEnd = moment().utc(); // now
        wm.lockTimeRangeToNow();
      }
      wm.endDTP.date(newEnd);
    });
  }

}
