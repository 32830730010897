/**
 * Root document for Weather Citizen Map Interface
*/

import $ from 'jquery';
import L from 'leaflet';
import moment from 'moment';
import 'moment-timezone';
import 'moment-round';
import 'eonasdan-bootstrap-datetimepicker';
import './components/leaflet-dropdown/leaflet-dropdown';

import {
	DEBOUNCE_TIME,
	NUM_MAPS_MAX,
	SIDEBAR_ON_LEFT,
	INFO_PANEL_HTML,
} from './webmap-config';

import {
	QueryParams,
} from './core/query-params';

import {
	Submap, iSubmap
} from './core/submap';

import {
	wzDataEnums,
	DataLayer
} from './core/data-layer';

import {
	DataSource
} from './core/data-source';

import {
	DSMETHOD, Collection
} from './core/collection';

import {
	ColorScale
} from './core/color-scale';

// Components
import { ColorMapLegend } from './components/legend/colormap-legend';
import { Sidebar } from './components/sidebar/sidebar';
import { TimeSlider } from './components/time-slider/time-slider';
import { JsonApi } from './components/json-api/json-api';

const MASTER_MAP = 0;

const REPEAT_INTERVAL_MS = 15000;

export type BoundingBox = [[number, number],[number, number]];
export type LabeledDuration = {label: string, value: moment.Duration | undefined };

export interface iWMConfig {
	htmlIds: any;
	queryParams: QueryParams;
	dataLayers: DataLayer[];
	dataSources: DataSource[];
	tinyUrl?: JsonApi; 
	numMaps?: number;
	mapData?: any[];
	hideControls?: boolean; // Hide the buttons on the map?
	timeControl?: any; // Data on the time control features
	showRadar?: boolean;
}

/**
 * Webmap: the top-level class that creates the maps and holds the DataSource objects that put data on the maps. 
 */
export class Webmap {
	public queryParams: QueryParams;
	public submaps: Submap[];
	public noOfVisibleSubmaps: number;
	public sources: DataSource[] = [];
	public startDTP: BootstrapV3DatetimePicker.Datetimepicker;
	public endDTP: BootstrapV3DatetimePicker.Datetimepicker;
	public showDateTimeSettings: Boolean = false;
	public dataLayers: DataLayer[];  // keep all the dataLayers around, so we can get them for things like the device history and for populating drop-down menus
	public bounceTimeout: number = 0;
	public active: boolean = true; // Should we be actively updating the map?
	public sidebar: Sidebar;
	public timeslider: TimeSlider;
	public timezone: string;
	private lockedToNow: boolean = false; // Should the date range be frozen at "now"?
	private nowUpdater: any = undefined;
	public hideControls: boolean = false;
	public tinyUrl: JsonApi;
	public htmlIds: any = {'containerL': undefined, 'containerR': undefined};
	public timeControl: {'timeShift': LabeledDuration, 'timeWindow': LabeledDuration}; // Parameters related to controlling the time window
	private loopUpdater: any = undefined;
	private radarTimes: moment.Moment[];
	private showRadar: boolean;

	constructor(config: iWMConfig) {
		// The IDs of the html components:
		this.htmlIds = config.htmlIds;
		// Options: hide controls?
		this.hideControls = config.hideControls ? config.hideControls : false;
		// Use a tinyUrl service? 
		this.tinyUrl = config.tinyUrl;
		// Set up time control:
		this.timeControl = {
			timeShift: { 
				label: '', // Don't need labels, as they're matched later
				value: (config.timeControl && config.timeControl.timeShiftValue) ? config.timeControl.timeShiftValue : moment.duration(1,'day'),
			},
			timeWindow: {
				label: '', // Don't need labels, as they're matched later
				value: moment.duration(config.queryParams.endTime.diff(config.queryParams.startTime)), 
			}
		}
		
		// Define timezone:
		this.timezone = moment.tz.guess();

		// set up some data structures:
		this.sidebar = new Sidebar(); // Note we construct the sidebar here, but we don't build it until later
		this.timeslider = new TimeSlider();  // builds later too

		this.queryParams = config.queryParams.copy();
	 
		// "numMaps" should override submaps.length, in case in certain instances (such as mobile devices) we wish to limit the number of maps
		let numMaps = config.numMaps ? config.numMaps : config.mapData? config.mapData.length : 1;

		// ===========================
		// Construct data Layers:
		this.dataLayers = config.dataLayers;

		// ===========================
		// Add maps:
		this.submaps = [];
		this.noOfVisibleSubmaps = 0; // Default to zero so it changes when we start drawing the first maps
		this.setNumberOfVisibleSubmaps(numMaps, config.mapData);

		// Update query params:
		// 

		// ===========================
		// Initialize the sidebar:
		if (!this.hideControls) {
			this.initializeSidebar(this.submaps[MASTER_MAP].map);
		}

		// ===========================
		// Handle the user moving or zooming (just do this all on the first map, as they'll all be synchronized anyway):
		this.submaps[MASTER_MAP].map.on('moveend', () => {
			window.clearTimeout(this.bounceTimeout);
			if(this.active) {
				this.bounceTimeout = window.setTimeout(()=>{
					this.queryHasChanged();
				},
				DEBOUNCE_TIME);        
			}
		});

		this.submaps[MASTER_MAP].map.on('movestart', () => {
			this.resetContextMenus();
		})

		this.submaps[MASTER_MAP].map.on('click', () => {
			this.resetContextMenus();
		});

		// ===========================
		// Set up the date/time picker
		if (!this.hideControls) {
			this.initializeTimeRangeControl();
		}

		// ===========================
		// Add sources:
		for (let source of config.dataSources) {
			this.addSource(source) // do this first, to preserve order
				.then(()=>{return source.updateData(this.queryParams);})
				.then(()=>{return source.openPopups(config.mapData);});    // Recreate any pop-ups on submaps
		}

		// ===========================
		// Loop the radar maps:
		this.showRadar = (config.showRadar === undefined) ? true : config.showRadar;
		this.configureRadarLoop();
	}

	/**
	 * This is the method which is automagically called when you run JSON.stringify(webmap).
	 * NOTE: JSON.parse(JSON.stringify(webmap)) != webmap.toJSON() !!!!!!
	 *    because calling webmap.toJSON() directly does not recursively invoke the toJSON() method in sub objects.
	 * @return {[type]} [description]
	 */
	public toJSON() {
		let C = {
			'version': '1', 
			'parameters': {
				startTime: this.queryParams.startTime.utc().format(),
				endTime: this.queryParams.endTime.utc().format(),
				latLngBounds: this.queryParams.getBoundingBox(),
				sources: this.sources,
				dataLayers: this.dataLayers,
				numMaps: this.noOfVisibleSubmaps,
				mapData: this.submaps,
				timeControl: {
					timeShiftValue: this.timeControl.timeShift.value.toJSON(),
				},
				showRadar: this.showRadar,
			}
		};
		return C;
	} 

	/**
	 * Function that is called if the map query (geo range and time/date range) has changed.
	 */
	public queryHasChanged() {
		this.queryParams.latLngBounds = this.submaps[MASTER_MAP].map.getBounds();
		if (!this.hideControls) {
			// If the datetimepicker exists, grab the latest times from it.  Otherwise, don't change the times
			this.queryParams.startTime = moment(this.startDTP.date()).utc(); // make a copy of the date.
			this.queryParams.endTime = moment(this.endDTP.date()).utc(); // make a copy of the date.      
		}
		this.updateAllSources(this.queryParams);
		this.updateRadarLoop();
	}

	/**
	 * For some or all of the maps, reset the context menu (the thing that appears when you right-click).
	 * If you don't do this, then the right-click menu will stay open.
	 * This should be done whenever the map is moved.
	 */
	public resetContextMenus(submapsToUpdate?: number | number[]) {
		if (submapsToUpdate==undefined) {
			submapsToUpdate = [];
			let ctr = 0;
			while(ctr<this.submaps.length) {
				submapsToUpdate.push(ctr);
				ctr++;
			}
		} else if (!Array.isArray(submapsToUpdate)) {
			submapsToUpdate = [submapsToUpdate];
		}

		for (let i = 0; i<submapsToUpdate.length; i++) {
			let mapind = submapsToUpdate[i];
			this.submaps[mapind].resetContextMenu(mapind);
		}
	}

	/**
	 * Change the layout to have the presribed number of maps.
	 * @param {number} nMaps How many maps we want to see.
	 * Must be between 1 and NUM_MAPS_MAX
	 */
	public setNumberOfVisibleSubmaps(nMaps: number, mapData?: any){
		if (!nMaps) return;
		if (nMaps<1) return;
		if (nMaps>NUM_MAPS_MAX) {
			console.log(`Cannot create ${nMaps} maps (limit is ${NUM_MAPS_MAX}`);
			return;
		}

		for (let i=0; i<this.noOfVisibleSubmaps; i++) {
			this.submaps[i].map.closePopup();
		}

		this.active = false;  // Pause updating so things don't go crazy while we're resizing maps.

		console.log(`Setting to ${nMaps} submaps.`);
		console.log(this.queryParams);
		let llb = this.queryParams.latLngBounds;
		this.unsynchronizeAllMaps();

		// remember how many maps were previously displayed
		var orig_displayedNumberOfMaps = this.noOfVisibleSubmaps;
		var _displayedNumberOfMaps = nMaps;
		if (_displayedNumberOfMaps==orig_displayedNumberOfMaps) return;

		// unsynchronize all previous maps
		//unsynchonizeMaps(maps, orig_displayedNumberOfMaps);
			
		// add maps if needed
		if (_displayedNumberOfMaps>orig_displayedNumberOfMaps) {
			for (var i=1; i<=_displayedNumberOfMaps; ++i) {
					if (i>orig_displayedNumberOfMaps) {
						//console.log(`setNumberOfVisibleSubmaps(): adding map ${i-1}`);
						let dl = 0;
						if (mapData && mapData[i-1] && mapData[i-1].dl) {
							dl = mapData[i-1].dl;
						}
						// If dataLayer is specified, it supercedes .dl (since .dl is in the tinyurl and dataLayer may be 
						// specified in the additional CONFIG params)
						if (mapData && mapData[i-1] && mapData[i-1].dataLayer) {
							dl = this.dataLayers.findIndex((el)=>{
								return el.label == mapData[i-1].dataLayer;
							});
						}
						if (dl<0) {
							dl = 0;
						}
						this.addSubmap(dl);
					};
			};
		}
		// remove maps if needed
		else if (_displayedNumberOfMaps<orig_displayedNumberOfMaps) {
			for (var i=1; i<=orig_displayedNumberOfMaps; ++i) {
					if (i>_displayedNumberOfMaps) { // this is really just a counter, it isn't telling us which map to remove.
						this.removeLastSubmap();
					}
			};
		}

		// set width of left and right column containers
		var containerL = this.htmlIds.containerL ? L.DomUtil.get(this.htmlIds.containerL) : undefined;
		var containerR = this.htmlIds.containerR ? L.DomUtil.get(this.htmlIds.containerR) : undefined;
		if (_displayedNumberOfMaps==1) {
			if (SIDEBAR_ON_LEFT) {
				containerL.style.width='0%';
				containerR.style.width='100%';            
			} else {
				containerL.style.width='100%';
				containerR.style.width='0%';            
			}
		}
		else {
			containerL.style.width='49.6%';
			containerR.style.width='49.6%';
		}
			
		// set size of individual displayed maps
		for (var idx_map=0; idx_map<_displayedNumberOfMaps; ++idx_map) {
			var mapdiv_id = "map_" + idx_map.toString();
			var mapdiv = L.DomUtil.get(mapdiv_id);
			mapdiv.style.width = '100%';
			let noOfCols = 2;
			let noOfRows = Math.ceil((_displayedNumberOfMaps-0.1)/noOfCols); // Subtract 0.1?  Because I don't trust what javascript does with "numbers" as opposed to using ints or floats.
			switch(noOfRows) {
				case 1:
					mapdiv.style.height = '100%';
					break;
				case 2:
					mapdiv.style.height = '50%';
					break;
				case 3:
					mapdiv.style.height = '33%';
					break;
				default:
				case 4:
					mapdiv.style.height = '25%';
					break;
			}

			//    specify drawing of bottom map borders for bottom maps only
			var drawBottomBorder = idx_map==_displayedNumberOfMaps-1 || idx_map==_displayedNumberOfMaps-2;
			if (drawBottomBorder) {
				mapdiv.style.borderBottom = '1px solid #313030';
			} else {
				mapdiv.style.borderBottom = '';
			}

			this.submaps[idx_map].map.fitBounds(llb);
			this.submaps[idx_map].map._onResize(); // IMPORTANT!  force _onResize so maps repaint correctly
		};

		this.noOfVisibleSubmaps = _displayedNumberOfMaps;
		this.synchronizeAllMaps();

		this.active = true;
		this.submaps[0].map.fitBounds(llb);

		// load data if adding maps
		//if (_displayedNumberOfMaps>orig_displayedNumberOfMaps) refreshDataLoadedFromRepositories();
		
		// Update the units panel:
		this.updateUnitsPanel();
	}

	/**
	 * Make an additional submap, assign it to a side, style it, and push it on the stack.
	 */
	public addSubmap(adl?: number) {
		var idx_map = this.submaps.length;

		// ====================
		// Build the container (let's keep all style stuff in Webmap, not in a Submap):
		if (SIDEBAR_ON_LEFT) {
			var leftSide = (idx_map%2===1);
		} else {
			var leftSide = (idx_map%2===0);      
		}

		// get map div, set CSS params, and add to parent container
		//    alternate map additions between left and right parent containers
		var containerL = this.htmlIds.containerL ? L.DomUtil.get(this.htmlIds.containerL) : undefined;
		var containerR = this.htmlIds.containerR ? L.DomUtil.get(this.htmlIds.containerR) : undefined;
		var container;
		if (leftSide) {
			container = containerL ? containerL : containerR;
		} else {
			container = containerR ? containerR : containerL;
		}
		if (!container) {
			console.log('ERROR.  No HTML containers available for maps.');
			return
		}

		//    specify map size based on total number of maps displayed
		var className = 'map';
		var mapdiv = L.DomUtil.create('div', className, container);
		mapdiv.id = "map_" + idx_map.toString();
		//var style = mapdiv.style, height = style.height;
		mapdiv.style.width = '100%';
		mapdiv.style.height = '0%';

		//    specify drawing of map borders
		mapdiv.style.borderTop = '1px solid #313030';
		mapdiv.style.borderLeft = '1px solid #313030';
		mapdiv.style.borderRight = '1px solid #313030';

		// ====================
		// Now create the map:
		let thiswm = this;
		let submapOptions: iSubmap = {
			parent: this,
			idx: idx_map,
			mapid: mapdiv.id,
			dataLayers: this.dataLayers,
			activeDataLayerIndex: adl,
			permalinkMaker: ()=>{thiswm.createPermalink();},
			hideControls: this.hideControls,
		};
		let newmap = new Submap(submapOptions);
		
		this.submaps.push(newmap);

		// Let the sources know:
		for (let source of this.sources) {
			source.addSubmap();
		}    
	}

	public removeLastSubmap() {
		let idx = this.submaps.length-1;
		let content = this.submaps[idx].map.getContainer();
		content.parentNode.removeChild(content);
		this.submaps.pop();
		for (let source of this.sources) {
			source.removeLastSubmap();
		}
	}

	/**
	 * Sync all maps so they show the same area
	 */
	public synchronizeAllMaps() {
		for (let i = 0; i<this.submaps.length; i++) {
			for (let j=i+1; j<this.submaps.length; j++) {
				this.submaps[i].map.sync(this.submaps[j].map);
				this.submaps[j].map.sync(this.submaps[i].map);
			}
		}
	}

	/**
	 * Unsync all maps so they can show different areas
	 */
	public unsynchronizeAllMaps() {
		for (let i = 0; i<this.submaps.length; i++) {
			for (let j=i+1; j<this.submaps.length; j++) {
				this.submaps[i].map.unsync(this.submaps[j].map);
				this.submaps[j].map.unsync(this.submaps[i].map);
			}
		}
	}


	public lockTimeRangeToNow() {
		this.lockedToNow = true;
		let wm = this;
		window.clearInterval(this.nowUpdater);
		this.nowUpdater = window.setInterval(()=>{
				console.log('Auto-updating time (locked to "now")');
				let now = moment().utc();
				wm.endDTP.date(now);
				if (wm.timeControl.timeWindow.value) { // 'Custom' has a value of null, so in this case we leave the start point where it was.
					wm.startDTP.date(moment(now).subtract(wm.timeControl.timeWindow.value));
				}
			},
			REPEAT_INTERVAL_MS);
	}

	public unlockTimeRangeToNow() {
		if (this.nowUpdater) {
			window.clearInterval(this.nowUpdater);
		}
		if (this.lockedToNow) {
			this.lockedToNow = false;
		}
	}

	/**
	 * Set up our controls for managing the time window.  Currently using datetimepicker
	 */
	public initializeTimeRangeControl() {
		this.timeslider.initialize(this);
	}

	/** 
	 * Find a "duration" object (not a moment.Duration) out of a list of durations 
	 */
	public matchDuration(dur: LabeledDuration, durArray: LabeledDuration[]): number {
		let foundIdx = -1;
		if (dur.value) {
			for (let idx = 0; idx < durArray.length; idx++) {
				if (durArray[idx].value && (Math.abs(dur.value.asMilliseconds()-durArray[idx].value.asMilliseconds())<500) ) {
					foundIdx = idx;
					break;
				}
			}
		}
		// We didn't get a match, or the duration was "custom" (i.e. dur.value was undefined)
		// Either way, we should set it to "custom":
		if (foundIdx<0) {
			// set to 'custom'
			for (let idx = 0; idx < durArray.length; idx++) {
				if (!durArray[idx].value) {
					foundIdx = idx;
					break;
				}
			}
		}
		// And if we couldn't find "custom":
		if (foundIdx<0) {
			foundIdx = durArray.length;
		}
		return foundIdx;
	}

	/**
	 * Create the sidebar
	 * @param {L.Map} map the sidebar is attached to.
	 */
	public initializeSidebar(map: L.Map) {
		console.log('Initializing sidebar...');

		// ========================================================================
		// The SETTINGS Section:
		let settingsPanel = this.sidebar.addPanel({
			id: 'settings',                        // UID, used to access the panel
			//tab: '<i class="fa fa-gear"></i>',    // content can be passed as HTML string,
			//title: 'Settings',                // an optional pane header
			position: 'top'
		});

		//number of maps:
		let numMapsForm = document.createElement('form-group');
		numMapsForm.innerHTML = '<h5><b>Number of Maps</b></h5>';
		let numMapsArr = [1];
		let tmp = 2;
		while (tmp <=NUM_MAPS_MAX) {
			numMapsArr.push(tmp);
			tmp = tmp+2;
		}
		numMapsForm.appendChild(this.sidebar.dropdown(numMapsArr,this.noOfVisibleSubmaps,undefined,'numberOfMaps',(selection)=>{
			let sel = parseInt(selection[0].value);
			this.setNumberOfVisibleSubmaps(sel);
		}));
		settingsPanel.appendChild(numMapsForm); 


		let d = document.createElement('form-group');
		d.innerHTML = '<h5><b>Data Sources</b></h5>';
		let fc = document.createElement('div');
		fc.className = 'form-check';
		fc.id = 'sourcesCheckboxList';
		d.appendChild(fc);
		settingsPanel.appendChild(d);


		// Overlays:
		d = document.createElement('form-group');
		d.innerHTML = '<h5><b>Overlays</b></h5>';
		fc = document.createElement('div');
		fc.className = 'form-check';
		fc.id = 'overlaysCheckboxList';
		d.appendChild(fc);
		settingsPanel.appendChild(d);

		// ========================================================================
		//Time Zone selection:
		let tzForm = document.createElement('form-group');
		tzForm.innerHTML = '<h5><b>Time Zone</b></h5>';
		let tzArr = moment.tz.names();
		let tzGuess = moment.tz.guess();

		tzForm.appendChild(this.sidebar.dropdown(tzArr,tzGuess,undefined,'numberOfMaps',(selection)=>{
			let sel = selection[0].value;
			this.setTimeZone(sel);
		}));
		settingsPanel.appendChild(tzForm); 

		// Timespan:
		/*
		let timespanForm = document.createElement('form-group');
		timespanForm.innerHTML = '<h5><b>Time Range of Displayed Data:</b><h5>';
		let tsList = [
			{label: '1 Hour', value: moment.duration(1,'Hour')},
			{label: '6 Hours', value: moment.duration(6,'Hour')},
			{label: '1 Day', value: moment.duration(1,'Day')},
			{label: '1 Week', value: moment.duration(1,'Week')},
			{label: '1 Month', value: moment.duration(1,'Month')},
			{label: '6 Months', value: moment.duration(6,'Month')},
			{label: 'Custom', value: undefined},
		];
		let timespanArr = tsList.map((el)=>{return el.label});
		timespanForm.appendChild(this.sidebar.dropdown(timespanArr,'1 Hour',undefined,'numberOfMaps',(selection)=>{
			let selText = selection[0].value;
			console.log(`Selection: ${selText}`);
			let sdtp = document.getElementById('startDateTimePicker');
			if (selText=='Custom') {
				sdtp.style.display = 'table';
			} else {
				sdtp.style.display = 'none';
				let ts = tsList.find((el)=>{
					return el.label == selText;
				});
				let prevEnd = moment(this.endDTP.date());
				this.startDTP.date(prevEnd.subtract(ts.value));
			}
			// Adjust the start datetimepicker for the new range:
		}));
		settingsPanel.appendChild(timespanForm); 
		*/
	 
		// ========================================================================
		// Filter WZ by uuid:
		let userFilterDiv = document.createElement('div');
		userFilterDiv.id = 'userFilter';
		settingsPanel.appendChild(userFilterDiv);


		// ========================================================================
		// The UNITS Panel:
		let unitsForm = document.createElement('div');
		unitsForm.innerHTML = '<h5><b>Units</b></h5>';
		unitsForm.id = 'sidebar-units-panel'
		settingsPanel.appendChild(unitsForm);
		this.updateUnitsPanel();


		// ========================================================================
		// The ABOUT panel:
		/*
		this.sidebar.addPanel({
			id: 'about',                        // UID, used to access the panel
			tab: '<i class="fa fa-info"></i>',    // content can be passed as HTML string,
			pane: INFO_PANEL_HTML,                        // DOM elements can be passed, too
			//title: 'About',                // an optional pane header
			position: 'top'
		});
		*/
	}

	/**
	 * Sets up the radar looper on the submaps
	 * @return {[type]} [description]
	 */
	public async configureRadarLoop(): Promise<null> {

		// Add radar to the overlays checkbox list:
		let overlaysList = document.getElementById('overlaysCheckboxList');

		if (overlaysList) {
			let wm = this;
			let overlayFormGroup = this.sidebar.checkBox(overlaysList,'Radar',this.showRadar,'overlayFormGroup-radar',(checked)=>{
				if (checked) {
					console.log(`Activating radar`);
					wm.showRadar = true;
					for (let submap of wm.submaps) {submap.activateRadar();}
				} else {
					wm.showRadar = false;
					console.log(`Removing all radar data from maps`);
					for (let submap of wm.submaps) {submap.deactivateRadar();}
				}
			});
			// Add to the list
			//overlaysList.appendChild(overlayFormGroup);      
		}

		return this.updateRadarLoop();
	}

	/*
	 * Sets the times of the radar loops, and builds the radar layers
	 */
	public async updateRadarLoop(): Promise<null> {
		// First, make the array of times.  Note they go backward in time, from idx=0 for newest, to idx=end is oldest:
		let radar_dt = moment.duration(30,'minutes');
		let max_radar_timespan = moment.duration(6,'hours');

		let tmpTime = this.queryParams.endTime.clone().floor(5,'minutes');
		// See if the time hasn't changed enough to warrant modifying the radar loop
		if (this.radarTimes && tmpTime.isSame(this.radarTimes[0],'minute')) {
			return Promise.resolve(null);
		}    

		window.clearInterval(this.loopUpdater);
		this.radarTimes = [];

		tmpTime = this.queryParams.endTime.clone().floor(5,'minutes');
		let earliestTime = tmpTime.clone().subtract(max_radar_timespan);
		if (earliestTime.isBefore(this.queryParams.startTime)) {
			earliestTime = this.queryParams.startTime;
		}

		this.radarTimes = [tmpTime.clone()];
		let loopTheRadar = false;
		if (loopTheRadar) { 
			tmpTime.subtract(radar_dt);
			while (!tmpTime.isBefore(earliestTime)) {
				this.radarTimes.push(tmpTime.clone());
				tmpTime.subtract(radar_dt);
			}
		}

		for (let submap of this.submaps) {
			submap.establishRadar(this.radarTimes);
		}

		if (this.showRadar) {
			this.setRadarIndex(0, 6000);
		}
	 
		return Promise.resolve(null);
	}

	public setRadarIndex(idx: number, additionalTime:number=0) {
		for (let submap of this.submaps) {
			submap.setRadarLayer(idx);
		}

		if (this.radarTimes.length > 1) {
			// Get ready for the next radar loop:
			window.clearInterval(this.loopUpdater);
			let nextIdx = idx==0 ? this.radarTimes.length-1 : idx-1;
			this.loopUpdater = window.setInterval(()=>{
				this.setRadarIndex(nextIdx);
				//console.log(`Setting radar time to [${idx}]: ${this.radarTimes[idx].format()}`);
			}, idx==0 ? 6000+additionalTime : 1500+additionalTime);      
		}
	}


	/**
	 * Call on all DataSource objects to update their map points based on a new query (date & geo range)
	 * @param {QueryParams} queryParams The new query parameters
	 */
	public updateAllSources(queryParams: QueryParams) {
		console.log('Updating all sources.');
		for (let source of this.sources) {
			if (source.active) {
				source.updateData(queryParams);
			}
		}
	}

	/**
	 * Add a new source.  Once the source is added, the maps are populated.
	 * @param {DataSource} source The data source to add.
	 */
	public async addSource(source: DataSource): Promise<any> {
		source.parent = this;
		source.dataLayers = this.dataLayers;
		source.submaps = this.submaps;
		source.timezone = this.timezone;
		let qp = this.queryParams;

		// Add source to checkbox list:
		let sourceList = document.getElementById('sourcesCheckboxList');
		if (sourceList) {
			let id = ('sourceFormGroup-' + source.label).replace(/\s/g,'_');

			let sourceFormGroup = this.sidebar.checkBox(sourceList, source.label, source.active, id, (checked)=>{
				if (checked) {
					console.log(`Activating ${source.label}`);
					source.activate(qp);
				} else {
					console.log(`Removing all ${source.label} data from maps`);
					source.deactivate();
				}        
			});     
			//sourceList.appendChild(sourceFormGroup);      
		}

		// Add filtering possibilities - filter by ALL user IDs:
		/*
		let userFilter = document.getElementById('userFilter');
		if (userFilter) {
			let filterParams = await source.filterByIdParams();
			if (filterParams && filterParams.ids && filterParams.ids.length>0) {
				let labels = ['All'];
				labels = labels.concat(filterParams.ids);
				let vals = [undefined];
				vals = vals.concat(filterParams.ids);
				let filterDropdown = this.sidebar.dropdown(vals,'All',labels,sourceFormGroup.id+'_dropdown',(selection)=>{
					console.log(`Selection: ${selection}`);
					source.filterBy(selection);
				},true);      
				let userFilterForm = document.createElement('form-group');
				userFilterForm.innerHTML = '<h5><b>Filter by UUID:</b><h5>';
				userFilterForm.appendChild(filterDropdown);
				userFilter.appendChild(userFilterForm);
			}
		}
		*/

		// add to map (if default):
		if(source.displayByDefault) {
			console.log(`By default, adding layer "${source.label}" to map.`);
			source.active = true;
		} else {
			source.active = false;
		}

		// push:
		for (let i=0; i<this.submaps.length; i++) {
			source.addSubmap();
		}
		this.sources.push(source);

		return Promise.resolve();
	}

	/**
	 * This function gets called from submap when a submap's DataLayer has changed.
	 * @param {number} idx The index of the submap whose DataLayer has changed.
	 */
	public dataLayerHasChangedOnSubmap(idx: number) {
		let activeDataLayer = this.submaps[idx].activeDataLayer;
		if (this.submaps && this.submaps[idx]) { // Need to check that everyone knows about the submap before we announce its dataLayer has been set.
			//console.log('Closing popups:');
			this.submaps[idx].map.closePopup();
			for (let source of this.sources) {
				if (source.active) {
					source.dataLayerHasChangedOnSubmap(idx);
				}
			}      
		}
		this.updateUnitsPanel();
	}

	/**
	 * createPermalink: make a permalink from the curent data model.
	 * @return {String} permalink URL
	 */
	public async createPermalink() {
		console.log('In createPermalink:');
		console.log(this);
		let asStr = JSON.stringify(this);
		let parsed = JSON.parse(asStr);
		//console.log('webmap, stringified:');
		//console.log(asStr);
		console.log('webmap->str->parse:');
		console.log(parsed);

		// grab the current URL, but remove any args (which follow '?')
		let URL = window.location.href.split('?')[0] + '?';
		if (this.tinyUrl) {
			let id = await this.tinyUrl.post(asStr);
			console.log(`Posted id ${id}`);
			URL += 'ID=' + id;
		} else {
			URL += 'CONFIG=' + asStr;
		}
	 
		console.log(URL);
		prompt("Permalink URL to recreate current view:", URL);
	}

	public setTimeZone(tz: string) {
		console.log('New timezone:');
		console.log(tz);
		this.timezone = tz;
		for (let i =0; i<this.sources.length; i++) {
			this.sources[i].timezone = tz;
		}
		// Update the datetime pickers:
		let startDate = this.startDTP.date();
		let endDate = this.endDTP.date();
		this.startDTP.disable();
		this.endDTP.disable();
		this.startDTP.timeZone(tz);
		this.endDTP.timeZone(tz);
		this.startDTP.date(startDate);
		this.endDTP.date(endDate);
		this.startDTP.enable();
		this.endDTP.enable();
	}

	/**
	 * Set the units for the display of a DataLayer
	 * @param {DataLayer} dataLayer The DataLayer to modify
	 * @param {string}    unit      The new units
	 * @param {string}    dlDivID    The HTML ID of the div that contains these options
	 */
	public setUnits(dataLayer: DataLayer, unit: string, dlDivID: string) {
		//console.log(`Setting ${dataLayer.label} to ${unit}`);
		let units_old = dataLayer.units;
		let cs_old = dataLayer.colorScale;
		// Update the color scale:
		dataLayer.units = unit;
		let newMin = dataLayer.convertValue(cs_old.min,units_old);
		let newMax = dataLayer.convertValue(cs_old.max,units_old);
		let d = document.getElementById(dlDivID+'_min');
		d.value = newMin;
		d =  document.getElementById(dlDivID+'_max');
		d.value = newMax;
		dataLayer.colorScale = new ColorScale(cs_old.legendTitle, [newMin, newMax], cs_old.nDivs, cs_old.cmapName)

		for (let i=0; i<this.noOfVisibleSubmaps; i++) {
			if (this.submaps[i].activeDataLayer.dataType === dataLayer.dataType) {
				// set the updated active data layer:
				this.submaps[i].activeDataLayerIndex = this.submaps[i].activeDataLayerIndex;
				//this.dataLayerHasChangedOnSubmap(i);
			}
		}
		return;
	}

	/**
	 * Change the range on a legend for a given DataLayer.
	 * @param {DataLayer} dataLayer The DataLayer to modify
	 * @param {number|undefined}    newMin   The range lower bound
	 * @param {number|undefined}    newMax   The range upper bound
	 */
	public setRange(dataLayer: DataLayer, newMin: number | undefined, newMax: number | undefined) {
		// We're going to retain some stuff from the old colorScale:
		//console.log(`Setting ${dataLayer} range to ${newMin}:${newMax}`);
		let cs_old = dataLayer.colorScale;

		let newRange: [number, number] = [newMin? newMin : cs_old.min, newMax? newMax : cs_old.max];

		dataLayer.colorScale = new ColorScale(cs_old.legendTitle, newRange, cs_old.nDivs, cs_old.cmapName)
		for (let i=0; i<this.noOfVisibleSubmaps; i++) {
			if (this.submaps[i].activeDataLayer.dataType === dataLayer.dataType) {
				// set the updated active data layer:
				this.submaps[i].activeDataLayerIndex = this.submaps[i].activeDataLayerIndex;
				//this.dataLayerHasChangedOnSubmap(i);
			}
		}
		return;    
	}

	/**
	 * Update the units panel in the sidebar (if it exists)
	 * @return {[type]} [description]
	 */
	public updateUnitsPanel() {
		let unitsPanel = document.getElementById('sidebar-units-panel');
		//console.log('Updating units panel!');
		let wm = this;
		//let unitsPanel = this.sidebar.panel('units');
		if (unitsPanel) {
			// Examine the units setters that are in the units form and remove any extra ones:
			if (unitsPanel.childNodes) {
				let ch = unitsPanel.childNodes;
				for (let i=ch.length-1; i>=1; i--) {
					unitsPanel.removeChild(ch[i]);
				}
			}

			function addUnitSection(dataLayer: Datalayer, showRange: boolean=false) {
				let compactLabel = dataLayer.label.replace(/\s/g,'_');
				// Add changed datalayer child, if it doesn't exist (i.e. isn't on two maps at once):
				let dlFound = false;
				if (unitsPanel.childNodes) {
					let ch = unitsPanel.childNodes;
					for (let i=ch.length-1; i>=0; i--) {          
						if (ch[i].id === compactLabel) {
							dlFound = true;
						}
					}
				}
				if (!dlFound && dataLayer.unitPossibilities().length>0) {

					// First, define some html ids for complicated components we'll populate later:
					let unitSelectId = compactLabel + '_units';
					let minInputId = compactLabel + '_min';
					let maxInputId = compactLabel + '_max';
					// Next, definee the HTML template:
					let html = `
						<div class="row sidebar-units-row">
							<div class="col-sm-6 sidebar-units-property-name">${showRange ? `<b>${dataLayer.label}</b>` : dataLayer.label}</div>
							<div class="col-sm-6 sidebar-units-select-div">
								<select class="form-control" id=${unitSelectId}></select>
							</div>
						</div>
					`;

					if (showRange) {
					html += `
						<div class="row">
							<div class="col-sm-6 sidebar-units-range-label">Scale Max</div>
							<div class="col-sm-6 sidebar-units-number-box">
								<input class="form-control" type="number" id=${maxInputId}>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6 sidebar-units-range-label">Scale Min</div>
							<div class="col-sm-6 sidebar-units-number-box">
								<input class="form-control" type="number" id=${minInputId}>
							</div>
						</div>
						<hr>
					`;
					}
					
					html += `
						<br>
					`;
				
					// Create the HTML elements:
					let dlForm = document.createElement('div');
					dlForm.className = "sidebar-units-block"
					dlForm.id = compactLabel;
					dlForm.innerHTML = html;
					// Add them to the page:
					unitsPanel.appendChild(dlForm);

					// Populate the complicated ones:
					let dropdown = wm.sidebar.dropdown(dataLayer.unitPossibilities(),dataLayer.units,undefined,undefined,(selection)=>{
						let sel = selection[0].value;
						wm.setUnits(dataLayer,sel,compactLabel);
					},false,document.getElementById(unitSelectId));
					if (showRange) {
						let maxbox = wm.sidebar.numberBox('Max', dataLayer.colorScale.max.toString(), undefined, (value)=>{
							let sel = parseFloat(value);
							wm.setRange(dataLayer,undefined,sel);
						},document.getElementById(maxInputId));
						let minbox = wm.sidebar.numberBox('Min', dataLayer.colorScale.min.toString(), undefined, (value)=>{
							let sel = parseFloat(value);
							wm.setRange(dataLayer,sel,undefined);
						},document.getElementById(minInputId));
					}
				}
			}

			for (let mapind=0; mapind<this.noOfVisibleSubmaps; mapind++) {
				let activeDataLayer = this.submaps[mapind].activeDataLayer;
				addUnitSection(activeDataLayer, true);
			}
			// Add any that are missing:
			for (let dataLayer of this.dataLayers) {
				addUnitSection(dataLayer, false);
			}
		} 
	}
}
