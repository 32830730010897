/**
 * AIS Source and Collections
 */

import L from 'leaflet';
import moment from 'moment';
import 'moment-timezone';

import {
  Webmap
} from '../../webmap'

import {
  QueryParams
} from '../../core/query-params'

import {
  DSMETHOD, Collection
} from '../../core/collection'

import {
  GEOJSON_FIELDS_TO_FILTER,
  GEOLOCATION_ACCURACY_THRESHOLD,
  GPS_LAT_LON_PERTURB_SCALE,
  MAX_FEATURES_PER_LAYER,
  QUERY_PADDING
} from '../../webmap-config'

import {
  wzDataEnums,
  DataLayer,
  iDatum,
} from '../../core/data-layer';

import {
  DataSource, iDataSource
} from '../../core/data-source';

import {
  Submap,
} from '../../core/submap';


/**
 * A I S (AIS) Source
 * @type {DataSource}
 */
export interface iAISSource extends iDataSource {
}

export class AISSource extends DataSource {
  public sensors: Collection;
  public queryParams: QueryParams | null;
  public dataOnMaps: any[];

  constructor(config: iAISSource, auth: string) {
    super(config);
    this.sensors = new Collection(this, 'AISCollection', this.api_root, 'aisweather', auth, this.label+'/AISweather',this.color);
    this.queryParams = null;
    this.dataOnMaps = [];
  }

  /** 
   * toJSON returns an object which:
   *  1) can be passed to JSON.stringify (by definition, see typescript docs)
   *  2) has a property "config" can be passed to this class's constructor
   *  3) has a property "type" that specifies the subtype (i.e. the concrete, 'non-abstract' type) which
   *     allows us to pass the config property to the right constructor
   */
  public toJSON(): {type: string; config: iAISSource} {
    let config: iAISSource = {...super.toJSON().config,
    };
    return {type: 'AISSource', config: config}
  }

  public addSubmap() {
    let ds = this;
    let mapind = this.dataOnMaps.length;

    let sensorLayer = L.geoJSON(undefined, {
      pointToLayer: function(feature, latlng) {
        // add marker to map
        var marker = ds.circleMarker(mapind, feature, latlng, {
          color: ds.getColorByValue(mapind, feature.value),
        });

        let markerId = ds.getMarkerId(feature); // The ID we use to keep track of markers on the map

        // add left click
        marker.bindPopup(()=>{
          ds.submaps[mapind].addPopupRecord({'type': 'table', 'feature_id': markerId});
          return ds.sensorMarkerPopupHtml(feature, mapind);
        }).openPopup();
 
        // When adding a point, keep track that we've added it:
        //ds.sensorsOnMap[feature.properties.station_id][mapind] = L.stamp(marker);
        ds.dataOnMaps[mapind].sensorsOnMap[markerId] = L.stamp(marker);

        return marker;
      }
    });

    sensorLayer.addTo(this.submaps[mapind].map);

    this.dataOnMaps.push({
      sensorLayer: sensorLayer,
      sensorsOnMap: {}, // An array of markerIds (station_ids) for plotted AIS data.
    });
  }

  public removeLastSubmap() {
    this.dataOnMaps.pop();
  }

  public async interruptQueries() {
    return Promise.all([
      this.sensors.interruptQueries()
    ]).then(()=>{
      return this.resumeQueries();
    });
  }

  public async resumeQueries() {
    let c = Promise.all([
      this.sensors.resumeQueries()
    ]);
    return c; 
  }

  public async activate(queryParams: QueryParams) {
    this.active = true;
    return this.updateData(queryParams);
  }

  public async deactivate(): Promise<any> {
    this.active = false;
    let p = await this.interruptQueries();
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      for (var markerId in this.dataOnMaps[mapind].sensorsOnMap) {
        let layerID = this.dataOnMaps[mapind].sensorsOnMap[markerId];
        if (layerID) {
          this.submaps[mapind].removeMapLayer(layerID);
        }
      }
      this.dataOnMaps[mapind].sensorsOnMap = {};
    }
    return p;
  }


  public async updateData(newQueryParams: QueryParams): Promise<any> {
    if(!this.active) {
      return Promise.resolve();
    }

    // interrupt previous queries:
    let p = await this.interruptQueries();

    this.queryParams = newQueryParams.copy(); // define what the latest query is (because other functions may use it)

    // Remove points that fall outside the new time range: 
    if (1) {//(this.queryParams && (newQueryParams.startTime.isAfter(this.queryParams.startTime) || newQueryParams.endTime.isBefore(this.queryParams.endTime))) {
      //console.log('Time range has shrunk; removing excluded markers.');
      for (let mapind=0; mapind<this.submaps.length; mapind++) {
        for (var markerId in this.dataOnMaps[mapind].sensorsOnMap) {
          let layerID = this.dataOnMaps[mapind].sensorsOnMap[markerId];
          let mapLayer = this.submaps[mapind].getMapLayer(layerID);
          if (mapLayer) {
            let timeStr = mapLayer.feature.properties.time;
            let markerTime = moment(timeStr);
            if (markerTime.isBefore(newQueryParams.startTime) || markerTime.isAfter(newQueryParams.endTime)) {
              this.submaps[mapind].removeMapLayer(layerID);
              delete this.dataOnMaps[mapind].sensorsOnMap[markerId];
            }
          }
        }
      }
    }

    let callbackPromises = []; // Make an array of Promises that must be resolved for this function to be resolved
    let c = await this.sensors.updatePageByPage(newQueryParams,(items)=>{
      let r = this.addSensorsToMap(items);
      callbackPromises.push(r); // Sensors need to be added to the map for this function to be resolved
    });
    this.sensors.queryParams = newQueryParams.copy();

    return Promise.all(callbackPromises);
  }

  public async dataLayerHasChangedOnSubmap(mapind: number) {
    // remove sensors
    for (var id in this.dataOnMaps[mapind].sensorsOnMap) {
      let layerID = this.dataOnMaps[mapind].sensorsOnMap[id];
      if(layerID) {
        this.submaps[mapind].removeMapLayer(layerID);
        delete this.dataOnMaps[mapind].sensorsOnMap[id];
      }
    }

    let p = await this.sensors.updatePageByPage(this.queryParams,(items)=>{
      this.addSensorsToMap(items, mapind);
    });

    return p;
  }

  public async addSensorsToMap(features: any[], submapsToUpdate?: number | number[]): Promise<any> { 
    //console.log(`adding ${features.length} wz sensors to map.`);
    if(!this.active) {
      console.log('Layer is inactive.');
      return Promise.resolve(); // If the user turns off the layer during the querying promise chain
    }

    console.log('AIS Features:');
    console.log(features);

    if(!features || features.length===0) {
      return Promise.resolve();
    }

    if (submapsToUpdate==undefined) {
      submapsToUpdate = [];
      let ctr = 0;
      while(ctr<this.submaps.length) {
        submapsToUpdate.push(ctr);
        ctr++;
      }
    } else if (!Array.isArray(submapsToUpdate)) {
      submapsToUpdate = [submapsToUpdate];
    }

    let noOfSubmaps = this.submaps.length;
    let numAdded = new Array(noOfSubmaps).fill(0);

    // Make sure features get added to the right collection:
    let requestedDataType: wzDataEnums[] = [];
    for (let i=0; i<submapsToUpdate.length; i++){
      let mapind = submapsToUpdate[i];
      requestedDataType.push(this.submaps[mapind].activeDataLayer.dataType);
    }

    // from the sensors collection, extract features for the layer
    for (let feature of features) {
      // extract coordinates of feature
      if (feature.geometry === undefined || feature.geometry.coordinates === undefined) {
        continue;
      };
      // Don't put poorly geolocated data on the map
      if (GEOLOCATION_ACCURACY_THRESHOLD > 0 && feature.properties && feature.properties.loc_accuracy > GEOLOCATION_ACCURACY_THRESHOLD) {
        console.log(`Not adding feature to map due to poor geolocation accuracy (${feature.properties.loc_accuracy} m)`);
        continue;
      }

      //let featureid = feature._id; // use this for now, but then delete from feature
      for (let iField in GEOJSON_FIELDS_TO_FILTER) delete feature[GEOJSON_FIELDS_TO_FILTER[iField]];

      // put the feature on the map
      for (let i=0; i<submapsToUpdate.length; i++){
        let mapind = submapsToUpdate[i];

        // Is this point already on the map?
        let layerID = this.dataOnMaps[mapind].sensorsOnMap[feature._id];
        if(layerID) {
          // If they're the same, do nothing:
          continue;
        }

        // perturb coordinates so you can actually see the difference between similar points
        feature.geometry.coordinates[0] = feature.geometry.coordinates[0] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        feature.geometry.coordinates[1] = feature.geometry.coordinates[1] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;

        // Get value:
        feature.value = this.getValue(feature,this.submaps[mapind].activeDataLayer);

        // put the feature on the map:
        if (!(feature.value===undefined || feature.value===null)) {
          // put it on the map IF the dataType hasn't changed:
          if (requestedDataType[i] === this.submaps[mapind].activeDataLayer.dataType) {
            this.dataOnMaps[mapind].sensorLayer.addData(feature);
            numAdded[mapind]++;
          } else {
            console.log("MISMATCH!!! Not adding id=" + feature._id + "because map layer changed from " + requestedDataType[i] + " to " + this.submaps[mapind].activeDataLayer.dataType);
          } 
        } else {
          // what should we do with NaNs?  Right now we do nothing.
          //console.log('Found a feature with a NaN value:');
          //console.log(feature);
        }
      } // loop over submaps
    } // loop over features
    console.log(`Added ${numAdded} sensors (of ${features.length}) to maps.`);
    // return an empty promise
    return Promise.resolve();
  }

  public sensorMarkerPopupHtml(feature: any, mapind: number) {
    let ship_id = feature.properties.mmsi;
    let dataTableRowCount = 2;
    let dataTableObj = this.dataTable(feature, mapind, dataTableRowCount);
    let dataTableHtml = dataTableObj.table;
    dataTableRowCount = dataTableObj.lastLineNum;

    let html = `<div class='popup'>
      ${this.popupHeaderRow(`AIS Data`)}

      <!--
      ${this.dataRow(this.submaps[mapind].activeDataLayer.label, this.submaps[mapind].activeDataLayer.prettyValueWithUnits(feature.value))}

      <hr>
      -->

      ${this.dataRow(`Ship ID`, ship_id)}
      ${this.dataRow(`Time`, this.formatTimeStringInTZWithTZ(feature.properties.time), true)}

      ${dataTableHtml}

      <div id="popup-plot" style="display:none"></div>
      </div>
    `

    return html;
  }

  public logFeature(feature: any) {
    console.log('AIS feature:');
    console.log(feature);
  }

  /* Implementing abstract function */
  public getDeviceId(feature: any): string {
    return feature.properties.mmsi;
  }
  
  /**
   * For a given feature, get the value that is plotted on the dataLayer.
   * @param  {any}       feature   The sensor record.
   * @param  {DataLayer} dataLayer The dataLayer defining what type of data we're looking for
   * @return {any}       The value.  UNDEFINED if the device is not configured to give data for this data type; NULL if the record for this data type is empty.
   */
  public getValue(feature: any, dataLayer: DataLayer): any {
    let value: any = null; // default, meaning that the device often has this type of data, but it is missing from this sensor record for some reason.
    let units: string = '';

    // Define the simple mapping from a wzDataEnum to a feature.property:
    let dataLookups = {};
    //dataLookups[wzDataEnums.????]                   = {property: 'source', units, '?'};
    //dataLookups[wzDataEnums.????]                   = {property: 'mmsi', units, '?'};
    //dataLookups[wzDataEnums.????]                   = {property: 'dac', units, '?'};
    //dataLookups[wzDataEnums.????]                   = {property: 'fi', units, '?'};
    //dataLookups[wzDataEnums.????]                   = {property: 'name', units, '?'};
    dataLookups[wzDataEnums.INPUT_VISIBILITY]         = {property: 'visibility', units: 'nmi'}; 
    dataLookups[wzDataEnums.HUMIDITY]                 = {property: 'relative_humidity', units: '%'};
    dataLookups[wzDataEnums.WIND_SPEED]               = {property: 'wind_speed', units: 'm/s'};
    dataLookups[wzDataEnums.WIND_DIRECTION]           = {property: 'wind_direction', units: 'degrees'};
    dataLookups[wzDataEnums.PEAK_GUST_SPEED]          = {property: 'wind_gust_speed', units: 'm/s'};
    //dataLookups[wzDataEnums.???]                    = {property: 'wind_gust_direction', units: 'degrees'};
    dataLookups[wzDataEnums.STATION_PRESSURE]         = {property: 'pressure', units: 'mbar'};
    //dataLookups[wzDataEnums.???]                    = {property: 'pressure_trend', units: ''};
    dataLookups[wzDataEnums.AIR_TEMPERATURE]          = {property: 'ambient_temperature', units: 'C'};
    dataLookups[wzDataEnums.SEA_SURFACE_TEMPERATURE]  = {property: 'water_temperature', units: 'C'};
    dataLookups[wzDataEnums.OW_SALINITY]              = {property: 'salinity', units: 'PSU'};
    //dataLookups[wzDataEnums.???]                    = {property: 'ice', units: ''};
    //dataLookups[wzDataEnums.???]                    = {property: 'precipitation', units: ''};
    dataLookups[wzDataEnums.DEW_POINT]                = {property: 'dew_point', units: 'C'};
    dataLookups[wzDataEnums.AVERAGE_WAVE_PERIOD]      = {property: 'wave_period', units: 's'};
    dataLookups[wzDataEnums.WAVE_HEIGHT]              = {property: 'wave_height', units: 'm'};
    //dataLookups[wzDataEnums.???]                    = {property: 'wave_direction', units: 'degrees'};
    dataLookups[wzDataEnums.DOMINANT_WAVE_PERIOD]     = {property: 'swell_period', units: 's'};
    //dataLookups[wzDataEnums.???]                    = {property: 'swell_height', units: ''};
    dataLookups[wzDataEnums.DOMINANT_WAVE_DIRECTION]  = {property: 'swell_direction', units: 'degrees'};
    //dataLookups[wzDataEnums.???]                    = {property: 'water_height', units: ''};
    //dataLookups[wzDataEnums.???]                    = {property: 'sea_state', units: ''};

    // Find the datum:
    if (feature.properties) {
      let simpleSpec = dataLookups[dataLayer.dataType];
      if (simpleSpec) {
        value = feature.properties[simpleSpec.property];
        units = simpleSpec.units;
      } else {
        //console.log('No simple spec');
        switch (dataLayer.dataType) {
          case wzDataEnums.BAROMETRIC_PRESSURE: {
            if (feature.properties.pressure && feature.properties.ambient_temperature) {
              let altitude = 0.;
              let temperature = feature.properties.ambient_temperature + 273.15;
              value = this.getBarometricPressure(feature.properties.pressure, temperature, altitude);
              units = 'mbar';
            } else {
              value = null;
              units = 'mbar';                
            }
            break;
          }
          case wzDataEnums.FULL_REPORT: {
            value = true;
            units = undefined;
            break;
          }
          default: { 
            value = null; // means that the device cannot have data for this dataType.
            units = undefined;
            break;
          }
        } // end switch statement
      }
    } else {
      value = null;
      units = undefined;
    }

    return dataLayer.convertValue(value, units);
  }

  public getImageLinks(feature: any) {
    return {url: ''};
  }

  public getAudioLink(feature: any) {
    return '';
  }

  /* Implementing abstract function */
  public exportDataOnMapAsJSON(mapind: number): any {
    return null;
  }

  /* Implementing abstract function */
  public getCustomData(feature:any): Array<iDatum> {
    return [];
  }
  
  /**
   * From a given feature, find that device's histary 
   * @param  {any}   feature [description]
   * @return {any[]}         [description]
   */
  public async getHistory(feature: any, startTime?: moment.Moment, endTime?: moment.Moment, historyFormat?: string) {
    // First, get ship ID:
    let ship_id = feature.properties.mmsi;

    // Next, find all sensor records with these device IDs
    let devQuery = new QueryParams(startTime,endTime,undefined,{'properties.mmsi':ship_id});
    let features = await this.sensors.getAllRecords(devQuery,undefined,undefined,undefined,false); // Don't interrupt getting the history

    // Generate a nice history object:
    let hist: any;
    if(!features || features.length===0) {
      console.log('No history for this device over this time window.');
      hist = [];
    } else {
      switch (historyFormat) {
        case 'vegaJson':
          hist = [];
          for (let feature of features) {
            let pt = {};
            pt['lineIdx'] = 0; // we can split the data into multiple plotted lines if we wish 
            pt['time'] = feature.properties.time;
            pt['Time'] = this.formatTimeStringInTZWithTZ(feature.properties.time); // Add formatted time string to Vega JSON (instead of writing own tooltip handler)
            for (let dataLayer of this.parent.dataLayers) {
              pt[dataLayer['label']] = this.getValue(feature,dataLayer);
            }
            hist.push(pt);
          }
          break;
        case 'arrays':
          // Now that we have all features, it is time to make it into a history:
          hist = {};
          hist['time'] = [];
          for (let dataLayer of this.parent.dataLayers) {
            hist[dataLayer.label] = [];
          }
          for (let feat of features) {
            hist['time'].push(feat.properties.time);
            for (let dataLayer of this.parent.dataLayers) {
              hist[dataLayer['label']].push(this.getValue(feat,dataLayer));
            }
          }

          // Get rid of fields that have no data:
          for (let p in hist) {
            if(!hist[p].some( (el)=>{return el;})) {
              //console.log(`${p} is empty.`);
              delete hist[p];
            }
          }
          break;
        default:
        case 'raw':
          hist = features;
      }
    }
    return hist;
  }

  public makePermalinkObject(): any {
    let PLObj = {};
    // not doing anything with permalinks right now    
    return PLObj;
  }

  public async interpretPermalinkObject(PLObj: any): Promise<any> {
    // not doing anything with permalinks right now    
    return Promise.resolve(null);
  }

  public async openPopups(mapData: any): Promise<any> {
    let ds = this;
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      if (mapData && mapData.length>mapind && mapData[mapind].popups) {
        for (let idx in mapData[mapind].popups) {
          let popup = mapData[mapind].popups[idx];
          let layerId = this.dataOnMaps[mapind].sensorsOnMap[popup.feature_id];
          if (layerId!==undefined) {
            let marker = this.submaps[mapind].getMapLayer(layerId);
            switch (popup.type) {
              case 'history':
                this.showHistory(mapind, ds, marker.feature);
                break;
              default:
              case 'table':
                marker.openPopup();
                break;
            }
          }
        }
      }
    }
    return Promise.resolve();
  }

}
