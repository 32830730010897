/**
 * Root document for Weather Citizen Map Interface
 */

import $ from 'jquery';
import L from 'leaflet';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import * as ImageSize from 'image-size';
import 'moment-timezone';
import 'moment-round';
import 'eonasdan-bootstrap-datetimepicker';
import '../components/leaflet-dropdown/leaflet-dropdown';

import {
  STATIC_STREETS_URL,
  STATIC_SATELLITE_URL,
  STATIC_SATELLITE_STREETS_URL,
  STATIC_OUTDOORS_URL,
  MBOX_LINK,
  MAPBOX_ATTRIB,
  BASELAYERS,
  DEFAULT_BASELAYER,
  DEBOUNCE_TIME,
  MASTER_MAP,
  SIDEBAR_ON_LEFT
} from '../webmap-config';

import {
  Webmap
} from '../Webmap';

import {
  QueryParams,
} from './query-params';

import {
  wzDataEnums,
  DataLayer,
  iDatum,
} from './data-layer';

// Components
import { ColorMapLegend } from '../components/legend/colormap-legend';

export interface iSubmap {
  parent: Webmap;
  idx: number;
  mapid: string;
  dataLayers: DataLayer[];
  activeDataLayerIndex?: number;
  llb?: L.LatLngBounds; 
  permalinkMaker?: Function;
  hideControls?: boolean; 
}

export class Submap {
  public parent: Webmap; // Submaps may need to know their parent in order to notify sources when the data layer has changed.
  public idx: number;
  public map: L.Map;
  public control: L.Control; // Controls the maps (aerial, contour, etc.)
  public legend: ColorMapLegend;
  public dataLayers: DataLayer[];  // keep all the dataLayers around, so we can get them for things like the device history.  This should just be a pointer to the parent DataLayer structure
  private _activeDataLayerIndex: number; // The index of the active DataLayer in this.dataLayers[] array.
  public dataLayerChanger: L.Control; // Dropdown for picking which DataLayer to show
  // Defined for idx = MASTER_MAP (submap 0) only:
  public startDTP: BootstrapV3DatetimePicker.Datetimepicker | undefined;
  public endDTP: BootstrapV3DatetimePicker.Datetimepicker | undefined;
  public bounceTimeout: number | undefined;
  public permalinkButton: L.Control | undefined;
  public popups: any;
  public radarLayers: L.TileLayer[];
  public radarPanes: string[]; 

  constructor(config: iSubmap) {
    this.parent = config.parent;
    this.idx = config.idx;
    this.map = L.map(config.mapid,{
      zoomControl: false, // we'll add our own zoom control (with its custom location) later.
      zoomSnap: 0.1,
      zoomDelta: 0.1,
      maxZoom : 24,
      contextmenu: true,
      contextmenuWidth: 160,
      contextmenuItems: this.contextMenuArray(this.parent, this.idx)
    });

    this.popups = [];
    this.map.on('popupclose',(e)=>{
      this.removeAllPopupRecords();
    });


    // Zoom to the right area to start:
    if (config.llb) {
      this.map.fitBounds(config.llb);
    }

    // ADD CONTROLS (in order, top to bottom):

    // Data Layer Changer (e.g. select "images" or "salinity")
    this.dataLayers = config.dataLayers;
    this.initializeDataLayerChanger(config.dataLayers, config.activeDataLayerIndex);

    // Map Layer Control:
    let staticStreetsMap = L.tileLayer(STATIC_STREETS_URL, {attribution: MAPBOX_ATTRIB, tileSize: 512, zoomOffset: -1});
    let staticSatelliteMap = L.tileLayer(STATIC_SATELLITE_URL, {attribution: MAPBOX_ATTRIB, tileSize: 512, zoomOffset: -1});
    let staticSatelliteStreetsMap = L.tileLayer(STATIC_SATELLITE_STREETS_URL, {attribution: MAPBOX_ATTRIB, tileSize: 512, zoomOffset: -1});
    let staticOutdoorsMap = L.tileLayer(STATIC_OUTDOORS_URL, {attribution: MAPBOX_ATTRIB, tileSize: 512, zoomOffset: -1});
    let bathymetryMap = L.tileLayer('https://tileservice.charts.noaa.gov/tiles/50000_1/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.noaa.gov/">NOAA</a>'
    });
    let oceanReliefMap = L.tileLayer.wms('https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?',{
      layers: 'GEBCO_LATEST', 
      attribution: '&copy; <a href="http://www.gebco.net/">GEBCO</a>'
    }); 


    switch(DEFAULT_BASELAYER) {
      default:
      case BASELAYERS.STATIC_STREETS: {
        staticStreetsMap.addTo(this.map);
        break;
      }
      case BASELAYERS.STATIC_SATELLITE: {
        staticSatelliteMap.addTo(this.map);
        break;
      }
      case BASELAYERS.STATIC_SATELLITE_STREETS: {
        staticSatelliteStreetsMap.addTo(this.map);
        break;
      }
      case BASELAYERS.STATIC_OUTDOORS: {
        staticOutdoorsMap.addTo(this.map);
        break;
      }
    }
   
    //Make map layer control (on all maps):
    let baseLayers = {
      'Streets': staticStreetsMap,
      'Satellite': staticSatelliteMap,
      'Hybrid': staticSatelliteStreetsMap,
      'Contours': staticOutdoorsMap,
      'Nautical Chart': bathymetryMap,
      'Sea Floor': oceanReliefMap,
    };

    if (!config.hideControls) {
      this.control = L.control.layers(baseLayers,undefined,{
        position: SIDEBAR_ON_LEFT ? 'topright' : 'topleft',
      }).addTo(this.map);      
    }

    // Permalink Button:
    if (this.idx===MASTER_MAP && config.permalinkMaker && !config.hideControls) {
      this.addPermalinkButton(config.permalinkMaker, SIDEBAR_ON_LEFT ? 'topright' : 'topleft');
    }

    // Click to Zoom In/Out:
    if (this.idx===MASTER_MAP && !config.hideControls) {
      // Add zoom control:
      L.control.zoom({
        position: SIDEBAR_ON_LEFT ? 'topright' : 'topleft'
      }).addTo(this.map);      
    }
  
    // On construction, secretly set the active data layer - don't tell the submaps yet
    // because this submap isn't done being initialized and hasn't been pushed to the 
    // webmap.submaps[] array 
    this._activeDataLayerIndex = config.activeDataLayerIndex;
    // Create the legend:
    if(this.activeDataLayer.showLegend) {
      this.legend = new ColorMapLegend(this.activeDataLayer);
      this.legend.addTo(this.map);      
    }

    this.radarLayers = [];
  }

  public establishRadar(radarTimes: moment.Moment[]) {
    // Delete existing radar layers:
    if (this.radarLayers) {
      for (let i=0; i< this.radarLayers.length; i++) {
        this.map.removeLayer(this.radarLayers[i]);
      }      
    }
    // Delete existing radar panes:
    this.radarLayers = [];
    if (this.radarPanes) {
      for (let i=0; i< this.radarPanes.length; i++) {
        L.DomUtil.remove(this.radarPanes[i]);
      }      
    }
    this.radarPanes = [];

    for (let i=0; i<radarTimes.length; i++) {
      let timeStr = radarTimes[i].utc().format();
      this.map.createPane(timeStr);
      this.map.getPane(timeStr).style.zIndex = '450';
      this.radarPanes.push(timeStr);
      // Start by hiding all radar layers; setRadarLayer() needs to be called to turn the first one on
      this.map.getPane(timeStr).style.visibility = 'hidden';
      let radarLayer = L.tileLayer.wms("https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r-t.cgi", {
        layers: 'nexrad-n0r-wmst',
        format: 'image/png',
        transparent: true,
        opacity: 0.5,
        //zIndex: 450,
        pane: timeStr
      });
      radarLayer.wmsParams.time = timeStr;
      radarLayer.addTo(this.map);
      this.radarLayers.push(radarLayer);
    }
  }

  public setRadarLayer(idx: number) {
    // First, hide all the layers (should only be 1 showing)
    for (let i= 0; i<this.radarLayers.length; i++) {
      if (i!=idx) {
        this.map.getPane(this.radarPanes[i]).style.visibility = 'hidden';
      } 
    }
    // Then, show the desired radar layer (if it exists)
    if (idx >= 0 && idx < this.radarLayers.length) {
      this.map.getPane(this.radarPanes[idx]).style.visibility = 'visible';
    }
  }

  public activateRadar() {
    this.setRadarLayer(0);
  }

  public deactivateRadar() {
    this.setRadarLayer(-1);
  }

  public toJSON(): any {
    let md = {};
    // active data layer - md.dl and md.dataLayer both work, but using the label prevents problems
    // right now since we don't put this.dataLayers in the URL object yet:
    //md.dl = this.activeDataLayerIndex;
    md['dataLayer'] = this.dataLayers[this.activeDataLayerIndex].label;
    if (this.popups && this.popups.length>0) {
      md['popups'] = this.popups;
    }
    return md;
  }

  /**
   * Create the data layer changer, and set the active data layer to the default.
   * If one already existed, replace it.
   * @param {DataLayer[]} dataLayers The datalayers to include in the data layer changer
   */
  public initializeDataLayerChanger(dataLayers?: DataLayer[], activeDataLayerIndex?: number) {
    if (this.dataLayerChanger) {
      this.map.removeControl(this.dataLayerChanger);
    }
    if (dataLayers==undefined) {
      dataLayers = this.dataLayers;
    }

    let dataLayerChanger = L.Control.dropdown({
      position : SIDEBAR_ON_LEFT ? 'topright' : 'topleft',
      items : dataLayers,
      initialSelectedItemIndex : activeDataLayerIndex,
      onChangeCallback: (dl,idx)=>{
        this.activeDataLayerIndex = idx;
      },
    });

    dataLayerChanger.addTo(this.map);
    this.dataLayerChanger = dataLayerChanger;  
  }

  /**
   * Get the active data layer.
   * @return {DataLayer} The active DataLayer
   */
  public get activeDataLayer(): DataLayer {

    return this.dataLayers[this._activeDataLayerIndex];
  }

  public get activeDataLayerIndex(): number {
    return this._activeDataLayerIndex;
  }

  /*
   * Set the active data layer
   */
  public set activeDataLayerIndex(idx: number) {
    //if (idx===this._activeDataLayerIndex) return;

    this._activeDataLayerIndex = idx;
    console.log(`Changing active data layer on submap ${this.idx} to ${this.activeDataLayer.label}`);

    // Clear all data off the map:

    // get rid of old legend:    
    if(this.legend) {
      this.legend.remove();
    }
    // Create the legend:
    if(this.activeDataLayer.showLegend) {
      this.legend = new ColorMapLegend(this.activeDataLayer);
      this.legend.addTo(this.map);      
    }

   // Update map:
   this.parent.dataLayerHasChangedOnSubmap(this.idx);
  }

  public getMapLayer(layerId: number) {
    let layer = undefined;
    if (this.map && layerId) {
      layer = this.map._layers[layerId];
    }
    return layer;
  }

  public removeMapLayer(layerId: number) {
    let layer = this.getMapLayer(layerId);
    if (layer) {
      layer.remove();
    }
  }

  /**
   * Add a permalink button to a map.  If one already exists, replace it.
   */
  public addPermalinkButton(permalinkFunction: Function, position?: string) {
    // Get rid of old button (if it exists)
    if (this.permalinkButton) {
      this.removePermalinkButton();
    }
    var btn = L.easyButton('fa-link fa-lg' , ()=>{permalinkFunction();}, 'Create a link (URL) to current view', /*maps[imap]*/ ''); // prevent auto-addition to map so can set position first
    btn.options.position = position ? position : SIDEBAR_ON_LEFT ? 'topleft' : 'topright';
    this.permalinkButton = btn;
    this.map.addControl(btn);
  }

  /**
   * Take permalink button off of a map (if it exists)
   */
  public removePermalinkButton() {
    if (this.permalinkButton) {
      this.map.removeControl(this.permalinkButton);
      this.permalinkButton = undefined;      
    }
  }

  public addPopupRecord(popup: any) {
    this.popups.push(popup);
  }

  public removeAllPopupRecords() {
    this.popups = [];
  }

  /**
   * Create what's in the menu when the user right-clicks on the map.
   */
  public contextMenuArray(wm: Webmap, mapind: number) {
    let arr = [
      {text: 'Export all to Excel', iconCls: 'glyphicon glyphicon-save-file',  callback: ()=>{this.exportMapData(wm, mapind);}},
      {text: 'Export all to Excel (no images)', iconCls: 'glyphicon glyphicon-save-file',  callback: ()=>{this.exportMapData(wm, mapind, false);}},
    ];
    return arr;
  }

  public resetContextMenu() {
    this.map.contextmenu.hide();
    // If the context menu was specific to an item on the map, we need to reset it
    this.map.contextmenu.removeAllItems();
    // Go back to the default map-level context menu
    let itemsToAdd = this.contextMenuArray(this.parent, this.idx)
    for (let cmItem of itemsToAdd) {
      this.map.contextmenu.insertItem(cmItem);
    }
  }

  public async getFileFromUrl(url) {
    if (!url) {
      return undefined;
    }
    
    let g = await $.ajax({
      method: 'GET',
      url: url,
      xhrFields: {
       responseType: 'blob'
      },
    });

    return g;
  }

  /**
   * Takes all the data on the map and writes it to an Excel file.
   * @param wm The webmap object
   * @param mapind The map index number (default map is 0)
   */
  public async exportMapData(wm: Webmap, mapind: number, includeImages: boolean = true) {
    console.log('Exporting data:');
    /* Retrieve the data in json format: */
    let records = [];
    for (let source of wm.sources) {
      let srcData = source.exportDataOnMapAsJSON(mapind);
      for (let recordId in srcData) {
        records.push(srcData[recordId]);
      }
    }

    this.exportFileFromRecords(records, undefined, includeImages);
  }

  /**
   * Writes an Excel file containing the data from the records in the records array
   * @param records Array of Array of iDatums
   */
  public async exportFileFromRecords(records: Array<Array<iDatum>>, filenameWithoutExtension: string = 'mapdata', includeImages: boolean = true) {

    /* makeLabelUnitPair takes an iDatum and makes a pretty "label (units)" string: */
    function makeLabelUnitPair(datum: iDatum): string {
      let lu = datum.label;
      if (datum.units) {
        lu += ' (' + datum.units + ')';
      }
      return lu;
    }

    /**
     * getUniqueLabelUnitPairs takes a data struct [[iDatum]] and creates
     * a unique list of label/units pairs
     */
    function getUniqueLabelUnitPairs(records: Array<Array<iDatum>>): Array<string> {
      let labelUnitPairs = [];
      for (let record of records) {
        record.forEach(function(datum) {
          if (datum.value) {
            let labelWithUnits = makeLabelUnitPair(datum);
            if (!labelUnitPairs.includes(labelWithUnits)) {
              labelUnitPairs.push(labelWithUnits);
            }
          }
        });
      }

      return labelUnitPairs;
    }

    // Start writing the file:
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Map Data');

    let rows = [];
    // Add header row:
    let headerArray = getUniqueLabelUnitPairs(records);
    rows[0] = headerArray;
    let imageCounter = 0;
    let numRows = 1 + records.length;
    // Add data rows:
    for (let idx in records) {
      let record = records[idx];
      let rowArray = new Array(headerArray.length);
      for (var datum of record) {
        if (datum.value) {
          let labelWithUnits = makeLabelUnitPair(datum);
          let cellContent = undefined;
          switch (datum.label) {
            case 'Record ID':
              cellContent = {text: datum.value, hyperlink: window.location.href.split('?')[0] + '?RECORD=' + datum.value};
              break;
            case 'Image':
              try {
                let imageLabel = "Link to Image";
                if (includeImages) {
                  let blob = await this.getFileFromUrl(datum.value.thumbnail_url);
                  let type = blob.type.split('/')[1];
                  let ab = await blob.arrayBuffer();
                  let c = await ImageSize.imageSize(Buffer.from(ab,'binary'));
                  const img = workbook.addImage({buffer: ab, extension: type});
                  imageLabel = `Image ${imageCounter+1}`;
                  const rowStride = 20;
                  const colStride = 8;
                  sheet.addImage(img, {
                    tl: {col:1+colStride*(imageCounter%2), row:numRows+1+rowStride*Math.floor(imageCounter/2)},
                    ext: {width: c.width, height: c.height},
                    hyperlinks: {
                      hyperlink: datum.value.url,
                      tooltip: imageLabel
                    }
                  });
                  imageCounter++;
                }
                // Generate an error if there's no datum.value.url
                let hyperlink = datum.value.url;
                if (!hyperlink) {
                  throw('Image url not found.');
                }
                cellContent = {text: `(${imageLabel})`, hyperlink: hyperlink};
              } catch (e) {
                console.log('Caught an error in image retrieval:');
                console.log(e);
                cellContent = 'Image not found';
              }
              break;
            case 'Audio':
              if (datum.value !== undefined) {
                cellContent = {text: 'Link to Audio', hyperlink: datum.value};
              } else {
                cellContent = 'Audio not found';
              }
              break;
            default:
              cellContent = datum.value;
          }
          rowArray[headerArray.indexOf(labelWithUnits)] = cellContent;
        }
      };
      rows.push(rowArray);
    }
    
    sheet.addRows(rows);
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], {type: fileType});

    saveAs(blob, filenameWithoutExtension + fileExtension);
  }

}