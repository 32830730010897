/**
 * Component for posting/getting JSON objects from an api
 */
import $ from 'jquery';

export class JsonApi {
	private api: string;
	private auth: string;

	constructor(api: string, auth: string) {
		this.api = api;
		this.auth = auth;
	}

	public async post(dataString: string): Promise<any> {

		let resp = await $.ajax({
			type: "POST",
			url: this.api,
			data: dataString,
			xhrFields: {
				withCredentials: true
			},
			headers: {
				'Authorization': this.auth,
				'Content-Type': 'application/json'
			}
		});

		let tinyUrl = '';
		if (resp && resp._id) {
			tinyUrl = resp._id;
		} else {
			console.log('Error posting to json api');
			tinyUrl = '';
		}

		return tinyUrl;
	}

	public async get(id: string): Promise<any> {

		let resp = await $.ajax({
			type: "GET",
			url: `${this.api}/${id}`,
			xhrFields: {
				 withCredentials: true
			},
			headers: {
				 'Authorization': this.auth,
				 'Content-Type': 'application/json'
			},
		}); //`

		return resp;
	}
}