/**
 * Weather Citizen Data Source Collections
 */


import {
  QueryParams
} from '../../core/query-params';

import {
  DSMETHOD, Collection
} from '../../core/collection';

import {
  DataSource
} from '../../core/data-source';

/**
 * Device collection
 * @type {Collection}
 */
export class DeviceCollection extends Collection {

  constructor(parent: DataSource, api_root: string, api_point: string, auth: string, coll_label: string, coll_color?: string, sortBy?: string) {
    if(!sortBy) {sortBy = 'properties.time'}; // we want to sort in this order by default so that new points are plotted on top of old ones.
    super(parent, 'DeviceCollection', api_root, api_point, auth, coll_label, coll_color, sortBy);
    this.downsampleMethod = DSMETHOD.ALL;
    this.features = {};

    let fakeFeature = {
      '_id': '5aff402ae47078a921d86b15',
      'properties': {'uuid': 'garbage'}
    };
    //this.features.push(fakeFeature);
  }

  public getDeviceLocally(deviceID: string): any {
    let goodfeat = undefined;
    if (this.features) {
      goodfeat = this.features[deviceID];
    }
    if (!goodfeat) {
      console.log(`ERROR: deviceID ${deviceID} not found locally! result is undefined.`);
    }
    return goodfeat;
  }

  public async getDevice(deviceID: string, interruptible: boolean=false): Promise<any> {
    //console.log(`getting device ${deviceID}`);
    //console.log(this.features);
    let goodfeat;
    if (this.features) {
      goodfeat = this.features[deviceID];
    }
    if (goodfeat) {
      //console.log(`devID: ${deviceID} exists locally.`);
    } else {
      goodfeat = await this.getRecordWithID(deviceID,interruptible); // this is a query that shouldn't be interrupted.
      if (goodfeat) {
        //console.log(`Device found on server, and added to local cache.`);
        this.addItemsToFeatureArray(goodfeat);
      } else {
        console.log(`ERROR: deviceID ${deviceID} not found on server! result is null.`);
      }
    }
    return goodfeat;
  }  

  /**
   * Find the UUID for a given "device" string
   * @param {string} devID - Device ID string
   * @return {string} UUID
   */
  public async getUUID(devID: string, interruptible: boolean=false): Promise<string> {
    let goodfeat = await this.getDevice(devID,interruptible);
    if (goodfeat) {
      return goodfeat.properties.uuid;
    } else {
      return 'unknown';
    }
  }
};


/**
 * Geosensor collection
 * @type {Collection}
 */
export class SensorCollection extends Collection {

  constructor(parent: DataSource, api_root: string, api_point: string, auth: string, coll_label: string, coll_color?: string, sortBy?: string) {
    if(!sortBy) {sortBy = 'properties.time'}; // we want to sort in this order by default so that new points are plotted on top of old ones.
    super(parent, 'SensorCollection', api_root, api_point, auth, coll_label, coll_color, sortBy);
    this.downsampleMethod = DSMETHOD.ALL;
  }

  /**
   * Get a list of the unique device records for a given query
   * @param  {QueryParams}  queryParams 
   * @return {Promise<any>}             An array of objects containing the unique device records
   */
  public async getUniqueDeviceRecords(queryParams: QueryParams, interruptible: boolean = true): Promise<any> {
    // Make the query:
    let page = 1;

    let devRecords: any[] = [];
    let resp = await this.queryUniqueDevAPI(queryParams, page, interruptible);
    // Go through page by page and store:
    while (resp._items.length>0) {
      let subset = resp._items.flatMap((item)=>{
        // every item returned should have item.most_recent.properties.device[0]; if they do not, 
        // it could be a problem with the server database not finding a device record.
        if (item.most_recent && item.most_recent.properties && item.most_recent.properties.device && item.most_recent.properties.device[0]) {
          return item.most_recent.properties.device[0];
        } else {
          console.log(`Warning: could not find device record associated with geosensor id ${item._id}`);
        }
        return [];
      });
      devRecords = devRecords.concat(subset);
      page++;
      resp = await this.queryUniqueDevAPI(queryParams, page, interruptible);
    }
    return devRecords;
  }

  /**
   * Make a specific query to the unique device ID aggregate API 
   * @param  {QueryParams}  queryParams The query range
   * @param  {number    =           1}           page          
   * @param  {boolean   =           true}        interruptible 
   * @return {Promise<any>}             A query response.
   */
  public async queryUniqueDevAPI(queryParams: QueryParams, page: number = 1, interruptible: boolean = true): Promise<any> {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api + '/unique/device',
      data: {
        'aggregate': JSON.stringify({ "$where":queryParams.asFilterObj() }),
        'page': page>0? page : 1
      },
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return resp;
  }

};


/**
 * Geomedia collection
 * @type {Collection}
 */
export class GeomediaCollection extends Collection {

  constructor(parent: DataSource, api_root: string, api_point: string, auth: string, coll_label: string, coll_color?: string, sortBy?: string) {
    if(!sortBy) {sortBy = 'properties.time'}; // we want to sort in this order by default so that new points are plotted on top of old ones.
    super(parent, 'GeomediaCollection', api_root, api_point, auth, coll_label, coll_color, sortBy);
    this.downsampleMethod = DSMETHOD.ALL;
  }

  public async getMedia(mediaID: string, interruptible:boolean=true) {
    //console.log(`getting media ${mediaID}`);
    //console.log(this.features);
    let goodfeat;
    if (this.features) {
      goodfeat = this.features[mediaID];
    }
    if (goodfeat) {
      //console.log(`mediaID ${mediaID} exists locally.`);
      return goodfeat;
    } else {
      let mediaIDparams = new QueryParams(undefined,undefined,undefined,{'_id': mediaID});
      if (1) {
        //console.log('media the fast way:');
        mediaIDparams.additionalParams = {'width': 300};
      } else {
        //console.log('media the slow way:');
      }

      let goodfeatArray = await this.getAllRecords(mediaIDparams,undefined,undefined,undefined,interruptible);
      if(goodfeatArray) {
        if (goodfeatArray.length===0) {
          console.log(`ERROR: mediaID ${mediaID} not found on server!  length==0`);
          return null;
        } else if (goodfeatArray.length===1) {
          //console.log(`Device found on server, and added to local cache.`);
          //console.log(goodfeatArray[0]);
          this.addItemsToFeatureArray(goodfeatArray[0]);
          return goodfeatArray[0];
        } else {
          console.log(`ERROR: Duplicate media IDs found on server!!!\nFound ${goodfeatArray.length}.  Returning first record.`);
          //console.log(goodfeatArray);
          return goodfeatArray[0];
        }
      } else {
          console.log(`ERROR: mediaID ${mediaID} not found on server! result is null.`);
          return null;
      }
    }
  }

  public getMediaLocally(mediaID: string): any {
    let goodfeat;
    if (this.features) {
      goodfeat = this.features[mediaID];
    }
    if (goodfeat) {
      //console.log(`devID: ${mediaID} exists locally.`);
      return goodfeat;
    } else {
      //console.log(`ERROR: mediaID ${mediaID} not found locally! result is undefined.`);
      return undefined;
    }
  }

  public async getccpred(mediaID: string, interruptible: boolean=true): Promise<any> {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/ccpred/' + mediaID,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    },interruptible);
    return resp;
  }

  public async getweatherLabels(mediaID: string, interruptible: boolean=true): Promise<any> {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/weatherlabels/' + mediaID,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    },interruptible);
    return resp;
  }



}
