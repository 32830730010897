/**
 * National Buoy Database Data Source and Collections
 */

import L from 'leaflet';
import moment from 'moment';
import 'moment-timezone';
import {sprintf} from 'sprintf-js';

import {
  Webmap
} from '../../webmap'

import {
  QueryParams
} from '../../core/query-params'

import {
  DSMETHOD, Collection
} from '../../core/collection'

import {
  GEOJSON_FIELDS_TO_FILTER,
  GPS_LAT_LON_PERTURB_SCALE,
  MAX_FEATURES_PER_LAYER,
  QUERY_PADDING,
  SHOW_PREDICTIONS
} from '../../webmap-config'

import {
  wzDataEnums,
  DataLayer,
  iDatum,
} from '../../core/data-layer';

import {
  DataSource, iDataSource
} from '../../core/data-source';

import {
  Submap,
} from '../../core/submap';


/**
 * NDBC collection
 * @type {Collection}
 */
export class NDBCCollection extends Collection {

  constructor(parent: DataSource, subtype: string, api_root: string, api_point: string, auth: string, coll_label: string, coll_color?: string, sortBy?: string) {
    if(!sortBy) {sortBy = '-properties.time'}; // we want to sort in this order by default so we can get just one point per buoy.
    super(parent, subtype, api_root, api_point, auth, coll_label, coll_color, sortBy);
    this.downsampleMethod = DSMETHOD.ALL;
  }

  public featureIndexingFunction(feature: any) {
    return feature.properties && feature.properties.station_id;
  }

  /**
   * Return the latest buoycam with a given station ID
   * @param  {string} stationID  The station ID to search for
   * @param  {QueryParams} queryParams  The query parameters to search by
   * @return {feature}    buoycam feature
   */
  public async getLatestWithID(ID: string, queryParams?: QueryParams, interruptible?: boolean) {
    if (interruptible===undefined || interruptible===null) {interruptible=true;}

    if(this.queryParams && this.queryParams.contains(queryParams) && this.features.hasOwnProperty(ID)) {
      //console.log('found locally.');
      return this.features[ID];
    } else {
      //console.log('checking server');
      let IDparams = queryParams.copy();
      IDparams.additionalFilters = {'properties.station_id': ID}; 
      IDparams.additionalParams = {'max_results': 1, 'projection': {'subimage0': 0, 'subimage1': 0, 'subimage2': 0, 'subimage3': 0, 'subimage4': 0, 'subimage5': 0}};
      let resp = await this.queryData(IDparams, undefined, interruptible);
      let goodArray = resp ? resp._items : null;
      if(goodArray) {
        if (goodArray.length===0) {
          console.log(`ERROR: ID ${ID} not found on server!  length==0`);
          return null;
        } else {
          //console.log(`Getting latest feature by ID: found ${goodArray.length}`);
          // don't update this.features, because this.queryParams has not changed.
          return goodArray[0];
        } 
      }      
    }
  }


  public getLocalFeatureWithID(ID: string) {
    return this.features[ID];
  }


  public async getUniqueStationsInRange(queryParams: QueryParams, page: number = 1, interruptible: boolean = true): Promise<any> {
    // Make the query:
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api + '/stations',
      data: {
        'aggregate': JSON.stringify({ "$where":queryParams.asFilterObj() }),
        'page': page>0? page : 1
      },
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return resp;
  }


  public async updatePageByPageWithAggregation(queryParams: QueryParams, callback?: Function, args?: {}, interruptible?: boolean) {
    if (interruptible===undefined || interruptible===null) {interruptible=true;}

    var isFinished, isRejected;
    //this.outstandingFunctions['updatePageByPageWithAggregation'] 
    if (interruptible) {
      var z = new Promise((resolve, reject)=>{
        isFinished = resolve;
        isRejected = reject;
      });
    }

    let requestedQueryParams = queryParams.copy();
    if (1) { //!(this.collectionContains(requestedQueryParams))) {
      // Pad the lat/lon range:
      requestedQueryParams.expand(QUERY_PADDING);

      // Get all station IDs on the server:
      let page = 1;
      let resp = await this.getUniqueStationsInRange(requestedQueryParams,page, interruptible);

      // Go through page by page and store:
      while (resp._items.length>0) {
        if (callback) {
          callback(resp._items, args);
        }
        page++;
        resp = await this.getUniqueStationsInRange(requestedQueryParams,page, interruptible);
      }
    } else {
      console.log('Queries are the same.')
    }
    //Once complete, update the collection's queryParams:
    this.queryParams = queryParams.copy();

    if (interruptible) {
      isFinished();
    }
    return Promise.resolve(null);
  }

  public async getccpred(mediaID: string, interruptible: boolean=true): Promise<any> {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/ccpred/' + mediaID,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    },interruptible);
    return resp;
  }

  public async getweatherLabels(mediaID: string, interruptible: boolean=true): Promise<any> {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/weatherlabels/' + mediaID,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    },interruptible);
    return resp;
  }

  public async updateBuoycamForNewServer(id: string) {
    let recordId = this.features[id]._id;
    let newFeature = await this.getRecordWithID(recordId);
    Object.assign(this.features[id],newFeature);
    return Promise.resolve();
  }

}


/**
 * National Data Buoy Center (NDBC) Source
 * @type {DataSource}
 */
export interface iNDBCSource extends iDataSource {
}

export class NDBCSource extends DataSource {
  private auth: string;
  public buoys: NDBCCollection;
  public buoycams: NDBCCollection;
  public queryParams: QueryParams | null;
  public dataOnMaps: any[];

  constructor(config: iNDBCSource, auth: string) {
    super(config);
    this.auth = auth;
    this.buoys = new NDBCCollection(this, 'BuoyCollection', this.api_root, 'buoys', this.auth, this.label+'/buoys',this.color);
    this.buoycams = new NDBCCollection(this, 'BuoycamCollection', this.api_root, 'buoycam', this.auth, this.label+'/buoycams');
    this.queryParams = null;
    this.dataOnMaps = [];
  }

  /** 
   * toJSON returns an object which:
   *  1) can be passed to JSON.stringify (by definition, see typescript docs)
   *  2) has a property "config" can be passed to this class's constructor
   *  3) has a property "type" that specifies the subtype (i.e. the concrete, 'non-abstract' type) which
   *     allows us to pass the config property to the right constructor
   */
  public toJSON(): {type: string; config: iNDBCSource} {
    let config: iNDBCSource = {...super.toJSON().config,
    };
    return {type: 'NDBCSource', config: config}
  }

  public getMarkerId(feature: {}) {
    return feature.properties.station_id;
  }

  public addSubmap() {
    let ds = this;
    let mapind = this.dataOnMaps.length;

    let buoyLayer = L.geoJSON(undefined, {
      pointToLayer: function(feature, latlng) {
        // add marker to map
        switch (ds.submaps[mapind].activeDataLayer.dataType) {
          case wzDataEnums.IMAGE:
            var marker = ds.imageMarker(mapind, feature, latlng, {
              iconOptions: {
                iconUrl: feature.associatedBuoycam.subimage0.thumbnail_url
              },
            });
            break;
          default:
            var marker = ds.circleMarker(mapind, feature, latlng, {
              color: ds.getColorByValue(mapind, feature.value),
            });
        }

        let markerId = ds.getMarkerId(feature); // The ID we use to keep track of markers on the map

        // add left click
        marker.bindPopup(()=>{
          ds.submaps[mapind].addPopupRecord({'type': 'table', 'feature_id': markerId});
          return ds.buoyMarkerPopupHtml(feature, mapind);
        }).openPopup();
 
        // When adding a point, keep track that we've added it:
        //ds.buoysOnMap[feature.properties.station_id][mapind] = L.stamp(marker);
        ds.dataOnMaps[mapind].buoysOnMap[markerId] = L.stamp(marker);

        return marker;
      }
    });

    let buoycamLayer = L.geoJSON(undefined, {
      pointToLayer: function(feature, latlng) {
        // add marker to map
        switch (ds.submaps[mapind].activeDataLayer.dataType) {
          case wzDataEnums.IMAGE:
            var marker = ds.imageMarker(mapind, feature, latlng, {
              iconOptions: {
                iconUrl: feature.subimage0.thumbnail_url
              },
            });
            break;
          default:
            var marker = ds.circleMarker(mapind, feature, latlng, {
              color: ds.getColorByValue(mapind, feature.value),
            });
        }

        let markerId = ds.getMarkerId(feature); // maps to feature.properties.station_id

        // add left click
        marker.bindPopup(()=>{
          ds.submaps[mapind].addPopupRecord({'type': 'table', 'feature_id': markerId});
          return ds.buoyMarkerPopupHtml(feature, mapind, true);
        }).openPopup();

        // When adding a point, keep track that we've added it:
        ds.dataOnMaps[mapind].buoycamsOnMap[markerId] = L.stamp(marker);

        return marker;
      }
    });


    buoyLayer.addTo(this.submaps[mapind].map);
    buoycamLayer.addTo(this.submaps[mapind].map);

    this.dataOnMaps.push({
      buoyLayer: buoyLayer,
      buoycamLayer: buoycamLayer,
      buoysOnMap: {}, // An array of markerIds (station_ids) for plotted buoys.
      buoycamsOnMap: {}, // An array of markerIds (station_ids) for plotted buoycams that didn't have associated buoys.
    });
  }

  public removeLastSubmap() {
    this.dataOnMaps.pop();
  }

  public async interruptQueries() {
    return Promise.all([
      this.buoys.interruptQueries(),
      this.buoycams.interruptQueries()
    ]).then(()=>{
      return this.resumeQueries();
    });
  }

  public async resumeQueries() {
    let c = Promise.all([
      this.buoys.resumeQueries(),
      this.buoycams.resumeQueries(),
    ]);
    return c; 
  }

  public async activate(queryParams: QueryParams) {
    this.active = true;
    return this.updateData(queryParams);
  }

  public async deactivate(): Promise<any> {
    this.active = false;
    let p = await this.interruptQueries();
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      for (var markerId in this.dataOnMaps[mapind].buoysOnMap) {
        let layerID = this.dataOnMaps[mapind].buoysOnMap[markerId];
        if (layerID) {
          this.submaps[mapind].removeMapLayer(layerID);
        }
      }
      this.dataOnMaps[mapind].buoysOnMap = {};
      for (var markerId in this.dataOnMaps[mapind].buoycamsOnMap) {
        let layerID = this.dataOnMaps[mapind].buoycamsOnMap[markerId];
        if (layerID) {
          this.submaps[mapind].removeMapLayer(layerID);
        }
      }
      this.dataOnMaps[mapind].buoycamsOnMap = {};
    }
    return p;
  }


  public async updateData(newQueryParams: QueryParams): Promise<any> {
    if (!this.active) {
      return Promise.resolve();
    }

    // interrupt prior queries:
    let p = await this.interruptQueries();
    
    this.queryParams = newQueryParams.copy(); // define what the latest query is (because other functions may use it)

    // First, update buoycams collection (but don't plot anything):
    // NOTE: a better way to do this would be to just update the bound popups (using unbindPopup()) when 
    // we "add missing buoy cams" in a few lines down the code:
    let self = this;
    let c = await this.buoycams.updatePageByPageWithAggregation(newQueryParams, (items)=>{
      if (items) {
        for(let i=0; i<items.length; i++) {
          this.buoycams.features[items[i]._id] = items[i].most_recent;
          // STITCH TOGETHER TWO FORMATS:
          //this.buoycams.updateBuoycamForNewServer(items[i]._id);
        }
      }
    });
    this.buoycams.queryParams = newQueryParams.copy();

    // Remove points that fall outside the new time range: 
    if (1) {//(this.queryParams && (newQueryParams.startTime.isAfter(this.queryParams.startTime) || newQueryParams.endTime.isBefore(this.queryParams.endTime))) {
      //console.log('Time range has shrunk; removing excluded markers.');
      for (let mapind=0; mapind<this.submaps.length; mapind++) {
        // buoys:
        for (var markerId in this.dataOnMaps[mapind].buoysOnMap) {
          let layerID = this.dataOnMaps[mapind].buoysOnMap[markerId];
          let mapLayer = this.submaps[mapind].getMapLayer(layerID);
          if (mapLayer) {
            let timeStr = mapLayer.feature.properties.time;
            let markerTime = moment(timeStr);
            if (markerTime.isBefore(newQueryParams.startTime) || markerTime.isAfter(newQueryParams.endTime)) {
              this.submaps[mapind].removeMapLayer(layerID);
              delete this.dataOnMaps[mapind].buoysOnMap[markerId];
            }
          }
        }
        // buoycams:
        for (var markerId in this.dataOnMaps[mapind].buoycamsOnMap) {
          let layerID = this.dataOnMaps[mapind].buoycamsOnMap[markerId];
          let mapLayer = this.submaps[mapind].getMapLayer(layerID);
          if (mapLayer) {
            let timeStr = mapLayer.feature.properties.time;
            let markerTime = moment(timeStr);
            if (markerTime.isBefore(newQueryParams.startTime) || markerTime.isAfter(newQueryParams.endTime)) {
              this.submaps[mapind].removeMapLayer(layerID);
              delete this.dataOnMaps[mapind].buoycamsOnMap[markerId];
            }
          }
        }
      }
    }

    // Update the buoys, adding them to the map:
    let callbackPromises = [];
    c = await this.buoys.updatePageByPageWithAggregation(newQueryParams, (items)=>{
      let r = self.addBuoysToMap(items);
      callbackPromises.push(r);
    });
    this.buoys.queryParams = newQueryParams.copy();

    // Add missing buoy cams:
    c = await this.buoycams.updatePageByPageWithAggregation(newQueryParams, (items)=>{
      let r = this.addBuoycamsToMap(items);
      callbackPromises.push(r);
    });

    // update the query parameters for this source:

    return Promise.all(callbackPromises); 
  }

  public async dataLayerHasChangedOnSubmap(mapind: number) {
    // remove buoys
    for (var markerId in this.dataOnMaps[mapind].buoysOnMap) {
      let layerID = this.dataOnMaps[mapind].buoysOnMap[markerId];
      if(layerID) {
        this.submaps[mapind].removeMapLayer(layerID);
        delete this.dataOnMaps[mapind].buoysOnMap[markerId];
      }
    }

    // remove buoycams
    for (var markerId in this.dataOnMaps[mapind].buoycamsOnMap) {
      let layerID = this.dataOnMaps[mapind].buoycamsOnMap[markerId];
      if(layerID) {
        this.submaps[mapind].removeMapLayer(layerID);
        delete this.dataOnMaps[mapind].buoycamsOnMap[markerId];
      }
    }

    // Add buoys:
    let p = await this.buoys.updatePageByPageWithAggregation(this.queryParams, (items)=>{
      this.addBuoysToMap(items, mapind);
    });
    // Add missing buoy cams:
    p = await this.buoycams.updatePageByPageWithAggregation(this.queryParams, (items)=>{
      this.addBuoycamsToMap(items, mapind);
    });

    return p;
  }

  public async addBuoysToMap(items: any[], submapsToUpdate?: number | number[]) {  
    if (!items || items.length==0) {
      return;
    }

    if (submapsToUpdate==undefined) {
      submapsToUpdate = [];
      let ctr = 0;
      while(ctr<this.submaps.length) {
        submapsToUpdate.push(ctr);
        ctr++;
      }
    } else if (!Array.isArray(submapsToUpdate)) {
      submapsToUpdate = [submapsToUpdate];
    }

    let noOfSubmaps = this.submaps.length;
    let numAdded = new Array(noOfSubmaps).fill(0);

    // Make sure features get added to the right collection:
    let requestedDataType: wzDataEnums[] = [];
    for (let i=0; i<submapsToUpdate.length; i++){
      let mapind = submapsToUpdate[i];
      requestedDataType.push(this.submaps[mapind].activeDataLayer.dataType);
    }

    // from the buoys collection, extract features for the layer
    for (let item of items) {
      let feature = item.most_recent;

      // Make sure feature has coordinates:
      if (feature.geometry === undefined || feature.geometry.coordinates === undefined) {
        continue;
      };

      // Remove some of the database fields from the geoJSON feature:
      for (let iField in GEOJSON_FIELDS_TO_FILTER) delete feature[GEOJSON_FIELDS_TO_FILTER[iField]];

      // Get latest image:
      feature.associatedBuoycam = await this.buoycams.getLocalFeatureWithID(feature.properties.station_id);

      // Get the markerId, which is how we're keeping track of what we put on the map
      let markerId = this.getMarkerId(feature);
      // put on all the maps that we want to update:
      for (let i=0; i<submapsToUpdate.length; i++) {
        let mapind = submapsToUpdate[i];

        // Is this point already on the map?
        let layerID = this.dataOnMaps[mapind].buoysOnMap[markerId];
        if(layerID) {
          //console.log(`Station ${feature.properties.station_id} found as layer ${layerID}`);
          // If they're the same, do nothing:
          let mapLayer = this.submaps[mapind].getMapLayer(layerID);
          let existingMapFeature = mapLayer ? mapLayer.feature : undefined;
          let sameFeature = (existingMapFeature._id === feature._id);
          let sameBuoycam = (existingMapFeature.associatedBuoycam && existingMapFeature.associatedBuoycam._id) == (feature.associatedBuoycam && feature.associatedBuoycam._id);
          if (sameFeature && sameBuoycam) {
            //console.log(`Feature is already on map ${mapind} (layer=${layerID}); ignoring`);
            continue;
          } else {
            //console.log('Removing old feature and adding new.');
            this.submaps[mapind].removeMapLayer(layerID);
            delete this.dataOnMaps[mapind].buoysOnMap[markerId];
          }
        }

        // perturb coordinates so you can actually see the difference between similar points
        feature.geometry.coordinates[0] = feature.geometry.coordinates[0] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        feature.geometry.coordinates[1] = feature.geometry.coordinates[1] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        
        // Get value:
        feature.value = this.getValue(feature,this.submaps[mapind].activeDataLayer);

        // put feature on the map:
        if (feature.value) {
          // put it on the map IF the dataType hasn't changed:
          if (requestedDataType[i] === this.submaps[mapind].activeDataLayer.dataType) {
            this.dataOnMaps[mapind].buoyLayer.addData(feature);
            numAdded[mapind]++;
            //and take it off the map if it was just a buoycam marker:
            layerID = this.dataOnMaps[mapind].buoycamsOnMap[markerId];
            if (layerID) {
              this.submaps[mapind].removeMapLayer(layerID);
              delete this.dataOnMaps[mapind].buoycamsOnMap[markerId];
            }
          } else {
            console.log("MISMATCH!!! Not adding id=" + feature._id + "because map layer changed from " + requestedDataType[i] + " to " + this.submaps[mapind].activeDataLayer.dataType);
          }
        } else {
          // what should we do with NaNs?  Right now we do nothing.
          //console.log('Found a feature with a NaN value:');
          //console.log(feature);
        }

      } // loop over submaps
    } // loop over features
    console.log(`Added ${numAdded} buoys (of ${items.length}) to maps.`);
    // return an empty promise
    return Promise.resolve();
  }

  public async addBuoycamsToMap(items: any[], submapsToUpdate?: number | number[]) {
    //console.log('adding missing buoycams to map.');
    if (!items || items.length==0) {
      return;
    }

    if (submapsToUpdate==undefined) {
      submapsToUpdate = [];
      let ctr = 0;
      while(ctr<this.submaps.length) {
        submapsToUpdate.push(ctr);
        ctr++;
      }
    } else if (!Array.isArray(submapsToUpdate)) {
      submapsToUpdate = [submapsToUpdate];
    }

    let noOfSubmaps = this.submaps.length;
    let numAdded = new Array(noOfSubmaps).fill(0);

    // Make sure features get added to the right collection:
    let requestedDataType: wzDataEnums[] = [];
    for (let i=0; i<submapsToUpdate.length; i++){
      let mapind = submapsToUpdate[i];
      requestedDataType.push(this.submaps[mapind].activeDataLayer.dataType);
    }

    // from the buoys collection, extract features for the layer
    for (let item of items) {
      let feature = item.most_recent;

      // Make sure feature has coordinates:
      if (feature.geometry === undefined || feature.geometry.coordinates === undefined) {
        continue;
      };

      // Remove some of the database fields from the geoJSON feature:
      for (let iField in GEOJSON_FIELDS_TO_FILTER) delete feature[GEOJSON_FIELDS_TO_FILTER[iField]];

      // put on all the maps:
      let markerId = this.getMarkerId(feature);
      for (let i=0; i<submapsToUpdate.length; i++) {
        let mapind = submapsToUpdate[i];
        // Is there already a buoy on the map with this station ID?
        if (this.dataOnMaps[mapind].buoysOnMap[markerId]) {
          continue;
        }

        // Is this buoycam already on the map?
        let layerID = this.dataOnMaps[mapind].buoycamsOnMap[markerId];
        if(layerID) {
          // If they're the same, do nothing:
          let mapLayer = this.submaps[mapind].getMapLayer(layerID);
          let existingMapFeature = mapLayer.feature;
          if(existingMapFeature._id === feature._id ) {
            //console.log(`Feature is already on map ${mapind} (layer=${layerID}); ignoring`);
            continue;
          } else {
            console.log('Removing old feature and adding new.');
            this.submaps[mapind].removeMapLayer(layerID);
            delete this.dataOnMaps[mapind].buoysOnMap[markerId];
          }
        }

        // perturb coordinates so you can actually see the difference between similar points
        feature.geometry.coordinates[0] = feature.geometry.coordinates[0] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        feature.geometry.coordinates[1] = feature.geometry.coordinates[1] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        
        // Get value:
        feature.value = this.getValue(feature,this.submaps[mapind].activeDataLayer);

        // put feature on the map:
        if (!(feature.value===undefined || feature.value===null)) {
          // put it on the map IF the dataType hasn't changed:
          if (requestedDataType[i] === this.submaps[mapind].activeDataLayer.dataType) {
            this.dataOnMaps[mapind].buoycamLayer.addData(feature);
            numAdded[mapind]++;
          } else {
            console.log("MISMATCH!!! Not adding id=" + feature._id + "because map layer changed from " + requestedDataType[i] + " to " + this.submaps[mapind].activeDataLayer.dataType);
          }
        } else {
          // what should we do with NaNs?  Right now we do nothing.
          //console.log('Found a feature with a NaN value:');
          //console.log(feature);
        }

      } // loop over submaps
    } // loop over features
    console.log(`Added ${numAdded} buoycams (of ${items.length}) to maps.`);
  }

  public buoyMarkerPopupHtml(feature: any, mapind: number, isJustBuoycam: boolean=false) {
    let imageHtml, imgsrc, subimgsrc = '';
    let buoycam = undefined;
    if (isJustBuoycam) {
      buoycam = feature;
    } else if (feature.associatedBuoycam) {
      buoycam = feature.associatedBuoycam;
    }

    if (buoycam) {
        // Link to the full composite image:
        //imgsrc = this.api_root + '/files/' + buoycam.image; //+ media.file.file; //buoycam.file.file;
        imgsrc = buoycam.image.url;
        // One subimage:
        //subimgsrc = this.api_root + '/files/' + buoycam.subimages[0].image; //+ media.file.file; //buoycam.file.file;
        subimgsrc = buoycam.subimage0.thumbnail_url;
    }

    let openAnchor = '';
    let closeAnchor = '';
    if (!this.disableBlankTargetLinks) {
      openAnchor = '<a href=' + imgsrc + ' target="_blank">';
      closeAnchor = '</a>';
    }

    let dataTableRowCount = 2;
    let dataTableObj = this.dataTable(feature, mapind, dataTableRowCount);
    let dataTableHtml = dataTableObj.table;
    dataTableRowCount = dataTableObj.lastLineNum;

    if (buoycam) {
      // Link to the full composite image:
      //imgsrc = this.api_root + '/files/' + buoycam.image; //+ media.file.file; //buoycam.file.file;
      imgsrc = buoycam.image.url;
      // One subimage:
      let fileId = buoycam.subimages[0].image;
      //subimgsrc = this.api_root + '/files/' + fileId; //+ media.file.file; //buoycam.file.file;
      subimgsrc = buoycam.subimage0.thumbnail_url;

      // Add cloud cover predictions and weather labels to the table:
      if (SHOW_PREDICTIONS) {
        let ccpred_htmlId = `${fileId}+_ccpred`;
        let weatherLabels_htmlId = `${fileId}+_weatherLabels`;

        dataTableHtml += this.dataHeaderRow(`Predictions`);
        dataTableHtml += this.dataRow(`Cloud Cover`,`Calculating...`,dataTableRowCount % 2 === 1, undefined, ccpred_htmlId); 
        dataTableRowCount++;
        dataTableHtml += this.dataRow(`Weather Labels`,`Calculating...`,dataTableRowCount % 2 === 1, undefined, weatherLabels_htmlId);
        this.buoycams.getccpred(fileId, false).then((pred)=>{
          if (pred.cloud_cover) {
            let div = document.getElementById(ccpred_htmlId);
            if (div) {
              div.innerHTML = sprintf('%.0f%%',pred.cloud_cover);
            } else {
              console.log('Cant find div!');
            }            
          }
          return 1;
        });
        this.buoycams.getweatherLabels(fileId, false).then((labels)=>{
          let goodLabels = '';
          if (labels.weather_labels) {
            let allLabels = '';
            let labelSeparator = '';
            let labelCtr = 0;
            for (let label in labels.weather_labels) {
              let labelStr = sprintf('%s%s (%.0f%%)',labelSeparator,label,100*labels.weather_labels[label])
              if (labelCtr<3 || labels.weather_labels[label]>0.3) {
                goodLabels += labelStr;
              }
              allLabels += labelStr;
              labelCtr++;
              labelSeparator = ', ';
            }
            console.log('Labels:');
            console.log(allLabels);
          } else {
            goodLabels = 'none';
          }
          let div = document.getElementById(weatherLabels_htmlId);
          if (div) {
            div.innerHTML = goodLabels;
          } else {
            console.log('Cant find div!');
          }            
          return 1;
        });
      }
    }

    let html = `<div class='popup'>
      ${this.popupHeaderRow(`NDBC Buoy Data`)}

      ${this.dataRow(`Station ID`, feature.properties.station_id)}
      ${this.dataRow(`Time`, this.formatTimeStringInTZWithTZ(feature.properties.time), true)}
      ${dataTableHtml}

      ${buoycam ? '<br><center>' + 'Image Time: ' + this.formatTimeStringInTZWithTZ(buoycam.properties.time) + '</center>' : ''}
      ${buoycam ? `${this.imageRow(openAnchor + '<img src=' + subimgsrc + '>' + closeAnchor)}` : ''}

      <div id="popup-plot" style="display:none"></div>
      </div>
    `

    return html;
  }

  public logFeature(feature: any) {
    console.log('Buoy feature:');
    console.log(feature);
  }

  /* Implementing abstract function */
  public getDeviceId(feature: any): string {
    return feature.properties.station_id;
  }

  /**
   * For a given feature, get the value that is plotted on the dataLayer.
   * @param  {any}       feature   The buoy record.
   * @param  {DataLayer} dataLayer The dataLayer defining what type of data we're looking for
   * @return {any}       The value.  UNDEFINED if the device is not configured to give data for this data type; NULL if the record for this data type is empty.
   */
  public getValue(feature: any, dataLayer: DataLayer): any {
    let value: any = null; // default, meaning that the device often has this type of data, but it is missing from this sensor record for some reason.
    let units: string = '';

    // For each enum type, map it to a buoy property, and handle any conversions or calibrations (if necessary)
    switch (dataLayer.dataType) {
      case wzDataEnums.AIR_TEMPERATURE: {
        value = feature.properties ? feature.properties.air_tempurature : null; // YES, "tempurature"
        units = 'C';
        break;
      }
      case wzDataEnums.AVERAGE_WAVE_PERIOD: {
        value = feature.properties ? feature.properties.average_wave_period : null; 
        units = 's';
        break;
      }
      case wzDataEnums.DEW_POINT: {
        value = feature.properties ? feature.properties.dew_point_temperature : null; 
        units = 'C';
        break;
      }
      case wzDataEnums.DOMINANT_WAVE_DIRECTION: {
        value = feature.properties ? feature.properties.dominant_wave_direction : null; 
        units = 'heading_degrees';
        break;
      }
      case wzDataEnums.DOMINANT_WAVE_PERIOD: {
        value = feature.properties ? feature.properties.dominant_wave_period : null; 
        units = 's';
        break;
      }
      case wzDataEnums.PEAK_GUST_SPEED: {
        value = feature.properties ? feature.properties.peak_gust_speed : null; 
        units = 'm/s';
        break;
      }
      case wzDataEnums.BAROMETRIC_PRESSURE: {
        if (feature.properties && feature.properties.pressure && feature.properties.air_tempurature) {
          let altitude = 0;
          let temperature = feature.properties.air_tempurature +273.15;
          value = this.getBarometricPressure(feature.properties.pressure, temperature, altitude); 
        } else {
          value = null;
        }
        units = 'mbar';
        break;
      }
      case wzDataEnums.STATION_PRESSURE: {
        value = feature.properties ? feature.properties.pressure : null; 
        units = 'mbar';
        break;
      }
      case wzDataEnums.SEA_SURFACE_TEMPERATURE: {
        value = feature.properties ? feature.properties.sea_surface_temperature : null; 
        units = 'C';
        break;
      }
      case wzDataEnums.WAVE_HEIGHT: {
        value = feature.properties ? feature.properties.wave_height : null; 
        units = 'm';
        break;
      }
      case wzDataEnums.WIND_DIRECTION: {
        value = feature.properties ? feature.properties.wind_direction : null; 
        units = 'heading_degrees';
        break;
      }
      case wzDataEnums.WIND_SPEED: {
        value = feature.properties ? feature.properties.wind_speed : null; 
        units = 'm/s';
        break;
      }
      case wzDataEnums.IMAGE: {
        value = feature.associatedBuoycam || feature.image;
        //value = feature.image; 
        break;
      }
      case wzDataEnums.FULL_REPORT: {
        value = true;
        break;
      }
      default: {
        value = undefined; // means that the device cannot have data for this dataType.
        break;
      }
    }

    //return value;
    return dataLayer.convertValue(value, units);
  }

  public getImageLinks(feature: any) {
    return {url: ''};
  }

  public getAudioLink(feature: any) {
    return '';
  }

  /* Implementing abstract function */
  public exportDataOnMapAsJSON(mapind: number): any {
    return null;
  }

  /* Implementing abstract function */
  public getCustomData(feature:any): Array<iDatum> {
    return [];
  }
  

  /**
   * From a given feature, find that device's histary 
   * @param  {any}   feature [description]
   * @return {any[]}         [description]
   */
  public async getHistory(feature: any, startTime?: moment.Moment, endTime?: moment.Moment, historyFormat?: string) {
    // First, get station ID:
    let stationID = feature.properties.station_id;

    // Next, find all sensor records with these device IDs
    let devQuery = new QueryParams(startTime,endTime,undefined,{'properties.station_id':stationID});
    let features = await this.buoys.getAllRecords(devQuery,undefined,undefined,undefined,false); // Don't interrupt getting the history

    // Generate a nice history object:
    let hist: any;
    if(!features || features.length===0) {
      console.log('No history for this device over this time window.');
      hist = [];
    } else {
      switch (historyFormat) {
        case 'vegaJson':
          hist = [];
          for (let feature of features) {
            let pt = {};
            pt['lineIdx'] = 0; // we can split the data into multiple plotted lines if we wish 
            pt['time'] = feature.properties.time;
            pt['Time'] = this.formatTimeStringInTZWithTZ(feature.properties.time); // Add formatted time string to Vega JSON (instead of writing own tooltip handler)
            for (let dataLayer of this.parent.dataLayers) {
              pt[dataLayer['label']] = this.getValue(feature,dataLayer);
            }
            hist.push(pt);
          }
          break;
        case 'arrays':
          // Now that we have all features, it is time to make it into a history:
          hist = {};
          hist['time'] = [];
          for (let dataLayer of this.parent.dataLayers) {
            hist[dataLayer.label] = [];
          }
          for (let feat of features) {
            hist['time'].push(feat.properties.time);
            for (let dataLayer of this.parent.dataLayers) {
              hist[dataLayer['label']].push(this.getValue(feat,dataLayer));
            }
          }

          // Get rid of fields that have no data:
          for (let p in hist) {
            if(!hist[p].some( (el)=>{return el;})) {
              //console.log(`${p} is empty.`);
              delete hist[p];
            }
          }
          break;
        default:
        case 'raw':
          hist = features;
      }
    }
    return hist;
  }

  public makePermalinkObject(): any {
    let PLObj = {};
    // not doing anything with permalinks right now    
    return PLObj;
  }

  public async interpretPermalinkObject(PLObj: any): Promise<any> {
    // not doing anything with permalinks right now    
    return Promise.resolve(null);
  }

  public async openPopups(mapData: any): Promise<any> {
    let ds = this;
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      if (mapData && mapData.length>mapind && mapData[mapind].popups) {
        for (let idx in mapData[mapind].popups) {
          let popup = mapData[mapind].popups[idx];
          let layerId = this.dataOnMaps[mapind].buoysOnMap[popup.feature_id];
          if (layerId===undefined) {
            layerId = this.dataOnMaps[mapind].buoycamsOnMap[popup.feature_id];
          }
          if (layerId!==undefined) {
            let marker = this.submaps[mapind].getMapLayer(layerId);
            switch (popup.type) {
              case 'history':
                this.showHistory(mapind, ds, marker.feature);
                break;
              default:
              case 'table':
                marker.openPopup();
                break;
            }
          }
        }
      }
    }
    return Promise.resolve();
  }

}
