
L.Control.Dropdown = L.Control.extend({

	options: {
		position: 'topleft',
		items: null,
		initialSelectedItemIndex : 0,
		onChangeCallback: null,
	},

	// update the list of groupings in menu
	updateGroupedItemsInMenu : function() {
		var menu = this._menu;

		// remove all previously added items from menu
		while (menu.firstChild) {
			menu.removeChild(menu.firstChild);
		};
		
		// add a menu item for each grouping
		var optGroup = null;
		//for (let itemIndex in this.options.items) { // for-in returns a string, not a number!
		for (let itemIndex=0; itemIndex < this.options.items.length; itemIndex++) {
			item = this.options.items[itemIndex];

			// If this item is the start of a new grouping, create the grouping label:
			if (optGroup==null || item.categoryName!=optGroup.label)  {
				if (optGroup) menu.appendChild(optGroup);
				optGroup = document.createElement('OPTGROUP');
				optGroup.label = item.categoryName;
				//console.log('created ' + previousGroup.label)
			}
			// Add the item
			var opt = document.createElement('option', 'leaflet-control'  + ' leaflet-bar');
			opt.innerHTML = item.label;
			opt.item = item;
			opt.itemIndex = itemIndex;
			//opt.selected = itemIndex==this.options.initialSelectedGroupIndex;
			opt.selected = (itemIndex===this.options.initialSelectedItemIndex);
			optGroup.appendChild(opt);
		};
		if (optGroup) menu.appendChild(optGroup);
	},

	// get selected menu item
	getSelectedMenuItem: function () {
		// go through menu and find which item has been selected
		var menuItems = this.getMenuItems(this._menu);
		for (var menuindex in menuItems) {
			var menuitem = menuItems[menuindex];
			if (menuitem.selected) return menuitem.item;
		};
	},

	// get selected menu index
	getSelectedMenuIndex: function () {
		// go through menu and find which item has been selected
		var menuItems = this.getMenuItems(this._menu);
		// for (var menuindex in menuItems) {  // for-in returns strings, not numbers!
		for (let menuindex = 0; menuindex<menuItems.length; menuindex++) {
			var menuitem = menuItems[menuindex];
			if (menuitem.selected) return menuindex;
		};
	},

	// get list of selectable menu items from a menu
	getMenuItems: function(menu) {
		var menuItems = [];
		for (var menuindex in menu.children) {
			var menuItem = menu.children[menuindex];
			if (menuItem.tagName==='OPTGROUP') menuItems = menuItems.concat(this.getMenuItems(menuItem));
			else if (menuItem.tagName==='OPTION') menuItems[menuItems.length] = menuItem;
		};
		return menuItems;
	},

	// initialize control when added to map
	onAdd: function (map) {
	
		// create the control. 
		// use native HTML select element, but need special handling to get it to work on 
		// touch-enabled devices

		var className = 'leaflet-control';
		var container = this._container = L.DomUtil.create('div', className);

		//this._map = map;

		// create the control (essentially a drop-down menu)
  		var menu = this._menu = L.DomUtil.create('select', className + '-toggle', container);
        menu.style.fontSize = '16px';
        menu.style.fontWeight = 'bold';
        menu.dropdown = this;
   
		this.updateGroupedItemsInMenu();

		// ***************************************************
		// event handler for when user changes selected layer
		// ***************************************************
        var self = this;
		menu.addEventListener("change", function(evt) {
			let menuItem = self.getSelectedMenuItem();
			let itemIndex = self.getSelectedMenuIndex();
			self.options.onChangeCallback(menuItem,itemIndex);
		});

		// special handling for touch interfaces
		if (L.Browser.touch && !L.Browser.pointer) { // WARNING! IE11 is touch!


			//Makes this work on IE10 Touch devices by stopping it from firing a mouseout event when the touch is released?
			// THIS DOESN'T SEEM TO HELP ON IE10!
			container.setAttribute('aria-haspopup', true);
			menu.setAttribute('aria-haspopup', true);

			// keep click from propagating down to map layer
			L.DomEvent.on(menu, 'click', L.DomEvent.stop);  // this seems safe...
			L.DomEvent.on(menu, 'dblclick', L.DomEvent.stop); // this seems safe...
			//L.DomEvent.on(menu, 'mousedown', L.DomEvent.stop); // NO!!! this will break menu on some android (e.g., Silk)
			//L.DomEvent.disableClickPropagation(container); // NO!!! this will break menu on some android (e.g., Silk)


			// TODO: try "Work around for Firefox android issue" in leaflet-src.js
			L.DomEvent.on(menu, 'click', function() {
				// focus() alone seems to be enough to activate on iPhone (Safari and Chrome)
				// focus() also at least allows subsequent use of a virtual keyboard on IE and Firefox
				menu.focus();  
				// enable opening of dropdown menu on touch devices with a custom event
				// custom event seems to work on Android/Chrome, Kindle/Silk, and Windows/Chrome
				var event = document.createEvent('MouseEvents');
				event.initMouseEvent('mousedown', true, true, window, 0, 
				                                    event.screenX, event.screenY, event.clientX, event.clientY, 
				                                    event.ctrlKey, event.altKey, event.shiftKey, event.metaKey, 
				                                    0, null);
				menu.dispatchEvent(event);				
			});
		}
		else {
			//L.DomEvent.on(menu, 'click', L.DomEvent.stop); 
			//L.DomEvent.on(menu, 'dblclick', L.DomEvent.stop); 
			//L.DomEvent.disableClickPropagation(menu); 
			//L.DomEvent.on(container, 'click', L.DomEvent.stop); 
			//L.DomEvent.on(container, 'dblclick', L.DomEvent.stop); 
			//L.DomEvent.on(container, 'dblclick', L.DomEvent.stopPropagation); 
			//L.DomEvent.on(container, 'click', L.DomEvent.stopPropagation); 
			//L.DomEvent.on(container, 'pointerdown', L.DomEvent.stopPropagation); 
			//L.DomEvent.on(container, 'mousedown', L.DomEvent.stopPropagation); 
			
			L.DomEvent.disableClickPropagation(container); // this fixes issues with scrollbar dragging in dropdown menu for Firefox/IE
			L.DomEvent.disableScrollPropagation(container); // this fixes issues with scrolwheel fall-through in dropdown menu for Firefox/IE
			
		};

		return container;		
	},

});

L.Control.dropdown = function (options) {
	return new L.Control.Dropdown(options);
};
