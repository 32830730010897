/**
 * Collection Class
 */

import moment from 'moment';
import 'moment-timezone';
import L from 'leaflet';
import $ from 'jquery';

import {
  DataSource
} from './data-source';

import {
  QueryParams
} from './query-params';

// GET RID OF THIS IMPORT (EVENTUALLY)
import {
  MAX_FEATURES_PER_LAYER,
  QUERY_PADDING
} from '../webmap-config';

export enum DSMETHOD {
  SUBSET,
  JUST_ONE,
  FIRST,
  LAST,
  ALL
}

/**
 * Collection is a class which manages access to a geojson collection.  It has a local copy of some data,
 * and it also holds information for querying a remote collection.  
 *
 * Collections are "dumb:" they are a source of geojson feature arrays, but they have no idea what to do
 * with these arrays, e.g. they don't know how to add them to a map, or how to integrate them with other
 * collections (DataSource objects are responsible for that higher-level stuff).
 * 
 * A Collection has instance methods for making a query (including follow-up queries).
 * The methods for handling the logic when queries come bakc with more than one 
 * page can be used here, but are probably best replicated at a higher level since the 
 * data source containing this query may want to have control over how to handle
 * queries that return large data sets (i.e. controlling subsampling routines, 
 * plotting each batch of data as they arrive, etc.)
 */

class dummyXHR {
  constructor() {}
  public abort() { console.log('Aborting dummy XHR query.'); }
}

interface iXhrObj {
  active: boolean;
  xhr: any;
}

export class Collection {
  public type: string = 'FeatureCollection';
  public subtype: any;
  public api_root: string; // Need the API root so we can get successive pages in a multi-page query
  public api: string;
  public auth: string = ''; // the authorization string, e.g. 'Token fsasdadfjalb323423k4n23n'
  public label: string;
  public color: string;
  public active: boolean = true; // Is this collection active, i.e. do we plot it and calculate things from it?
  public queryParams: QueryParams | undefined; // The queryParams that were used to get the list of features from the collection
  public requestedQueryParams: QueryParams | undefined; // The queryParams that have been requested.  A request isn't always fulfilled (server issues?) so this may be different from this.queryParams.
  public features: any; // The features in this collection (for a given set of queryParams).  Keys determined by featureIndexingFunction()
  public downsampleMethod: DSMETHOD = DSMETHOD.SUBSET; // what to do when collection is too big?  Default is to take a subset
  public isDownsampled: boolean = false; // Are the data in the collection a subset of the total data for these queryParams?
  public parent: DataSource;
  public sortBy: string | undefined;
  public outstandingInterruptibleXHRs: any[]; // A list of server queries that can be interrupted
  public outstandingInterruptibleFunctions: any[]; // A list of collection-level functions that can be interrupted (usu. functions that involve multiple queries)
  private isInterrupted: boolean;

  /**
   * Collection constructor.
   * @param  {DataSource} parent:   [DataSource which contains this collection.  Currently unused.]
   * @param  {string} coll_type:    [String describing what extended Collection class it is.]
   * @param  {string} api_root:     [The API for this collection, without the ultimate part of the path.]
   * @param  {string} api_point:    [The ultimate part of the api.  e.g. 'buoys']
   * @param  {string} auth:         [The authorization string.  e.g. 'Token asdfjam2323jmas904r934r0a']
   * @param  {string} coll_label:   [A label for the collection]
   * @param  {string} coll_color?:  [A default color for plotting the collection]
   * @param  {string} sortBy?:      [The default query sorting parameter for this collection]
   * @return {[type]}              [description]
   */
  constructor(parent: DataSource, coll_type: string, api_root: string, api_point: string, auth: string, coll_label: string, coll_color?: string, sortBy?: string) {
    this.parent = parent;
    this.subtype = coll_type;
    this.api_root = api_root;
    this.api = api_root + '/' + api_point;
    this.auth = auth;
    this.label = coll_label || this.api;
    this.color = coll_color || '#000000';
    this.features = {};
    this.sortBy = sortBy; 
    this.outstandingInterruptibleXHRs = [];
    this.outstandingInterruptibleFunctions = [];
    this.isInterrupted = false;

    //console.log(`Making collection ${this.label} of type ${this.subtype}`);
  }

  /**
   * Stop all queries that have been registered as interruptible.  Resumes queries once all interruptible promises have been 
   * @return {[Promise]}              All interruptible queries have been interrupted.
   */
  public async interruptQueries() {
    // First, interrupt all existing XHR queries:
    //console.log(`coll.interruptQueries(): Interrupting queries in ${this.subtype}`);
    this.isInterrupted = true;
    for(let i = 0; i<this.outstandingInterruptibleXHRs.length; i++) {
      if (this.outstandingInterruptibleXHRs[i].active) {
        try {
          this.outstandingInterruptibleXHRs[i]['xhr'].abort('aborting xhr...');
          this.outstandingInterruptibleXHRs[i]= {'active': false, 'xhr': new dummyXHR()};
          console.log('coll.interruptQueries(): canceled.');
        }
        catch (error) {
          console.log('coll.interruptQueries(): problem:');
          console.error(error);
        }
      } 
    }

    // Next, wait for all interruptible functions to finish
    let promises = [];
    for (let key in this.outstandingInterruptibleFunctions) {
      if (this.outstandingInterruptibleFunctions.hasOwnProperty(key) && this.outstandingInterruptibleFunctions[key]) {
        promises.push(this.outstandingInterruptibleFunctions[key]);
      }
    }

    //let c = await Promise.all(promises);
    //this.resumeQueries();
    //return c;
    return Promise.all(promises);
  }

  public resumeQueries() {
    //console.log(`coll.resumeQueries(): resuming queries in  (${this.subtype})`);
    this.isInterrupted = false;
    return Promise.resolve();
  }

  /**
   * Records a query as interruptible, in case we need to interrupt all queries
   * @param {any} xhrObj {}
   */
  public registerInterruptibleQuery(xhrObj: iXhrObj) {
    // find where to put it:
    let loc = 0;
    while (loc<this.outstandingInterruptibleXHRs.length) {
      if (!this.outstandingInterruptibleXHRs[loc].active) {
        break;
      }
      loc++;
    }
    // put it there:
    this.outstandingInterruptibleXHRs[loc] = xhrObj;
  }

  /**
   * Wrapper around $.ajax so we can interrupt all outstanding queries, if needed
   * @param {any}          options       The ajax options
   * @param {boolean=true} interruptible Is this specific call interruptible?  Can set to false if we absolutely must have the results of this query.
   */
  public async interruptibleAjax(options: any, interruptible: boolean=true) {
    let interruptedResponse = {'_status': 'INTERRUPTED', '_items': [], '_meta': {'total': 0} };
    if (interruptible && this.isInterrupted) {
      console.log(`Not starting interruptible query, since isInterrupted=true`);
      return interruptedResponse;
    }

    let thisXhr = {'active': true, 'xhr': $.ajax(options)};

    if (interruptible) {
      this.registerInterruptibleQuery(thisXhr);
    }

    let resp = {};
    try {
      resp = await thisXhr.xhr;
    } catch {
      // If the promise is rejected, i.e. if the xhr was aborted, or if there was an ajax error:
      resp = interruptedResponse;
    }
    thisXhr.active = false;
    return resp;
  }

  /**
   * Get a specific record from the collection, based on record id.
   * @param  id           The specific record to request
   * @return {any}        The most recent response from the server
   */
  public async getRecordWithID(id: string, interruptible: boolean=true) {
    var resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api + '/' + id,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    },interruptible);
    return resp;
  }

  /**
   * Query this collection with a given set of QueryParams
   * @param  {QueryParams} queryParams   The parameters that define this query
   * @return {any}              The response from the server.  Could be a geojson object, could be an error.
   */
  public async queryData(queryParams: QueryParams, sortBy?: string, interruptible?: boolean): Promise<any> {
    if (interruptible===undefined || interruptible===null) {interruptible=true;}
    //console.log(`Querying ${this.api} for data...`);
    
    let dataObj = queryParams.asDataObj();
    // Note here we override the "sortBy" stored in the query, but we don't update the query:
    if (sortBy) {
      dataObj['sort'] = sortBy;
    } else if (this.sortBy) {
      dataObj['sort'] = this.sortBy;
    };
    // Make the query:
    let resp = await this.interruptibleAjax({
      type: "GET",
      url: this.api,
      data: dataObj,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return resp;
  }

  /**
   * Get the next page of data in a multi-page query
   * @param  {any} resp   The most recent response from the server, which should include a "_links.next.href" field
   * @return {any}        The next page of data from the server (or, a server error)
   */
  public async getNext(resp: any, interruptible: boolean=true) {
    if(!(resp._links && resp._links.next)) {
      return {'ERROR': 'No next page!'};
    }

    var nextResp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/' + resp._links.next.href,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return nextResp;
  }

  /**
   * Get the previous page of data in a multi-page query
   * @param  {any} resp   The most recent response from the server, which should include a "_links.prev.href" field
   * @return {any}        The previous page of data from the server (or, a server error)
   */
  public async getPrevious(resp: any, interruptible: boolean=true) {
    if(!(resp._links && resp._links.prev)) {
      return {'ERROR': 'No previous page!'};
    }

    var prevResp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/' + resp._links.prev.href,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return prevResp;
  }

  /**
   * Get the last page of data in a multi-page query
   * @param  {any} resp   The most recent response from the server, which should include a "_links.last.href" field
   * @return {any}        The last page of data from the server (or, a server error)
   */
  public async getLast(resp: any, interruptible: boolean=true) {
    if(!(resp._links && resp._links.last)) {
      return {'ERROR': 'No last page!'};
    }

    var lastResp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/' + resp._links.last.href,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return lastResp;
  }

  /**
   * Get the nth-from-last page of data in a multi-page query
   * @param  {any} resp   A response from the server, which should include a "_links.last.href" field
   * @return {any}        The nth-from-last (n=0 is the last; n=1 is the penultimate, etc.) page of data from the server (or, a server error)
   */
  public async getNfromLast(resp: any, N: number, interruptible: boolean=true) {
    if(!(resp._links && resp._links.last)) {
      return {'ERROR': 'No last page!'};
    }
    //console.log(`Getting ${N} from last in ${resp._links.last.href}`):
    let linkstr = resp._links.last.href;
    let pagespec = linkstr.match(/page=[0-9]*/g)[0];
    let pagestr = pagespec.slice(5);
    let newpagestr = (Number(pagestr)-Math.round(N)).toString();
    let newpagespec = pagespec.replace(pagestr,newpagestr);
    let newlinkstr = linkstr.replace(pagespec,newpagespec);
    //console.log(`Link is ${newlinkstr}`);

    var targetResp = await this.interruptibleAjax({
      type: "GET",
      url: this.api_root + '/' + newlinkstr,
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Authorization': this.auth
      }
    }, interruptible);
    return targetResp;
  }

  public async getAllRecords(queryParams: QueryParams, limit?: number, dsMethod?: DSMETHOD, sortBy?: string, interruptible?: boolean) {
    if (interruptible===null || interruptible===undefined) {interruptible=true;}

    //console.log('getAllRecords(): getting records with the following query:');
    //console.log(queryParams);
    var isFinished, isRejected;
    if (interruptible) {
      this.outstandingInterruptibleFunctions['getAllRecords'] = new Promise((resolve, reject)=>{
        isFinished = resolve;
        isRejected = reject;
      });
    }
    
    let resp = await this.queryData(queryParams, sortBy, interruptible);
    dsMethod = dsMethod ? dsMethod : DSMETHOD.SUBSET;
    //console.log(resp);

    if(resp && resp._meta && resp._meta.total==0) {
      /*
      console.log(`No data on server fitting the following query:`);
      console.log(queryParams);
      console.log('...server respose was:');
      console.log(resp);
      */
      if (interruptible) {
        isFinished();
      }
      return null;
    }

    // if there are data, get ready to add them to the local features array 
    let records: any[] = [];

    // stash the data in the collection's feature[] array (a.k.a. the collection):
    if (!(resp._links && resp._links.next)) {
      // if just one page, then add it to records:  
      records = records.concat(resp._items);
    } else { 
      // if multiple pages:
      let numpages = Math.ceil(resp._meta.total/resp._meta.max_results);
      if (limit==undefined || resp._meta.total<limit) {
        records = records.concat(resp._items);
        while (resp._links && resp._links.next) {
          resp = await this.getNext(resp, interruptible);
          records = records.concat(resp._items);
        }
      } else {
        // Figure out how to downsample the data:
        switch(dsMethod) {
          case DSMETHOD.SUBSET: {
            console.log('Taking a subset (first 5 pages).');
            records = records.concat(resp._items);
            let i =0;
            while (resp._links && resp._links.next && i<5) {
              resp = await this.getNext(resp, interruptible);
              records = records.concat(resp._items);
              i++;
            }
            break;
          }
          case DSMETHOD.LAST: {
            console.log('Just showing last pages - NOT IMPLEMENTED');
            break;
          }
          default:
          case DSMETHOD.ALL: {
            console.log('Getting all records');
            records = records.concat(resp._items);
            while (resp._links && resp._links.next) {
              resp = await this.getNext(resp, interruptible);
              records = records.concat(resp._items);
            }
            break;
          }
        }
      }
    }

    if (interruptible) {
      isFinished();      
    }
    return records;
  }

  /**
   * Update a collection.  After each response from the server, add the collection to the map by calling
   * the DataSource method this.addToMap(addToMapArgs).
   * @param {Collection} coll - The collection to be updated.
   * @param {QueryParams} queryParams - The parameters of the query.
   * @param {{}}       addToMapArgs? - When each page is returned, call the method addToMap with these optional arguments
   */
  public async updatePageByPage(queryParams: QueryParams, callback?: Function, args?: {}, interruptible?: boolean): Promise<any> {
    if (interruptible===undefined || interruptible===null) {interruptible=true;}

    //console.log(`Updating collection ${this.label} page by page:`);
    var isFinished, isRejected;
    if (interruptible) {
      this.outstandingInterruptibleFunctions['updatePageByPage'] = new Promise((resolve, reject)=>{
        isFinished = resolve;
        isRejected = reject;
      });      
    }

    let requestedQueryParams = queryParams.copy();

    // Pad the lat/lon range:
    requestedQueryParams.expand(QUERY_PADDING);
 
    // Query the database:
    let resp = await this.queryData(requestedQueryParams, undefined, interruptible);
    // stash the features in the collection's feature{} object, and pass the features to the callback.
    if (!(resp._links && resp._links.next)) {
      // if just one page, then add it to collection:  
      console.log(`${this.label} has just one page (${resp._meta.total} results).  Retrieving all.`);
      this.addItemsToFeatureArray(resp._items);
      this.isDownsampled = false;
      if(callback) {callback(resp._items, args);}
    } else { 
      // if multiple pages:
      let numpages = Math.ceil(resp._meta.total/resp._meta.max_results);
      if (resp._meta.total<MAX_FEATURES_PER_LAYER) {
        console.log(`${this.label} has ${numpages} pages (${resp._meta.total} results).  Retrieving all.`);
        // just push all the features onto the collection
        this.addItemsToFeatureArray(resp._items);
        if(callback) {callback(resp._items, args);}
        while (resp._links && resp._links.next) {
          resp = await this.getNext(resp, interruptible);
          this.addItemsToFeatureArray(resp._items);
          if(callback) {callback(resp._items, args);}
        }
        this.isDownsampled = false;
      } else {
        // Figure out how to downsample the data:
        switch(this.downsampleMethod) {
          case DSMETHOD.SUBSET: {
            console.log(`${this.label} has ${numpages} pages (${resp._meta.total} results).  Taking a subset (first 5 pages).`);
            this.isDownsampled = true;
            this.addItemsToFeatureArray(resp._items);
            if(callback) {callback(resp._items, args);}
            let i=0;
            while (resp._links && resp._links.next && i<5) {
              resp = await this.getNext(resp, interruptible);
              this.addItemsToFeatureArray(resp._items);
              if(callback) {callback(resp._items, args);}
              i++;
            }
            break;
          }
          default: // let 'LAST' be the default.
          case DSMETHOD.LAST: {
            console.log(`${this.label} has ${numpages} pages (${resp._meta.total} results).  Taking a subset: Just showing last 6 pages.`);
            this.isDownsampled = true;
            resp = await this.getNfromLast(resp,5, interruptible);
            this.addItemsToFeatureArray(resp._items);
            if(callback) {callback(resp._items, args);}
            while (resp._links && resp._links.next) {
              resp = await this.getNext(resp, interruptible);
              this.addItemsToFeatureArray(resp._items);
              if(callback) {callback(resp._items, args);}
            }
            break;
          }
          case DSMETHOD.ALL: {
            console.log(`${this.label} has ${numpages} pages (${resp._meta.total} results).  Retrieving all.`);
            this.isDownsampled = false;
            this.addItemsToFeatureArray(resp._items);
            if(callback) {callback(resp._items, args);}
            while (resp._links && resp._links.next) {
              resp = await this.getNext(resp, interruptible);
              this.addItemsToFeatureArray(resp._items);
              if(callback) {callback(resp._items, args);}
            }
            break;
          }
        }
      }
    }

    //If all of that worked, update the queryParams:
    this.queryParams = requestedQueryParams.copy();
    if (interruptible) {
      isFinished();
    }
    return Promise.resolve(null);
  }

  /**
   * Determine if this collection's feature array already contains the data being requested in this query
   * @param  {QueryParams} newQueryParams   Does the collection have all the data already for this query?
   * @return {[type]}                 [description]
   */
  public collectionContains(newQueryParams: QueryParams) {
    let contains = (this.queryParams != null);
    contains = contains && this.queryParams.contains(newQueryParams);
    contains = contains && !this.isDownsampled;
    // if(contains) {console.log(`${this.label}: Already have data in collection; do not need to requery`); }
    return contains;
  }

  /**
   * Adds an array of items to the collection's "feature" array
   * @params {array} items  An array of features
   * @params {Function} indexingFunction: a function that takes an item and returns the desired index.
   * @return {number} The number of items returned to the array
   */
  public addItemsToFeatureArray(items: any): number {
    if (items) {
      if (!Array.isArray(items)) {
        items = [items];
      }
      //console.log(`Adding ${items.length} features to the local collection.`);
      let itemsAdded = 0;
      for (let i=0; i<items.length; i++) {
        let item = items[i];
        let index = this.featureIndexingFunction(item);
        if (index) {
          this.features[index] = item;
          itemsAdded++;
        }
      }
      return itemsAdded;
    } else {
      return 0;
    }
  }

  /**
   * This function determines the index to use when putting items in the Collection.features object 
   * @type {[type]} item: the item that is added to the features object.
   */
  public featureIndexingFunction(feature: any) {
    return feature['_id'];
  }
};


