/**
 * Weather Citizen Data Source
 */

// Import key packages:
import moment from 'moment';
import 'moment-timezone';
import L from 'leaflet';
import * as _ from 'lodash';
import {sprintf} from 'sprintf-js';

// Import the "core" classes:
import {
  QueryParams
} from '../../core/query-params';

import {
  DSMETHOD, Collection
} from '../../core/collection';

import {
  wzDataEnums,
  DataLayer,
  iDatum,
} from '../../core/data-layer';

import { DataSource, iDataSource } from '../../core/data-source';

import {
  Submap,
} from '../../core/submap';

// Import defaults, from the config.  It would be great to get rid of these.
import {
  GEOJSON_FIELDS_TO_FILTER,
  GEOLOCATION_ACCURACY_THRESHOLD,
  GPS_LAT_LON_PERTURB_SCALE,
  MAX_FEATURES_PER_LAYER,
  SHOW_PREDICTIONS,
} from '../../webmap-config';

// import other files associated with this source
import {
   DeviceCollection, SensorCollection, GeomediaCollection
} from './wz-collections';
import { StringColorScale } from '../../core/color-scale';


export interface iWZSource extends iDataSource {
}

export interface iDeviceIdFilter {
  htmlId?: string;
  selections?: {value: string, text: string}[];
  devIds?: string[];
}

export class WZSource extends DataSource {
  private auth: string;
  public devices: DeviceCollection;
  public sensors: SensorCollection;
  public geosensors: SensorCollection;
  public media: GeomediaCollection;
  public geosensorsOnMap: {}; // An array of _ids for plotted sensors.
  public mediaOnMap: {}; // An array of _ids for plotted media.
  public queryParams: QueryParams | null;
  public dataOnMaps: any[];
  private deviceIdFilter: iDeviceIdFilter; // which devices are shown on the map

  constructor(config: iWZSource, auth: string) {
    super(config);
    this.auth = auth;
    this.devices = new DeviceCollection(this, this.api_root, 'device', this.auth, this.label+'/devices');
    this.sensors = new SensorCollection(this, this.api_root, 'sensors', this.auth, this.label+'/sensors',this.color);
    this.geosensors = new SensorCollection(this, this.api_root, 'geosensors', this.auth, this.label+'/geosensors',this.color);
    this.media = new GeomediaCollection(this, this.api_root, 'geomedia', this.auth, this.label+'/geomedia',this.color);
    this.queryParams = null;
    this.dataOnMaps = [];
    this.deviceIdFilter = {htmlId: undefined, selections: undefined, devIds: undefined};
  }

  /** 
   * toJSON returns an object which:
   *  1) can be passed to JSON.stringify (by definition, see typescript docs)
   *  2) has a property "config" can be passed to this class's constructor
   *  3) has a property "type" that specifies the subtype (i.e. the concrete, 'non-abstract' type) which
   *     allows us to pass the config property to the right constructor
   */
  public toJSON(): {type: string; config: iWZSource} {
    let config: iWZSource = {...super.toJSON().config,
    };
    return {type: 'WZSource', config: config};
  }

  public addSubmap() {
    let ds = this;
    let mapind = this.dataOnMaps.length;

    let sensorLayer = L.geoJSON(undefined, {
      pointToLayer: function(feature, latlng) {

        switch (ds.submaps[mapind].activeDataLayer.dataType) {
          case wzDataEnums.IMAGE:
            let media = ds.media.getMediaLocally(feature.properties.image);
            if (media && media.file && media.file.content_type && media.file.file) {
              var marker = ds.imageMarker(mapind, feature, latlng, {
                iconOptions: {
                  iconUrl: media.file.thumbnail_url
                },
              });
            } else {
              var marker = ds.circleMarker(mapind, feature, latlng, {});
            }
            break;
          default:
            var marker = ds.circleMarker(mapind, feature, latlng, {
              color: ds.getColorByValue(mapind, feature.value)
            });
        }
               
        // add left click
        marker.bindPopup(()=>{
          ds.submaps[mapind].addPopupRecord({'type': 'table', 'feature_id': feature._id});
          return ds.markerPopupHtml(feature, mapind);
        }).openPopup();

        // When adding a point, keep track that we've added it:
        ds.dataOnMaps[mapind].geosensorsOnMap[feature._id] = L.stamp(marker);

        return marker;
      }
    });

    sensorLayer.addTo(this.submaps[mapind].map);

    this.dataOnMaps.push({
      sensorLayer: sensorLayer,
      geosensorsOnMap: {}
    });
  }

  public removeLastSubmap() {
    this.dataOnMaps.pop();
  }

  public async interruptQueries() {
    let p = Promise.all([
      this.devices.interruptQueries(),
      this.geosensors.interruptQueries(),
      this.media.interruptQueries()
    ]).then(()=>{
      return this.resumeQueries();
    });
    return p;
  }

  public async resumeQueries() {
    let c = Promise.all([
      this.devices.resumeQueries(),
      this.geosensors.resumeQueries(),
      this.media.resumeQueries(),
    ]);
    return c; 
  }

  public async activate(queryParams: QueryParams) {
    this.active = true;
    return this.updateData(queryParams);
  }

  public async deactivate(): Promise<any> {
    this.active = false;
    let p = await this.interruptQueries();
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      for (var ID in this.dataOnMaps[mapind].geosensorsOnMap) {
        let layerID = this.dataOnMaps[mapind].geosensorsOnMap[ID];
        if (layerID) {
          this.submaps[mapind].removeMapLayer(layerID);
          delete this.dataOnMaps[mapind].geosensorsOnMap[ID]; 
        }
      }
    }
    return p;
  }

  public async updateFilterMenu(queryParams: QueryParams): Promise<any> {
    // Don't do anything if the parent WZ doesn't have a sidebar:
    if (!this.parent.sidebar) {
      return Promise.resolve();
    }

    // get list of all unique device IDs within this time and space range (but exclude other query filters):
    let qp = new QueryParams(queryParams.startTime, queryParams.endTime, queryParams.latLngBounds, undefined, undefined);
    let uniqueList = await this.geosensors.getUniqueDeviceRecords(qp);

    // Convert the device records into a menu list, filtering by uuid:
    let filterOptions: any = {};
    for (let record of uniqueList) {
      if (record.properties) {
        let identifier = record.properties.uuid;
        if (filterOptions.hasOwnProperty(identifier)) {
          filterOptions[identifier].push(record._id);
        } else {
          filterOptions[identifier] = [record._id];
        }
      }
    }

    // Make sure that the current selection is represented:
    if (this.deviceIdFilter && this.deviceIdFilter.selections && this.deviceIdFilter.selections.length>0) {
      for (let sel of this.deviceIdFilter.selections) {
        if (!filterOptions.hasOwnProperty(sel.text) && sel.value) {
          filterOptions[sel.text] = sel.value;
        }
      }
    }

    let filterIds = Object.keys(filterOptions); // A list we're keeping just so we can sort the filterOptions keys later
    filterIds.sort();

    // Find the HTML Form element where we're going to add our filter:
    let userFilterForm = null;
    if (this.deviceIdFilter && this.deviceIdFilter.htmlId) {
      userFilterForm = document.getElementById(this.deviceIdFilter.htmlId);
    }
    if (!userFilterForm) {
      // it the form doesn't already exist, try to add it to the sidebar:
      let userFilter = document.getElementById('userFilter');
      if (userFilter) { // if the 'user' tab of the sidebar exists, create a form group on it
        userFilterForm = document.createElement('form-group');
        userFilterForm.innerHTML = '<h5><b>Filter by WeatherCitizen Device ID</b></h5>';
        let htmlId = 'userFilterWZ';
        while(document.getElementById(htmlId)) {
          htmlId = htmlId.concat('x');
        }
        userFilterForm.id = htmlId;
        this.deviceIdFilter.htmlId = htmlId;
        userFilter.appendChild(userFilterForm);
      } // if the 'user' tab doesn't exist, don't do anything
    }
    if (userFilterForm) {
      let dropdownId = this.deviceIdFilter.htmlId+'dropdown';
      // get rid of the old dropdown:
      if (userFilterForm.childNodes) {
        let ch = userFilterForm.childNodes;
        for (let i=0; i<ch.length; i++) {
          if (ch[i].id === dropdownId) {
            userFilterForm.removeChild(ch[i]);
          }
        }
      }
      // Make the new dropdown:
      let labels = ['All'];
      let vals = [undefined];
      if (filterIds && filterIds.length>0) {
        for (let lbl of filterIds) {
          labels.push(lbl);
          vals.push(filterOptions[lbl]);
        }
      }

      let defaultSelections = [];
      if (this.deviceIdFilter && this.deviceIdFilter.selections && this.deviceIdFilter.selections.length>0) {
        let mp = this.deviceIdFilter.selections.map((sel)=>{return sel.text});
        defaultSelections = Array.from(new Set(mp));
      } else {
        defaultSelections = [undefined];
      }
      let filterDropdown = this.parent.sidebar.dropdown(vals,defaultSelections,labels,dropdownId,(selections)=>{
        console.log(`Selections: ${selections}`);
        this.filterBy(selections);
      },true);
      userFilterForm.appendChild(filterDropdown);
    }
  }

  public async filterBy(selections: any, updateData: boolean=true): Promise<any> {
    // Make a list of all the device IDs that are acceptable.  Note that if the user selected "All", it should override their other choices.
    let devIds = undefined;
    let hasAll = false;
    for (let sel of selections) {
      if (!sel.value) {
        hasAll = true;
      }
    }

    if (!hasAll) {
      devIds = [];
      for (let sel of selections) {
        devIds = devIds.concat(sel.value);
      }
    }

    this.deviceIdFilter.selections = selections;
    this.deviceIdFilter.devIds = devIds;

    if (updateData) {
      return this.updateData(this.queryParams);
    } else {
      return Promise.resolve();
    }
  }

  public featureFitsQuery(feature: any, qp: QueryParams): boolean {
    if (qp.startTime && qp.endTime && feature.properties && feature.properties.time) {
      let featureTime = moment(feature.properties.time);
      if (featureTime.isBefore(qp.startTime) || featureTime.isAfter(qp.endTime) ) {
        return false;
      }
    }
    if (qp.additionalFilters) {
      let good = true;
      for (let key in qp.additionalFilters) {
        if (qp.additionalFilters[key]) {
          let fk = _.get(feature,key);
          if (Array.isArray(qp.additionalFilters[key])) {
            good = good && qp.additionalFilters[key].includes(fk);
          } else {
            good = good && (qp.additionalFilters[key] === fk);
          }
        }
      }
      if (!good) return false;
    }
    return true;
  }

  public featureFitsQueryAndDeviceIdFilter(feature: any, qp: QueryParams): boolean {
    if (qp.startTime && qp.endTime && feature.properties && feature.properties.time) {
      let featureTime = moment(feature.properties.time);
      if (featureTime.isBefore(qp.startTime) || featureTime.isAfter(qp.endTime) ) {
        return false;
      }
    }
    if (qp.additionalFilters) {
      let good = true;
      for (let key in qp.additionalFilters) {
        if (qp.additionalFilters[key]) {
          let fk = _.get(feature,key);
          if (Array.isArray(qp.additionalFilters[key])) {
            good = good && qp.additionalFilters[key].includes(fk);
          } else {
            good = good && (qp.additionalFilters[key] === fk);
          }
        }
      }
      if (!good) return false;
    }

    if (this.deviceIdFilter && this.deviceIdFilter.devIds && this.deviceIdFilter.devIds.length>0) {
      if (this.deviceIdFilter.devIds.indexOf(feature.properties.device)==-1) {
        return false;
      }
    }
    return true;
  }

  public async updateData(newQueryParams: QueryParams): Promise<any> {
    if(!this.active) {
      return Promise.resolve();
    }

    // interrupt previous queries:
    let p = await this.interruptQueries();

    p = await this.updateFilterMenu(newQueryParams);

    this.queryParams = newQueryParams.copy(); // define what the latest query is (because other functions may use it)

    // Remove points that fall outside the new time range, or don't meet the filter: 
    if (1) { //(this.queryParams && (newQueryParams.startTime.isAfter(this.queryParams.startTime) || newQueryParams.endTime.isBefore(this.queryParams.endTime))) {
      //console.log('WZ Time range has shrunk; removing excluded markers.');
      for (let mapind=0; mapind<this.submaps.length; mapind++) {
        for (var ID in this.dataOnMaps[mapind].geosensorsOnMap) {
          let layerID = this.dataOnMaps[mapind].geosensorsOnMap[ID];
          let thisLayer = this.submaps[mapind].getMapLayer(layerID);
          if (thisLayer && thisLayer.feature) {
            let timeStr = thisLayer.feature.properties.time;
            let markerTime = moment(timeStr);
            if (!this.featureFitsQueryAndDeviceIdFilter(thisLayer.feature, newQueryParams)) {
              this.submaps[mapind].removeMapLayer(layerID);
              delete this.dataOnMaps[mapind].geosensorsOnMap[ID];
            }
          }
        }
      }
    }

    let idList = [];
    if (this.deviceIdFilter && this.deviceIdFilter.devIds && this.deviceIdFilter.devIds.length>0) {
      idList = this.deviceIdFilter.devIds;
    } else {
      idList = [undefined];
    }

    // For each valid device Id, update the media in the background:
    let mediaQueryParams = newQueryParams.copy();
    mediaQueryParams.addFilter({'properties.type': 'image'});
    let c = await this.media.updatePageByPage(mediaQueryParams,(items)=>{
      // don't do anything with the items, just add them to the media collection (happens automatically)
    });
    this.media.queryParams = newQueryParams.copy();

    // For each valid device Id, update the geosensors on the map.
    // Don't just try to pack all the device IDs into one query, because errors occur
    // when the query strings are too long
    let callbackPromises = []; // Make an array of Promises that must be resolved for this function to be resolved
    for (let devId of idList) {
      let newQueryParamsWithId = newQueryParams.copy();
      if (devId) {
        newQueryParamsWithId.addFilter({'properties.device': devId});
      }
      // Update the sensors and add them to the map:
      let c = await this.geosensors.updatePageByPage(newQueryParamsWithId,(items)=>{
        let r = this.addSensorsToMap(items);
        callbackPromises.push(r); // Sensors need to be added to the map for this function to be resolved
      });
    }
    this.geosensors.queryParams = newQueryParams.copy();

    return Promise.all(callbackPromises);
  }

  public async dataLayerHasChangedOnSubmap(mapind: number) {
    // when the data layer is changed, replot the sensors    
    for (var ID in this.dataOnMaps[mapind].geosensorsOnMap) {
      this.submaps[mapind].removeMapLayer(this.dataOnMaps[mapind].geosensorsOnMap[ID]);
      delete this.dataOnMaps[mapind].geosensorsOnMap[ID];
    }

    let p = await this.geosensors.updatePageByPage(this.queryParams,(items)=>{
      this.addSensorsToMap(items, mapind);
    });

    return p;
  }

  public async addSensorsToMap(features: any[], submapsToUpdate?: number | number[]): Promise<any> { 
    //console.log(`adding ${features.length} wz sensors to map.`);
    if(!this.active) {
      console.log('Layer is inactive.');
      return Promise.resolve(); // If the user turns off the layer during the querying promise chain
    }

    if(!features || features.length===0) {
      return Promise.resolve();
    }

    if (submapsToUpdate==undefined) {
      submapsToUpdate = [];
      let ctr = 0;
      while(ctr<this.submaps.length) {
        submapsToUpdate.push(ctr);
        ctr++;
      }
    } else if (!Array.isArray(submapsToUpdate)) {
      submapsToUpdate = [submapsToUpdate];
    }

    let noOfSubmaps = this.submaps.length;
    let numAdded = new Array(noOfSubmaps).fill(0);

    // Make sure features get added to the right collection:
    let requestedDataType: wzDataEnums[] = [];
    for (let i=0; i<submapsToUpdate.length; i++){
      let mapind = submapsToUpdate[i];
      requestedDataType.push(this.submaps[mapind].activeDataLayer.dataType);
    }

    // from the sensors collection, extract features for the layer
    for (let feature of features) {
      // extract coordinates of feature
      if (feature.geometry === undefined || feature.geometry.coordinates === undefined) {
        continue;
      };
      // Don't put poorly geolocated data on the map
      if (GEOLOCATION_ACCURACY_THRESHOLD > 0 && feature.properties && feature.properties.loc_accuracy > GEOLOCATION_ACCURACY_THRESHOLD) {
        console.log(`Not adding feature to map due to poor geolocation accuracy (${feature.properties.loc_accuracy} m)`);
        continue;
      }

      const bad_ids = [
        '5b4262e8a7c0d2b074cbe2cf', // improperly rotated photo
        '5d0a5d6a07431a547c426d97', // person in his office
        '5d0a69a0aa2d7090ddb72848', // indoors pic
        '5d0a71462eae288529e1cfd3', // indoors pic
        '5d0a6d7c820ce61aa0a86085', // more plants
        '5d0a5eff8a08c138e0fc8357', // more plants
        '5d0a8b2b1e350a0af3a2dcf0', // more coffee cups
        '5d0a83d01eb41b683e17da30', // bad pic out window
      ];
      if (bad_ids.includes(feature._id)) {
        continue; // Hide bad images.
      }

      //let featureid = feature._id; // use this for now, but then delete from feature
      for (let iField in GEOJSON_FIELDS_TO_FILTER) delete feature[GEOJSON_FIELDS_TO_FILTER[iField]];

      // Get associated device:
      let device = await this.devices.getDevice(feature.properties.device, false); // Make sure we have associated device record
      if (!device) {
        console.log(`Device record ${feature.properties.device} is missing.`);
        console.log('Not adding the following sensor to map, because device record cannot be found:');
        console.log(feature);
        continue; // Can't plot a sensor reading without knowing about the device.
      }

      // put the feature on the map
      for (let i=0; i<submapsToUpdate.length; i++){
        let mapind = submapsToUpdate[i];

        // Is this point already on the map?
        let layerID = this.dataOnMaps[mapind].geosensorsOnMap[feature._id];
        if(layerID) {
          // If they're the same, do nothing:
          continue;
        }

        // perturb coordinates so you can actually see the difference between similar points
        feature.geometry.coordinates[0] = feature.geometry.coordinates[0] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;
        feature.geometry.coordinates[1] = feature.geometry.coordinates[1] + Math.random() * GPS_LAT_LON_PERTURB_SCALE;

        // Get value:
        feature.value = this.getValue(feature,this.submaps[mapind].activeDataLayer);

        // put the feature on the map:
        if (!(feature.value===undefined || feature.value===null)) {
          if (requestedDataType[i] === wzDataEnums.IMAGE) {
            let c = await this.media.getMedia(feature.properties.image, false); // Don't interrupt getting the media; we need it for plotting.
          }
          if (feature.properties.audio) {
            let audio = await this.media.getMedia(feature.properties.audio, false); // Always get the audio
          }
          // put it on the map IF the dataType hasn't changed:
          if (requestedDataType[i] === this.submaps[mapind].activeDataLayer.dataType) {
            this.dataOnMaps[mapind].sensorLayer.addData(feature);
            numAdded[mapind]++;
          } else {
            console.log("MISMATCH!!! Not adding id=" + feature._id + "because map layer changed from " + requestedDataType[i] + " to " + this.submaps[mapind].activeDataLayer.dataType);
          } 
        } else {
          // what should we do with NaNs?  Right now we do nothing.
          //console.log('Found a feature with a NaN value:');
          //console.log(feature);
        }
      } // loop over submaps
    } // loop over features
    console.log(`Added ${numAdded} geosensors (of ${features.length}) to maps.`);
    // return an empty promise
    return Promise.resolve();
  }

  public markerPopupHtml(sensor: any, mapind: number): string {
    let device = this.devices.getDeviceLocally(sensor.properties.device);
    let uuid = device.properties.uuid; //await this.devices.getUUID(sensor.properties.device);
    let audiosrc, audioFile = {src: ''};

    let dataTableRowCount = 2;
    let dataTableObj = this.dataTable(sensor, mapind, dataTableRowCount);
    let dataTableHtml = dataTableObj.table;
    dataTableRowCount = dataTableObj.lastLineNum;

    let openAnchor = '';
    let closeAnchor = '';
    if(sensor.properties.image) {
      let media = this.media.getMediaLocally(sensor.properties.image);
      if (media) {

        if (!this.disableBlankTargetLinks) {
          openAnchor = `<a href="${media.file.url}" target='_blank'>`;
          closeAnchor = '</a>';
        }

        // Add cloud cover predictions and weather labels to the table:
        if (SHOW_PREDICTIONS) {
          let ccpred_htmlId = `${media.file._id}+_ccpred`;
          let weatherLabels_htmlId = `${media.file._id}+_weatherLabels`;

          dataTableHtml += this.dataHeaderRow(`Predictions`);
          dataTableHtml += this.dataRow(`Cloud Cover`,`Calculating...`,dataTableRowCount % 2 === 1, undefined, ccpred_htmlId);
          dataTableRowCount++;
          dataTableHtml += this.dataRow(`Weather Labels`,`Calculating...`,dataTableRowCount % 2 === 1, undefined, weatherLabels_htmlId);
          this.media.getccpred(media.file._id, false).then((pred)=>{
            if (pred.cloud_cover) {
              let div = document.getElementById(ccpred_htmlId);
              if (div) {
                div.innerHTML = sprintf('%.0f%%',pred.cloud_cover);
              } else {
                console.log('Cant find div!');
              }            
            }
            return 1;
          });
          this.media.getweatherLabels(media.file._id, false).then((labels)=>{
            let goodLabels = '';
            if (labels.weather_labels) {
              let allLabels = '';
              let labelSeparator = '';
              let labelCtr = 0;
              for (let label in labels.weather_labels) {
                let labelStr = sprintf('%s%s (%.0f%%)',labelSeparator,label,100*labels.weather_labels[label])
                if (labelCtr<3 || labels.weather_labels[label]>0.3) {
                  goodLabels += labelStr;
                }
                allLabels += labelStr;
                labelCtr++;
                labelSeparator = ', ';
              }
              console.log('All labels:');
              console.log(allLabels);
            } else {
              goodLabels = 'none';
            }
            let div = document.getElementById(weatherLabels_htmlId);
            if (div) {
              div.innerHTML = goodLabels;
            } else {
              console.log('Cant find div!');
            }            
            return 1;
          });
        }
      }
    }

    if(sensor.properties.audio) {
      let mediaAudio = this.media.getMediaLocally(sensor.properties.audio);
      if (mediaAudio) {
        audiosrc = mediaAudio.file.url;
        //audioFile = this.audioFromBase64(mediaAudio.file.content_type,mediaAudio.file.file);
      }
    }

    let html = `<div class='popup'>
      ${this.popupHeaderRow('WeatherCitizen App Data')}

      <!--
      ${this.dataRow(this.submaps[mapind].activeDataLayer.label, this.submaps[mapind].activeDataLayer.prettyValueWithUnits(sensor.value))}

      <hr>
      -->

      ${this.dataRow(`Device ID`, uuid)}
      ${this.dataRow(`Time`, this.formatTimeStringInTZWithTZ(sensor.properties.time), true)}

      ${dataTableHtml}

      ${sensor.properties.image && media && media.file && media.file.thumbnail_url ? `
          ${this.imageRow(`${openAnchor}<img src="${media.file.thumbnail_url}">${closeAnchor}`)}
        ` : '' 
      }

      ${sensor.properties.audio ? `
          <center><audio controls><source src="${audiosrc}"></audio></center>
        ` : '' 
      }

      <div id="popup-plot" style="display:none"></div>
      </div>
    `

    return html;
  }

  public async logFeature(feature: any) {
    console.log('Feature:');
    console.log(feature);
    let dev = await this.devices.getDevice(feature.properties.device);
    if (dev) {
      console.log('Associated device record:');
      console.log(dev);
    } else {
      console.log('Could not find any associated device record among these devices:');
      console.log(this.devices.features);
    }
  }

  /* Implementing abstract function */
  public getDeviceId(feature: any): string {
    let device = this.devices.getDeviceLocally(feature.properties.device);
    let uuid = device.properties.uuid; //await this.devices.getUUID(sensor.properties.device);  
    return uuid;
  }

  /**
   * For a given feature, get the value that is plotted on the dataLayer.
   * @param  {any}       feature   The sensor record.
   * @param  {DataLayer} dataLayer The dataLayer defining what type of data we are looking for
   * @return {any}       The value.  UNDEFINED if the device is not configured to give data for this data type; NULL if the record for this data type is empty.
   */
  public getValue(feature: any, dataLayer: DataLayer): any {
    return this._getValue(feature, dataLayer);
  }

  /* An internal function associated with getValue().  However,
   * by calling this with "overrideWithPropertyStrings=true", one
   * can find out all the properties of the feature that aren't associated
   * with data layers on the map.  See this.getUnusedPropertyStrings.
   */
  private _getValue(feature: any, dataLayer: DataLayer, overrideWithPropertyStrings=false) {
    let value: any = null; // default, meaning that the device often has this type of data, but it is missing from this sensor record for some reason.
    let units: string|undefined = undefined;
    let propertyStrings = [];

    let datum = {
      value: null,
      units: undefined
    };

    let getFromBLE = (propertyName: string): any => {
      if (feature.properties.ble_map && feature.properties.ble_map[propertyName]) {
        let bleDeviceName = feature.properties.ble_map[propertyName][0];
        propertyStrings.push(['properties.ble',bleDeviceName,propertyName].join('.'));
        propertyStrings.push(['properties.ble',bleDeviceName,propertyName+'_units'].join('.'));
        return {
          value: feature.properties.ble[bleDeviceName][propertyName],       
          units: feature.properties.ble[bleDeviceName][propertyName+'_units']
        }
      } else {
        return {
          value: null,
          units: undefined
        };
      }
    }

    // Define the simple mapping from a wzDataEnum to a feature.property:
    let dataLookups = {};
    dataLookups[wzDataEnums.AIR_TEMPERATURE]        =  {property: 'ambient_temperature', units: 'C'};
    dataLookups[wzDataEnums.STATION_PRESSURE]       =  {property: 'pressure', units: 'mbar'};
    dataLookups[wzDataEnums.GEOLOCATION_ALTITUDE]   =  {property: 'altitude', units: 'm'};
    dataLookups[wzDataEnums.GEOLOCATION_ACCURACY]   =  {property: 'loc_accuracy', units: 'm'};
    dataLookups[wzDataEnums.HUMIDITY]               =  {property: 'relative_humidity', units: '%'};
    dataLookups[wzDataEnums.INPUT_CLOUDTYPE]        =  {property: 'input_cloud_type', units: ''};
    dataLookups[wzDataEnums.INPUT_CLOUDHEIGHT]      =  {property: 'input_cloud_height', units: 'm'};
    dataLookups[wzDataEnums.INPUT_VISIBILITY]       =  {property: 'input_visibility', units: 'm'};
    dataLookups[wzDataEnums.INPUT_NOTES]            =  {property: 'input_notes', units: undefined};
    dataLookups[wzDataEnums.WIND_SPEED]             =  {property: 'input_wind_speed', units: 'm/s'};
    dataLookups[wzDataEnums.DEW_POINT]              =  {property: 'dew_point', units: 'C'};
    dataLookups[wzDataEnums.SENSOR_LIGHT]           =  {property: 'light', units: 'lux'};
    dataLookups[wzDataEnums.BATTERY_PERCENT]        =  {property: 'battery_percent', units: '%'};

    // Find the datum:
    if (feature.properties) {
      let simpleSpec = dataLookups[dataLayer.dataType];
      if (simpleSpec) {
        // STEP 1: Check BLE first:
        datum = getFromBLE(simpleSpec.property);
        // STEP 2: Revert to phone data from simple spec:
        if (!datum.value) {
          //console.log('Simple spec!');
          propertyStrings.push(['properties',simpleSpec.property].join('.'));
          datum = {
            value: feature.properties[simpleSpec.property],
            units: simpleSpec.units
          }
        } else {
          //Do we want to tag values as coming from a BLE sensor?
          //console.log('From BLE!');
        }
      } else {
        //console.log('No simple spec');
        // STEP 3: No simple spec defined:
        switch (dataLayer.dataType) {
          case wzDataEnums.BATTERY_TEMPERATURE: {
            if (feature.properties.battery_temperature && feature.properties.battery_temperature!=-1) {
              propertyStrings.push('properties.battery_temperature');
              datum = {
                value: feature.properties.battery_temperature,
                units: 'C'
              }
            } 
            break;
          }
          case wzDataEnums.BAROMETRIC_PRESSURE: {
            if (feature.properties.pressure && feature.properties.ambient_temperature && feature.properties.altitude) {
              let altitude = feature.properties.altitude;
              let temperature = feature.properties.ambient_temperature + 273.15;
              //propertyStrings.push();  // No specific property strings for this.
              datum = {
                value: this.getBarometricPressure(feature.properties.pressure, temperature, altitude),
                units: 'mbar'                
              }
            } else {
              datum = {
                value: null,
                units: 'mbar'                
              }
            }
            break;
          }
          case wzDataEnums.INPUT_PRESENTWEATHER: {
            value = feature.properties.input_present_weather;
            if (value=='ice') {value='freezing rain';}
            propertyStrings.push('properties.input_present_weather');
            datum = {
              value: value,
              units: ''
            }
            break;
          }
          case wzDataEnums.WIND_DIRECTION: {
            let hdgStr = feature.properties.input_wind_direction;
            if (hdgStr) {
              let hdgs = {'N': 0, 'NE': 45, 'E': 90, 'SE': 135, 'S': 180, 'SW': 225, 'W': 270, 'NW': 315, 'stationary': undefined, 'unknown': undefined};
              value = hdgs[hdgStr];
            }
            propertyStrings.push('properties.input_wind_direction');
            datum = {
              value: value,
              units: 'degrees'
            }
            break;
          }
          case wzDataEnums.SENSOR_AZIMUTH: {
            propertyStrings.push('properties.orientation');
            datum = {
              value: feature.properties.orientation ? feature.properties.orientation[0] : null,
              units: 'degrees'
            }
            break;
          }
          case wzDataEnums.SENSOR_PITCH: {
            propertyStrings.push('properties.orientation');
            datum = {
              value: feature.properties.orientation ? feature.properties.orientation[1] : null,
              units: 'degrees'
            }
            break;
          }
          case wzDataEnums.SENSOR_ROLL: {
            propertyStrings.push('properties.orientation');
            datum = {
              value: feature.properties.orientation ? feature.properties.orientation[2] : null,
              units: 'degrees'
            }
            break;
          }
          case wzDataEnums.IMAGE: {
            propertyStrings.push('properties.image');
            datum = {
              value: feature.properties.image,
              units: undefined
            }
            break;
          }
          case wzDataEnums.AUDIO: {
            propertyStrings.push('properties.audio');
            datum = {
              value: feature.properties.audio,
              units: undefined
            }
            break;
          }
          case wzDataEnums.FULL_REPORT: {
            datum = {
              value: true,
              units: undefined
            }
            break;
          }
          case wzDataEnums.OW_TURBIDITY: {
            let val = null;
            if (feature.properties.ble && feature.properties.ble.WaterMonitor) {
              propertyStrings.push('properties.ble.WaterMonitor.last_mean');
              val = feature.properties.ble.WaterMonitor.last_mean;
            }
            datum = {
              value: val,
              units: 'NTU'
            }
            break;
          }
          case wzDataEnums.OW_SALINITY: {
            let val = null;
            let units = null;
            if (feature.properties.ble && feature.properties.ble.WaterMonitor) {
              propertyStrings.push('properties.ble.WaterMonitor.last_mean');
              val = feature.properties.ble.WaterMonitor.last_mean;
              units = 'NTU';
            } else if (feature.properties.input_salinity) {
              propertyStrings.push('properties.input_salinity','properties.input_salinity_units');
              val = feature.properties.input_salinity;
              units = feature.properties.input_salinity_units;
            }
            datum = {
              value: val,
              units: units,
            }
            break;
          }
          default: { 
            datum = {
              value: null, // means that the device cannot have data for this dataType.
              units: undefined
            }
            break;
          }
        } // end switch statement
      }
    } else {
      datum = {
        value: null,
        units: undefined
      }
    }

    if (overrideWithPropertyStrings === true) {
      return propertyStrings;
    } else {
      return dataLayer.convertValue(datum.value, datum.units);
    }
  }

  /**
   * Recursively traverse an object, returning its property keys
   * @param feature The object to be travsersed
   * @returns Array<string> an array of strings with the property keys 
   */
  public getAllPropertyStrings(feature:any): Array<string> {
    let getNestedStrs = (item: any, prefix: string=undefined): Array<string> => {
      if (Array.isArray(item)) {
        return [prefix];
      } else if (typeof item === 'object' && item !== null) { // because typeof null == "object" 
        let strs = [];
        Object.keys(item).forEach((key)=>{
          let newPrefix = prefix ? prefix + '.' + key : key;
          strs = strs.concat(getNestedStrs(item[key],newPrefix));
        });
        return strs;
      } else {
        return [prefix];
      }
    }

    return getNestedStrs(feature);
  }

  public getUnusedPropertyStrings(feature: any, requiredPrefix: string=''): Array<string> {
    let usedPropertyStrings = [];
    this.dataLayers.forEach((dl)=>{
      usedPropertyStrings = usedPropertyStrings.concat(this._getValue(feature, dl, true));
    });
    let unusedPropertyStrings = [];
    this.getAllPropertyStrings(feature).forEach((str)=>{
      if (str.startsWith(requiredPrefix) && !usedPropertyStrings.includes(str)) {
        unusedPropertyStrings.push(str);
      }
    });
    return unusedPropertyStrings;
  }

  public getImageLinks(feature: any) {
    let link = undefined;

    if(feature.properties.image) {
      let media = this.media.getMediaLocally(feature.properties.image);
      if (media && media.file) {
        link = {url: media.file.url, thumbnail_url: media.file.thumbnail_url};
      }
    }
    // Catch when there's no link
    if (link===undefined) {
      link = {url: ''};
    }

    return link;
  }

  public getAudioLink(feature: any) {
    let link = '';

    let mediaAudio = this.media.getMediaLocally(feature.properties.audio);
    if (mediaAudio) {
      link = mediaAudio.file.url;
    }

    return link;
  }

  /* Implementing abstract function */
  public exportDataOnMapAsJSON(mapind: number): any {
    let data = {};
    for (let featid in this.dataOnMaps[mapind].geosensorsOnMap) {
      let layerid = this.dataOnMaps[mapind].geosensorsOnMap[featid];
      let feature = this.submaps[mapind].getMapLayer(layerid).feature;
      // Make sure point is currently visible within the map window
      if (
        this.queryParams.latLngBounds && feature.geometry && feature.geometry.coordinates &&
        this.queryParams.latLngBounds.contains(L.latLng(feature.geometry.coordinates[1],feature.geometry.coordinates[0]))
       ) {
        data[feature._id] = this.dataTableAsJSON(feature);
      }
    }
    return data;
  }

  /* Implementing abstract function */
  public getCustomData(feature:any): Array<iDatum> {
    const prefix = 'properties.input_';
    let psArr = this.getUnusedPropertyStrings(feature,prefix);
    let dataArr = [];
    psArr.forEach((pstr)=>{
      let label = pstr.slice(prefix.length);
      let value = _.get(feature,pstr);
      if (!(value===undefined || value===null)) {
        let d: iDatum = {
          label: label,
          value: value,
        }
        dataArr.push(d);  
      }
    });

    return dataArr;
  }

  /**
   * From a given feature, find that device's histary 
   * @param  {any}   feature [description]
   * @return {any[]}         [description]
   */
  public async getHistory(feature: any, startTime?: moment.Moment, endTime?: moment.Moment, historyFormat?: string) {
    // First, get UUID:
    let uuid = await this.devices.getUUID(feature.properties.device, false); // Don't interrupt this
    // Next, get all device records from this UUID:
    let devIDs = await this.getDeviceIDsFromUUID(uuid,undefined,undefined,false); // Don't interrupt this

    // Next, find all sensor records with these device IDs
    let senfeatures = [];
    let geofeatures = [];
    for(let devID of devIDs) {
      let devQuery = new QueryParams(startTime,endTime,undefined,{'properties.device':devID});
      let morefeatures = await this.geosensors.getAllRecords(devQuery,undefined,undefined,undefined,false); // don't interrupt getHistory
      if (morefeatures) {
        geofeatures = geofeatures.concat(morefeatures);
      }
      morefeatures = await this.sensors.getAllRecords(devQuery,undefined,undefined,undefined,false); // don't interrupt getHistory
      if (morefeatures) {
        senfeatures = senfeatures.concat(morefeatures);
      }
    }

    // Generate a nice history object:
    let hist: any;
    if((!geofeatures || geofeatures.length===0) && (!senfeatures || senfeatures.length===0)) {
      console.log('No history for this device over this time window.');
      hist = [];
    } else {
      switch (historyFormat) {
        case 'vegaJson':
          hist = [];
          for (let feat of geofeatures) {
            let pt = {};
            pt['lineIdx'] = 0;
            pt['time'] = this.formatTimeStringInTZWithoutTZ(feat.properties.time); // vega uses this for the x axis
            pt['Time'] = this.formatTimeStringInTZWithTZ(feat.properties.time); // Vega uses this for the tooltip (explicitly showing timezone)
            for (let dataLayer of this.parent.dataLayers) {
              pt[dataLayer['label']] = this.getValue(feat,dataLayer);
            }
            hist.push(pt);
          }
          for (let feat of senfeatures) {
            let pt = {};
            pt['lineIdx'] = 1;
            pt['time'] = this.formatTimeStringInTZWithoutTZ(feat.properties.time); // vega uses this for the x axis
            pt['Time'] = this.formatTimeStringInTZWithTZ(feat.properties.time); // Vega uses this for the tooltip (explicitly showing timezone)
            for (let dataLayer of this.parent.dataLayers) {
              pt[dataLayer['label']] = this.getValue(feat,dataLayer);
            }
            hist.push(pt);
          }
          break;
        case 'arrays': 
          hist = {};
          // Now that we have all geofeatures, it is time to make it into a history:
          hist['time'] = [];
          for (let dataLayer of this.parent.dataLayers) {
            hist[dataLayer.label] = [];
          }
          for (let feat of geofeatures) {
            hist['time'].push(feat.properties.time);
            for (let dataLayer of this.parent.dataLayers) {
              hist[dataLayer['label']].push(this.getValue(feat,dataLayer));
            }
          }
          for (let feat of senfeatures) {
            hist['time'].push(feat.properties.time);
            for (let dataLayer of this.parent.dataLayers) {
              hist[dataLayer['label']].push(this.getValue(feat,dataLayer));
            }
          }

          // Get rid of fields that have no data:
          for (let p in hist) {
            if(!hist[p].some( (el)=>{return el;})) {
              //console.log(`${p} is empty.`);
              delete hist[p];
            }
          }
          break;
        default:
        case 'raw':
          hist = geofeatures;
          hist = hist.concat(senfeatures);
          break;
      }
    }
    return hist;
  }

  private async getDeviceIDsFromUUID(uuid: string, startTime?: moment.Moment, endTime?: moment.Moment, interruptible?: boolean): Promise<string[]> {
    let devIds: string[] = [];

    if (interruptible===undefined || interruptible===null) {interruptible=true;}

    let UUIDparams = new QueryParams(startTime,endTime,undefined,{'properties.uuid': uuid});
    let goodDeviceArray = await this.devices.getAllRecords(UUIDparams,undefined,undefined,undefined,interruptible);
    if(goodDeviceArray) {
      if (goodDeviceArray.length===0) {
        console.log(`ERROR: no devices with UUID ${uuid} not found on server!  length==0`);
      } else {
        for (let dev of goodDeviceArray) {
          devIds.push(dev._id);
        }
      }
    } else {
        console.log(`ERROR: no devices with UUID ${uuid} not found on server! result is null.`);
    }
    return devIds;
  }

  public makePermalinkObject(): any {
    let PLObj: any = {};

    // Filter data?
    if (this.deviceIdFilter && this.deviceIdFilter.selections && this.deviceIdFilter.selections.length>0) {
      let mp = this.deviceIdFilter.selections.map((sel)=>{return sel.text});
      PLObj['id'] = Array.from(new Set(mp));
    }

    return PLObj;
  }

  public async interpretPermalinkObject(PLObj: any): Promise<any> {
    if (PLObj && Array.isArray(PLObj)) {
      let dsobj = PLObj[0]; // The WZ source should always be source zero.  If we want to get fancier with multiple wz sources, then we can add those bells and whistles when the time comes.
      if (dsobj && dsobj.id) {
        let selections = [];
        for (let sel of dsobj.id) {
          let devIds = [undefined];
          if (sel!='All') {
            devIds = await this.getDeviceIDsFromUUID(sel,undefined,undefined,false); // Don't interrupt this
          }
          selections.push({text: sel, value: devIds});
        }
        return this.filterBy(selections, false);
      }
    }
    return Promise.resolve(null);
  }

  public async openPopups(mapData: any): Promise<any> {
    let ds = this;
    for (let mapind=0; mapind<this.submaps.length; mapind++) {
      if (mapData && mapData.length>mapind && mapData[mapind].popups) {
        for (let idx in mapData[mapind].popups) {
          let popup = mapData[mapind].popups[idx];
          let layerId = this.dataOnMaps[mapind].geosensorsOnMap[popup.feature_id];
          if (layerId!==undefined) {
            let marker = this.submaps[mapind].getMapLayer(layerId);
            switch (popup.type) {
              case 'history':
                this.showHistory(mapind, ds, marker.feature);
                break;
              default:
              case 'table':
                marker.openPopup();
                break;
            }
          }
        }
      }
    }
    return Promise.resolve();
  }
}
