/**
 * Data Source 
 */

import moment from 'moment';
import 'moment-timezone';
import L from 'leaflet';
import * as vega from 'vega';
import 'vega-lite';
import vegaEmbed from 'vega-embed';
import JSONFormatter from 'json-formatter-js';

import {
  Webmap
} from '../webmap'

import {
  QueryParams
} from './query-params'

import {
  DSMETHOD, Collection
} from './collection'

import {
  MAX_FEATURES_PER_LAYER,
  QUERY_PADDING,
} from '../webmap-config'

import {
  wzDataEnums,
  DataLayer,
  iDatum,
} from './data-layer';

import {
  Submap,
} from './submap';

export interface iDataSource {
  api_root: string;
  label: string;
  color?: string;
  displayByDefault?: boolean;
  disableBlankTargetLinks?: boolean; // Prevent the source from generating links with target="_blank" ?
}

export abstract class DataSource {
  public api_root: string;
  public label: string;
  public color: string;
  public displayByDefault: boolean;
  public disableBlankTargetLinks: boolean;
  public active: boolean; // Is this collection active, i.e. do we plot it and calculate things from it?
  public parent: Webmap | undefined;
  public layerGroup: L.LayerGroup;
  public dataLayers: DataLayer[] = []; // Points to the parent Webmap's dataLayers object.
  public submaps: Submap[] = []; // Points to the parent Webmap's dataLayers object.
  public timezone: string; // Points to the parent Webmap's timezone property

  constructor(config: iDataSource) {
    this.api_root = config.api_root;
    this.label = config.label;
    this.color = config.color ? config.color : '#3EF009';
    //this.parent = parent; should eliminate references to parent?
    if (config.displayByDefault===undefined) {
      this.displayByDefault = true;
    } else {
      this.displayByDefault = config.displayByDefault;
    }
    this.disableBlankTargetLinks = !!config.disableBlankTargetLinks;
    this.active = this.displayByDefault;
  }

  /** 
   * toJSON returns an object which:
   *  1) can be passed to JSON.stringify (by definition, see typescript docs)
   *  2) has a property "config" can be passed to this class's constructor
   *  3) has a property "type" that specifies the subtype, in this case an empty string signifying that it is abstract and can't be instantiated
   */
  public toJSON(): {type: string; config: iDataSource} {
    let cfg: iDataSource = {
      api_root: this.api_root,
      label: this.label,
      color: this.color,
      displayByDefault: this.active,
    }
    return {type: '', config: cfg};
  }

  public abstract async activate(queryParams: QueryParams): Promise<any>;

  public abstract async deactivate(): Promise<any>;

  /**
   * Get the items that appear in the context menu (right-click menu)
   * @param {DataSource}    ds      The DataSource (so it doesn't get lost in the callback)
   * @param {{}}          feature [description]
   */
  public contextMenuArray(mapind: number, ds: DataSource, feature: {}): any[] {
    let arr = [
      //{text: 'Log Feature REMOVE', callback: ()=>{this.logFeature(feature);}},
      {text: 'Plot History', iconCls: 'glyphicon glyphicon-stats',  callback: ()=>{this.showHistory(mapind,this,feature)}},
      {text: 'View Raw Data', iconCls: 'glyphicon glyphicon-list-alt', callback: ()=>{this.showRawData(mapind,this,feature);}},
      {text: 'Export to Excel', iconCls: 'glyphicon glyphicon-save-file', callback: ()=>{this.parent.submaps[mapind].exportFileFromRecords([this.dataTableAsJSON(feature)], 'record')}},
      {text: 'Export History', iconCls: 'glyphicon glyphicon-save-file',  callback: ()=>{this.exportHistory(mapind,this,feature)}},
    ];
    return arr;
  }

  public async exportHistory(mapind: number, ds: DataSource, feature: {}) {
    let time1 = ds.parent.queryParams.startTime;
    let time2 = ds.parent.queryParams.endTime;

    let h = await ds.getHistory(feature,time1,time2,'raw');
    let anchor = document.createElement('a');
    let url = "data:," + JSON.stringify(h);
    anchor.href = url;
    anchor.download = 'data.json';
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(function() {
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    },0);
  }

  /*
   * Some data sources may want to index features by something other than the feature._id.
   * For example, for a stationary point it may be more interesting to look at a station id
   * instead of the feature id 
   */
  public getMarkerId(feature: {}) {
    return feature._id;
  }

  /**
   * Display a history for this device (by UUID, station name, or whatever the "permanent" identifier is.)
   * @param {number}    mapind      Which map is the marker on?
   * @param {DataSource}    ds      [description]
   * @param {{}}          feature [description]
   * @param {moment.Moment} time1   [description]
   * @param {moment.Moment} time2   [description]
   */
  public async showHistory(mapind: number, ds: DataSource, feature: {}) {
    let time1 = ds.parent.queryParams.startTime;
    let time2 = ds.parent.queryParams.endTime;

    let h = await ds.getHistory(feature,time1,time2,'vegaJson');
    console.log(h);

    let thisMap = ds.submaps[mapind].map;
    let mapContainer = thisMap.getContainer();

    if (h.length===0) {
      console.log('No history!');
      alert('No history for given time window.');
      return;
    }

    if (time1==undefined) {
      time1 = moment(h[0].time);
    }

    let yfield = ds.submaps[mapind].activeDataLayer.label;
    let yrange = [ds.submaps[mapind].activeDataLayer.colorScale.min, ds.submaps[mapind].activeDataLayer.colorScale.max];
    let xlabel: string = "Time ("+this.prettyTZ(time1)+")";

    let myVlSpec = {
      "$schema": "https://vega.github.io/schema/vega-lite/v2.json",
      "data": {"name": "myData"},
      "layer": [
        {
          "selection": {
            "grid": {"type": "interval","bind": "scales"}
          },
          "mark": {"type": "line", "color": "blue", "point": {"color": "blue"}, "strokeCap": "square"},
          "transform": [{"filter": {"field": "lineIdx", "equal": 0}}],
          "encoding": {
            "x": {"field": "time", "type": "temporal", "axis": {"title": xlabel}},
            "y": {"field": yfield, "type": "quantitative", "scale": {"domain": yrange}},
            "color": {"value":"blue"},
            "tooltip": [
              {"field": "Time", "type": "nominal", "format": "%s"},         
              {"field": yfield, "type": "quantitative"},
            ]
          }
        },
        {
          "mark": {"type": "circle", "filled": false, "color": "red"},
          "transform": [{"filter": {"field": "lineIdx", "equal": 1}}],
          "encoding": {
            "x": {"field": "time", "type": "temporal", "axis": {"title": xlabel}},
            "y": {"field": yfield, "type": "quantitative", "scale": {"domain": yrange}},
            "color": {"value":"red"},
            "tooltip": [
              {"field": "time", "type": "temporal"},         
              {"field": yfield, "type": "quantitative"},
            ]
          }
        },
      ]
    };

    let options = {
      "actions": {"export": true, "source": true, "compiled": false, "editor": true},
      "defaultStyle": true, // cause the actions to appear as a triple-dot menu in the upper-right as opposed to text links at the bottom.
      "padding": {'left': 5, 'right': 5, 'top': 5, 'bottom': 25}, // The bottom padding gives space for the x-axis title
    };

    // plot in the data bubble?
    if (0) {
      let d = document.getElementById('popup-plot');

      myVlSpec.width = 200;

      vegaEmbed(d, myVlSpec, options).then((res)=>res.view.insert("myData",h).run());
      d.style.display = 'block';
    } else {
      // Plot in its own popup:

      // close any open popups on this map
      thisMap.closePopup();

      // Embed the CSV data in the link itself for fast/easy downloading without calling back to the server.
      let chartOptions = {
        "width": Math.min(mapContainer.offsetWidth-300, 700),
        "height": Math.min(mapContainer.offsetHeight-100, 700),
      };

      let divIdPopup = mapContainer.id + "_plotPopup";
      let divIdPlot = mapContainer.id + "_timeseries";

      let html = (
          '<div id="'+divIdPopup+'" style="height:'+chartOptions.height+'px;width:'+chartOptions.width+'px"><div id="'+divIdPlot+'"></div></div>');
      
      //let poploc = thisMap.getCenter();
      let poploc = L.latLng(feature.geometry.coordinates[1],feature.geometry.coordinates[0]);
      
      let popup = L.popup({
        maxWidth:chartOptions.width,
        maxHeight:chartOptions.height
      }).setContent(html); 

      //marker.bindPopup(popop).openPopup();
      popup.setLatLng(poploc).openOn(thisMap);

      myVlSpec.width = chartOptions.width-100;
      myVlSpec.height = chartOptions.height-100;

      let d = document.getElementById(divIdPlot);
      let res = await vegaEmbed(d, myVlSpec, options);
      res.view.insert("myData",h).run();

      // Add the varying time options
      let pudiv = document.getElementById(divIdPopup);

      let footopts = document.createElement('div');
      footopts.className = 'col-sm-3 form-group';
      //footopts.setAttribute('aria-haspopup', true); // DOESN'T HELP!
      let selid = 'selectTimeRange';
      let sel = document.createElement('select');
      sel.className = 'form-control';
      sel.id = selid;
      let labels = ['custom', '1 day', '1 week', '1 month', '6 months', 'all'];
      for (let i=0; i<labels.length; i++) {
        let label = labels[i];
        let opt = document.createElement('option');
        opt.innerHTML = label;
        opt.value = label;
        sel.appendChild(opt);
      } 
      sel.selectedIndex=0;       
      sel.addEventListener("change", (evt) => {
        let label = labels[sel.selectedIndex];
        let t1, t2: moment.Moment | null;
        switch (label) {
          case 'custom': {
            t1 = moment(time1);
            t2 = moment(time2);
            break;
          }
          case '1 day': {
            t1 = moment(time2).subtract(1,'day');
            t2 = moment(time2);
            break;
          }
          case '1 week': {
            t1 = moment(time2).subtract(1,'week');
            t2 = moment(time2);
            break;
          }
          case '1 month': {
            t1 = moment(time2).subtract(1,'month');
            t2 = moment(time2);
            break;
          }
          case '6 months': {
            t1 = moment(time2).subtract(6,'months');
            t2 = moment(time2);
            break;
          }
          case 'all': {
            t1 = null;
            t2 = null;
            break;
          }
        }
        ds.updatePlot(ds,feature,res,t1,t2);
      });
      
      //Makes this work on IE10 Touch devices by stopping it from firing a mouseout event when the touch is released
      //sidebar.div.setAttribute('aria-haspopup', true); // NO! this makes sidebar disappear when try to interact with it!
      footopts.appendChild(sel);

      pudiv.appendChild(footopts);      
    }

    // record that we've added a popup:
    ds.submaps[mapind].addPopupRecord({'type': 'history', 'feature_id': ds.getMarkerId(feature)});
  }

  public async showRawData(mapind: number, ds: DataSource, feature: {}) {
    let thisMap = ds.submaps[mapind].map;
    thisMap.closePopup();

    let mapContainer = thisMap.getContainer();

    let popupOptions = {
      "width": Math.min(mapContainer.offsetWidth-300, 500),
      "height": Math.min(mapContainer.offsetHeight-300, 500),
    };

    let divIdPopup = mapContainer.id + "_dataPopup";
    let divIdJSON = mapContainer.id + "_JSON";

    let html = (
        '<div id="'+divIdPopup+'" style="height:'+popupOptions.height+'px;width:'+popupOptions.width+'px;overflow-y:scroll;overflow-x:scroll"><div id="'+divIdJSON+'">Raw Data Object:</div></div>');

    //let poploc = thisMap.getCenter();
    let poploc = L.latLng(feature.geometry.coordinates[1],feature.geometry.coordinates[0]);
    
    let popup = L.popup({
      maxWidth:popupOptions.width,
      maxHeight:popupOptions.height
    }).setContent(html); 

    popup.setLatLng(poploc).openOn(thisMap);

    let divPopup = document.getElementById(divIdPopup);
    let formatter = new JSONFormatter(feature,2,{});
    divPopup.appendChild(formatter.render());
  }

  public async updatePlot(ds: DataSource, feature: {}, res: {}, time1: moment.Moment, time2: moment.Moment) {
    console.log('getting history...');
    let h2 = await ds.getHistory(feature,time1,time2,'vegaJson');
    console.log('updating plot.');
    //let changeSet = vega.changeset().remove(function (t) { return true;}).insert("myData",h2);
    //res.view.change("myData",changeSet).run();
    //res.view.remove('myData',function(){return true;}).insert("myData",h2).run();
    res.view.remove('myData',function(){return true;}).run();
    res.view.insert("myData",h2).run();
  }

  public getColorByValue(mapind: number, value: any) {
    return this.submaps[mapind].activeDataLayer.colorScale.getColorByValue(value);
  }

  public abstract addSubmap();

  public abstract removeLastSubmap();

  public abstract async updateData(queryParams: QueryParams): Promise<any>;

  public abstract async dataLayerHasChangedOnSubmap(idx: number): Promise<null>;

  public abstract async logFeature(feature: {});

  /**
   * Return a string representing the unique device that created/recorded the feature
   * @param feature The feature
   * @return {string} The ID (e.g. device id, station id, mmsi)
   */
  public abstract getDeviceId(feature: any): string;

  public abstract getValue(feature: any, dataLayer: DataLayer): any;

  /**
   * Return a dict of strings with url(s) of the feature.
   * The object's keys are as listed:
   *   'url': string
   *   'thumbnail_url': (optional) string for link to smaller image
   * @param feature The feature
   * @return {Object} An dict of key:value url strings
   */
  public abstract getImageLinks(feature: any): any;

  public abstract getAudioLink(feature: any): string;

  /**
   * Export all the data shown on the map, in JSON format.
   * @param {number}    mapind  Which map layer is being called
   * @return {Object}   An object whose keys are the unique record id and whose properties
   * are an array of iDatum objects 
   */
  public abstract exportDataOnMapAsJSON(mapind: number): any;

  /**
   * Return additional data from a feature that isn't assoicated with a map DataLayer
   * @param {Object}    feature The feature to get data from
   * @return {Array<iDatum>}   An array with the additional data from the feature 
   */
  public abstract getCustomData(feature:any): Array<iDatum>;

  public abstract async getHistory(feature: any, startTime?: moment.Moment, endTime?: moment.Moment, historyFormat?: string);

  public abstract makePermalinkObject(): any;

  public abstract async interpretPermalinkObject(obj: any): Promise<any>;

  public abstract async openPopups(obj: any): Promise<any>;

  // Interrupts the collection's queries.  Useful when the user toggles options or moves around the map rapidly 
  //public abstract interrupt();

  public removePointsOutsideTimeRange(layerName: string, queryParams: QueryParams) {
    console.log(`Removing points from layer with label ${layerName}...`);
    this.layerGroup.eachLayer(function(collectionLayerGroup) {
      if (collectionLayerGroup.options.type === layerName) {
        collectionLayerGroup.eachLayer(function(featureLayer) {
            let featureTime = moment(featureLayer.feature.properties.time);
            if(featureTime.isBefore(queryParams.startTime) || featureTime.isAfter(queryParams.endTime)) {
              featureLayer.remove();
            }
        });
      };
    });
  }

  /**
   * [getBarometricPressure Convert station pressure, temperature, and altitude to barometric pressure]
   * @param  {[number]} stationPressure_Pa:   [Measured station pressure, in any absolute units since the correction is just a ratio]
   * @param  {[number]} temperature_K:        [Measured air temperature, in Kelvin]
   * @param  {[number]} altitude_m:           [Station altitude, in meters]
   * @return {[number]}                       [Barometric pressure, smae units as the input station pressure]
   */
  public getBarometricPressure(stationPressure: number, temperature_K: number, altitude_m: number) {
    let R = 8.3144598;  // universal gas constant, (J/mol/K)
    let g = 9.807       // acceleration due to gravity, (m/s^2)
    let Mair = 28.97    // molecular weight of air, (g/mol)

    let Rair = R/(Mair*1.0e-3)   // specific gas constant for air, (J/kg/K)
    let H = Rair*temperature_K/g // m, scale height (height adjusted for temperature)
    let P_sl = stationPressure * Math.exp(altitude_m/H) // Pressure at sea level, (Pa)
        
    return P_sl  // pressure at sea level, (Pa ~ or whatever units were input)
  }

  /**
   * Convert a binary image string into an image.
   * @params {string} contentType  The type of content, e.g. 'image/jpeg'
   * @params {string} content  A string representing the binary image
   */
  public imageFromBase64(contentType: string, content: string): HTMLImageElement {
    let image = new Image();
    image.src = 'data:'+contentType+';base64,'+content;
    return image;
  }

  /**
   * Convert a binary audio string into an audio file.
   * @params {string} contentType  The type of content, e.g. 'audio/mp4'
   * @params {string} content  A string representing the binary audio
   */
  public audioFromBase64(contentType: string, content: string): HTMLAudioElement {
    let audio = new Audio();
    audio.src = 'data:'+contentType+';base64,'+content;
    return audio;
  }

  public circleMarker(mapind: number, feature: {}, latlng: L.LatLng, argOptions: {}) {
    // https://leafletjs.com/reference-1.3.4.html#circlemarker
    let options = {
      contextmenu: true,
      contextmenuWidth: 200,
      contextmenuItems: this.contextMenuArray(mapind,this,feature),
      contextmenuInheritItems: false,
      color: "#FFFFFF",
      fillOpacity: .9,
      weight: 0,
      radius: 6
    };
    Object.assign(options,argOptions);
    return L.circleMarker(latlng, options);
  }

  public imageMarker(mapind: number, feature: {}, latlng: L.LatLng, argOptions: {}) {
    // https://leafletjs.com/reference-1.3.4.html#marker
    let options = {
      contextmenu: true,
      contextmenuWidth: 200,
      contextmenuItems: this.contextMenuArray(mapind,this,feature),
      contextmenuInheritItems: false,
      iconOptions:  {
        iconSize: [50, 50] ,       
      }
    };
    let iconOptions = Object.assign({},options.iconOptions,argOptions.iconOptions);
    let icon = L.icon(iconOptions);
    Object.assign(options,argOptions);
    options.icon = icon;
    delete options.iconOptions;
    return L.marker(latlng, options);
  }

  public dataRow(label:string, value:string, odd?: boolean, highlighted?: boolean, value_htmlId?: string): string {
    let dataRowType = highlighted ? 'trHighlighted' : (odd ? 'trOdd' : 'trEven');
    let valDivExtras = '';
    if (value_htmlId) {
      valDivExtras = `id=${value_htmlId}`;
    }

    return `<div class="row ${dataRowType}">
    <div class="col-xs-6 data-label">${label}</div>
    <div class="col-xs-6" ${valDivExtras}>${value}</div>
    </div>`
  }

  public popupHeaderRow(label:string): string {
    return `<div class="row popup-header-row">
    <div class="col-xs-12 popup-header-label">${label}</div>
    </div>`
  }

  public dataHeaderRow(label:string, value_htmlId?: string): string {
    return `<div class="row data-header-row">
    <div class="col-xs-12 data-header-label">${label}</div>
    </div>`
  }

  public dataTable(feature: any, mapind: number, priorLines: number=0): {table: string, lastLineNum: number} {
    let tableHtml = '';
    let lineCtr = priorLines;
    this.dataLayers.forEach((dl, i) => {
      //if (i==this.submaps[mapind].activeDataLayerIndex) return; // this fails with a triple equals === ???? why??
      if (dl.dataType === wzDataEnums.FULL_REPORT) return;
      if (dl.dataType === wzDataEnums.IMAGE) return; // hide the image line
      if (dl.dataType === wzDataEnums.AUDIO) return;
      let lineVal = this.getValue(feature, dl);
      if (!(lineVal===undefined || lineVal===null)) { // don't just check if(lineVal) - this must be true when lineVal is 0
        tableHtml += this.dataRow(dl.label, dl.prettyValueWithUnits(lineVal), lineCtr%2==1, i==this.submaps[mapind].activeDataLayerIndex);
        lineCtr++;
      }
    });
    this.getCustomData(feature).forEach((datum, i) => {
      if (!(datum.value===undefined) || (datum.value===null)) {
        tableHtml += this.dataRow(datum.label, datum.value, lineCtr%2==1);
        lineCtr++;
      }
    })

    return {table: tableHtml, lastLineNum: lineCtr};
  }

  public dataTableAsJSON(feature): Array<iDatum> {
    let table = []
    table.push({
      'label': 'Data Source',
      'value': this.label,
      'units': ''
    });
    table.push({
      'label': 'Record ID',
      'value': this.getMarkerId(feature),
      'units': ''
    });
    table.push({
      'label': 'time',
      'value': this.formatTimeStringInTZWithTZ(feature.properties.time,true),
      'units': ''
    });
    table.push({
      'label': 'Device ID',
      'value': this.getDeviceId(feature),
      'units': ''
    });
    table.push({
      'label': 'Longitude',
      'value': feature.geometry.coordinates[0],
      'units': ''
    });
    table.push({
      'label': 'Latitude',
      'value': feature.geometry.coordinates[1],
      'units': ''
    });
    this.dataLayers.forEach((dl) => {
      if (dl.dataType === wzDataEnums.FULL_REPORT) return;
      if (dl.dataType === wzDataEnums.IMAGE) return; // we'll get this separately
      if (dl.dataType === wzDataEnums.AUDIO) return; // we'll get this separately
      table.push({
        'label': dl.label,
        'value': this.getValue(feature, dl),
        'units': dl.prettyUnits
      });
    });
    // Add custom data from a feature:
    table = table.concat(this.getCustomData(feature));
    table.push({
      'label': 'Image',
      'value': this.getImageLinks(feature),
      'units': ''
    })
    table.push({
      'label': 'Audio',
      'value': this.getAudioLink(feature),
      'units': ''
    })
    return table;
  }

  public imageRow(html:string): string {
    return `<div class="row image-row">
      <div class="col-xs-12">${html}</div>
    </div>`
  }

  public formatTimeStringInTZWithoutTZ(time1: string) {
    return moment(time1).tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss');
  }

  public formatTimeStringInTZWithTZ(time1: string, descendingOrder: boolean = false) {
    let formatStr = 'MM/DD/YYYY, HH:mm:ss z';
    if (descendingOrder) {
      formatStr = 'YYYY-MM-DD, HH:mm:ss z';
    }
    return moment(time1).tz(this.timezone).format(formatStr);
  }

  /**
   * Get the time zone
   * @param {string} time1 Need to know a date/time to get timezone (to figure out if it is standard time or daylight savings time)
   */
  public prettyTZ(time1: moment.Moment): string {
    return moment(time1).tz(this.timezone).format('z');
  }
}
