import L from 'leaflet';
import moment from 'moment';
import 'moment-timezone';

/**
 * QueryParams is a class dealing with collection queries.  Each instance of 
 * queryParams represents a set of contraints (e.g. geographic bounds or a time window)
 * that are used to query a collection.  
 */
export class QueryParams {
	public startTime: moment.Moment | undefined; // UTC
	public endTime: moment.Moment | undefined; // UTC
	public latLngBounds: L.LatLngBounds | undefined; // Geographic region (optional)
	public additionalFilters: {} | undefined; // these should be called "filters".  Filters are things in there "where:" block of the query
	public additionalParams: {} | undefined; // these should be called "parameters".  Parameters are additional things tacked onto a query (after a &)

	constructor(startTime?: moment.Moment, endTime?: moment.Moment, latLngBounds?: L.LatLngBounds, additionalFilters?: {}, additionalParams?: {}) {
		this.startTime = startTime;
		this.endTime = endTime;
		this.latLngBounds = latLngBounds;
		this.additionalFilters = additionalFilters;
		this.additionalParams = additionalParams;
	}

	public toJSON(): {startTime: string; endTime: string; latLngBounds: string} {
		let cfg = {
			startTime: this.startTime.utc().format(),
			endTime: this.endTime.utc().format(),
			latLngBounds: '[' + this.latLngBounds.toBBoxString() + ']'
		}
		return cfg;
	}

	/**
	 * copy(): return a new QueryParams object which is a copy of a QueryParams object.
	 * @return {QueryParams} A new copy of this.
	 */
	public copy() {
		let p2 = new QueryParams(this.startTime, this.endTime, this.latLngBounds, this.additionalFilters, this.additionalParams);
		return p2;
	}

	// Adds a filter
	public addFilter(filter: {}) {
		for (let key in filter) {
			if (filter.hasOwnProperty(key)) {
				if ( (filter[key]===undefined || filter[key]===null) && this.additionalFilters && this.additionalFilters.hasOwnProperty(key)) {
					delete this.additionalFilters[key];
				} else {
					if (!this.additionalFilters) {
						this.additionalFilters = {};
					}
					this.additionalFilters[key] = filter[key];
				}
			}
		}
	}

	/**
	 * contains(): Checks whether a second query is a subset of this.
	 * @param  {QueryParams} subset:   We want to know if this QueryParams object is contained within this query
	 * @return {boolean}         True if subset is entirely within (is a subset) of this.
	 */
	public contains(subset: QueryParams) {
		let contains = true;
		// First, check latLngBounds:
		if (this.latLngBounds) {
			if (subset.latLngBounds) {
				contains = this.latLngBounds.contains(subset.latLngBounds);
			} else {
				contains = false;
			}
		}

		// next, check time:
		if (this.startTime) {
			if (subset.startTime) {
				contains = contains && !subset.startTime.isBefore(this.startTime);
			} else {
				contains = false;
			}
		}
		if (this.endTime) {
			if (subset.endTime) {
				contains = contains && !subset.endTime.isAfter(this.endTime);
			} else {
				contains = false;
			}
		}

		// next, check any additional parameters (don't have any yet, but can add them):
		if (this.additionalFilters) {
			contains = contains && (this.additionalFilters===subset.additionalFilters);
		}
		
		return contains;
	}

	/**
	 * asFilterObj(): return the startTime, endTime, lat/long, and additional filters as a object with stringified values
	 * @return {[type]} [description]
	 */
	public asFilterObj() {
		let filterObj = {};
		
		// Add the time query:
		if(this.startTime || this.endTime) {
			filterObj['properties.time'] = {};
		}
		if(this.startTime) {
			filterObj['properties.time']['$gt'] = this.startTime.utc().format('YYYY-MM-DDTHH:mm:ss.S') + "Z";
		}
		if(this.endTime) {
			filterObj['properties.time']['$lte'] = this.endTime.utc().format('YYYY-MM-DDTHH:mm:ss.S') + "Z";
		}
		// Add the geometry query:
		if (this.latLngBounds) {
	        let coordArray = [[this.latLngBounds.getWest(),this.latLngBounds.getSouth()],[this.latLngBounds.getEast(),this.latLngBounds.getNorth()]];
			filterObj['geometry'] = {
				"$geoWithin":{
					"$box": coordArray
				}
            };
		}

		// Add additional filters:
		if (this.additionalFilters) {
			for (let p in this.additionalFilters) { // don't assign null fields
				if (this.additionalFilters[p]) {
					filterObj[p] = this.additionalFilters[p];
				}
			}
		}

		return filterObj;
	}

	/**
	 * asDataObj(): return this as an object formatted for use as the "data object" in an ajax query (keys and stringified values)
	 * @return {[type]} [description]
	 */
	public asDataObj() {

		let dataObj = {};

		dataObj['where'] = JSON.stringify(this.asFilterObj());

		// Add additional params:
		if (this.additionalParams) {
			for (let p in this.additionalParams) {
				if (this.additionalParams[p]) {
					dataObj[p] = JSON.stringify(this.additionalParams[p]);
				}
			}
		}

		// Return object
      	return dataObj;
	}

	/**
	 * Reads a URL string (from a permalink) and sets queryParams startTime, endTime, and latLngBounds
	 */
	fromV1URLString(queryParamURLString: string | null): QueryParams {
		let QP = JSON.parse(queryParamURLString);
		this.startTime = (QP && QP.startTime) ? moment(QP.startTime) : undefined;
		this.endTime = (QP && QP.endTime) ? moment(QP.endTime) : undefined;
		let A = (QP && QP.latLngBounds) ? JSON.parse(QP.latLngBounds) : undefined;
		this.latLngBounds = A ? L.latLngBounds([[A[1],A[0]],[A[3],A[2]]]) : undefined;

		return this;
	}

	public getBoundingBox() {
		if (this.latLngBounds) {
			return [[this.latLngBounds.getSouth(), this.latLngBounds.getWest()],[this.latLngBounds.getNorth(), this.latLngBounds.getEast()]];
		} else {
			return undefined;
		}
	}

	/**
	 * Expands the lat/long boundaries by a "padding" ratio on each side
	 * @param  {number} padding: The fraction to expand the width and height on each side/end
	 * @return {[type]}           [description]
	 */
	public expand(padding: number) {
		if (this.latLngBounds) {
			this.latLngBounds = this.latLngBounds.pad(padding);
		}
	}
}
