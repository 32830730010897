import moment from 'moment';
import 'moment-timezone';

import { BoundingBox, LabeledDuration } from './webmap';

import {
	wzDataEnums,
} from './core/data-layer';

// Data Sources:
import { iWZSource } from './sources/weathercitizen/wz';
import { iNDBCSource } from './sources/ndbc/ndbc';
import { iAISSource } from './sources/ais/ais';

import {
	ColorScale,
	NullColorScale,
	StringColorScale,
} from './core/color-scale';

/* Basic webmap settings: */
export const DEFAULT_API_URL = 'https://api.weathercitizen.org';
export const INFO_PANEL_HTML = 'WeatherCitizen Map.<br>Copyright <a href="http://www.creare.com" target="blank">Creare</a> 2021.';
const MAPBOX_TOKEN = 'pk.eyJ1Ijoid2VhdGhlcmNpdGl6ZW4iLCJhIjoiY2swNnYyNnIzM3NiZjNicDBuaHJ6dHN1MyJ9.h3unRJ6OGnqogqe3mu-uuA'; // token "WeatherCitizen public"

/* Configure API settings: */
export const SHOW_PREDICTIONS = true;

// Configure map layers:
/* static tiles: */
export const STATIC_STREETS_URL = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token='+MAPBOX_TOKEN;
export const STATIC_SATELLITE_URL = 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token='+MAPBOX_TOKEN;
export const STATIC_SATELLITE_STREETS_URL = 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token='+MAPBOX_TOKEN;
export const STATIC_OUTDOORS_URL = 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token='+MAPBOX_TOKEN;
/* raster tiles: */
//export const AERIAL_VIEW_URL = 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token='+MAPBOX_TOKEN;
//export const TERRAIN_VIEW_URL = 'https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token='+MAPBOX_TOKEN;
//export const STREETS_AND_BORDERS_URL = 'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.png?access_token='+MAPBOX_TOKEN;

export const MBOX_LINK = '<a href="http://mapbox.com/">Mapbox </a>';
export const MAPBOX_ATTRIB = '&copy; ' + MBOX_LINK + ' Contributors';
export const GPS_LAT_LON_PERTURB_SCALE = 0.0002;
export const GEOLOCATION_ACCURACY_THRESHOLD = 500; // Accuracy needed for feature to be placed on map. Set to 0 for all to be shown regardless of accuracy
export const GEOJSON_FIELDS_TO_FILTER = ['_etag', '_links', '_created', '_updated'];

export const DEBOUNCE_TIME = 500; // How long, in ms, to wait before reacting to a movement of the map
export const QUERY_PADDING = 0.2; // How much we expand the map's lat/lon when we query for data. 0.1 means expand the bounding box by 10% in each direction (width becomes 120%, height becomes 120%).

const SHOW_RADAR_BY_DEFAULT = true;

export enum BASELAYERS {
	STATIC_STREETS,
	STATIC_SATELLITE,
	STATIC_SATELLITE_STREETS,
	STATIC_OUTDOORS
};

export const DEFAULT_BASELAYER: BASELAYERS = BASELAYERS.STATIC_STREETS;
export const MAX_FEATURES_PER_LAYER = 1000;

// Parameters for controlling the time:
export const TIME_SHIFT_INTERVALS: LabeledDuration[] = [
	{label: '1 Hour', value: moment.duration(1,'hour')},
	{label: '6 Hours', value: moment.duration(6,'hour')},
	{label: '1 Day', value: moment.duration(1,'day')},
	{label: '1 Week', value: moment.duration(1,'week')}
];

export const TIME_WINDOWS: LabeledDuration[] = [
	{label: '1 Hour', value: moment.duration(1,'hour')},
	{label: '6 Hours', value: moment.duration(6,'hour')},
	{label: '1 Day', value: moment.duration(1,'day')},
	{label: '1 Week', value: moment.duration(1,'week')},
	{label: '1 Month', value: moment.duration(1,'month')},
	{label: '6 Months', value: moment.duration(6,'month')},
	{label: 'Custom', value: undefined},
];


// Configure multiple maps:
export const NUM_MAPS_MAX = 4;
export const MASTER_MAP = 0; // The map that holds all the controllers that apply to all the maps
export const SIDEBAR_ON_LEFT: boolean = false; // which side of the maps is the sidebar on?

// Set the default DataLayers: 
const DEFAULT_BEARING_RANGE: [number, number] = [0, 360];
const DEFAULT_BEARING_DIVS = 9;
const OW_DEFAULT_CMAP_RANGE: [number, number] = [0, 5];
const OW_DEFAULT_LEGEND_DIVS = 6;

/*
export const activeDataLayers: DataLayer[] = [
	new DataLayer(wzDataEnums.OW_PH,"pH","OpenWater", 'pH', new ColorScale('', [0., 10.], 6)),
	new DataLayer(wzDataEnums.OW_CDOM,"CDOM","OpenWater", '', new ColorScale('QSDE', [0, 12], 7)),
	new DataLayer(wzDataEnums.OW_DEPTH,"Depth","OpenWater", 'm', new ColorScale('m', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_PRECIP,"Precipitation","OpenWater", '', new ColorScale('', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_NITRATE,"Nitrate","OpenWater", '', new ColorScale('uM', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_PAR0_NEG,"PAR(0-)","OpenWater", '', new ColorScale('uM/m^2/sec', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_SALINITY,"Salinity","OpenWater", '', new ColorScale('PSU', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_TURBIDITY,"Turbidity","OpenWater", '', new ColorScale('NTU', [0., 20.], 6)),
	new DataLayer(wzDataEnums.OW_PAR0_PLUS,"PAR(0+)","OpenWater", '', new ColorScale('uM/m^2/sec', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_CHLOROPHYLL,"Chlorophyll","OpenWater", '', new ColorScale('ug/L', [0., 10.], 6)),
	new DataLayer(wzDataEnums.OW_TEMPERATURE,"Temperature","OpenWater", 'C', new ColorScale('C', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_DISSOLVED_O2,"Dissolved Oxygen","OpenWater", '', new ColorScale('ml/l', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_TIDAL_HEIGHT,"Tidal Height","OpenWater", '', new ColorScale('', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_TRANSMISSION,"Transmission","OpenWater", '', new ColorScale('m^-1', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_CONDUCTIVITY,"Conductivity","OpenWater", '', new ColorScale('S/m', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_CURRENT_SPEED,"Current Speed","OpenWater", '', new ColorScale('cm/s', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_O2_SATURATION,"Oxygen Saturation","OpenWater", '', new ColorScale('ml/l', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_CURRENT_DIRECTION,"Current Direction","OpenWater", 'deg', new ColorScale('deg', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
	new DataLayer(wzDataEnums.OW_O2_PERCENT_SATURATION,"Oxygen % Saturation","OpenWater", '%', new ColorScale('%', OW_DEFAULT_CMAP_RANGE, OW_DEFAULT_LEGEND_DIVS)),
]
*/

/*
 * HERE WE ASSEMBLE THE DEFAULT CONFIG, PIECE BY PIECE:
 */

/* 
 * MAKE THE DEFAULT QUERY PARAMS:
 */
const default_start_time = moment().utc().subtract(1, 'day').format();
const default_end_time = moment().utc().format();
// a few options for the default view area:
const conus: BoundingBox = [[30, -140],[45, -65]];
const eastern_seaboard: BoundingBox = [[25, -85],[45, -70]];
const new_england: BoundingBox = [[41, -73.6],[49, -63]];
const upper_valley: BoundingBox = [[43.64, -72.28],[43.76, -72.11]];
const nantucket_sound: BoundingBox = [[41.15, -70.65],[41.65, -69.85]];
const nantucket: BoundingBox = [[41.28, -70.11],[41.34, -70.01]];
const nancam: BoundingBox = [[41.447, -70.350],[41.570, -70.166]];

/* 
 * BUILD THE DATA SOURCES:
 */
const wzConfig: iWZSource = {
	api_root: DEFAULT_API_URL, 
	label: 'WeatherCitizen', 
	color: '#F03709',
};
const noaaConfig: iNDBCSource = {
	api_root: DEFAULT_API_URL, 
	label: 'NOAA Buoys',
	color: '#0972F0'
}
const aisConfig: iAISSource = {
	api_root: DEFAULT_API_URL,
	label: 'AIS',
	color: '#FFFFFF'
}

// the result:
const DEFAULT_SOURCES = [
	{type: 'WZSource', config: wzConfig},
	{type: 'NDBCSource', config:  noaaConfig},
	{type: 'AISSource', config:  aisConfig},
];

/* 
 * BUILD THE DATA LAYERS:
 */
const DEFAULT_DATA_LAYERS = [
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.STATION_PRESSURE,
		label: 'Station Pressure',
		categoryName: 'Air Data',
		units: 'mbar',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'P (<units>)', range: [980., 1050.], nDivs: 8}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.BAROMETRIC_PRESSURE,
		label: 'Barometric Pressure',
		categoryName: 'Air Data',
		units: 'mbar',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'P (<units>)', range: [980., 1050.], nDivs: 8}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.AIR_TEMPERATURE,
		label: 'Air Temperature',
		categoryName: 'Air Data',
		units: 'C',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'T (<units>)', range: [-10., 30.], nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.HUMIDITY,
		label: 'Relative Humidity',
		categoryName: 'Air Data',
		units: '%',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'RH (<units>)', range: [0, 100], nDivs: 6}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.DEW_POINT,
		label: 'Dew Point',
		categoryName: 'Air Data',
		units: 'C',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'Dew Point (<units>)', range: [-10., 30.], nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.WIND_SPEED,
		label: 'Wind Speed',
		categoryName: 'Air Data',
		units: 'm/s',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 30.], nDivs: 7}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.WIND_DIRECTION,
		label: 'Wind Direction',
		categoryName: 'Air Data',
		units: 'degrees',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: DEFAULT_BEARING_RANGE, nDivs: DEFAULT_BEARING_DIVS}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SEA_SURFACE_TEMPERATURE,
		label: 'Sea Surface Temperature',
		categoryName: 'Water Data',
		units: 'C',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'T (<units>)', range: [-10., 30.], nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.AVERAGE_WAVE_PERIOD,
		label: 'Average Wave Period',
		categoryName: 'Water Data',
		units: 's',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 10.], nDivs: 6}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.WAVE_HEIGHT,
		label: 'Wave Height',
		categoryName: 'Water Data',
		units: 'm',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 10.], nDivs: 6}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.OW_TURBIDITY,
		label: 'Turbidity',
		categoryName: 'Water Data',
		units: 'NTU',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 20.], nDivs: 6}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.OW_SALINITY,
		label: 'Salinity',
		categoryName: 'Water Data',
		units: 'g/kg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 40.], nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.IMAGE,
		label: 'Images',
		categoryName: 'Observations'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.AUDIO,
		label: 'Audio',
		categoryName: 'Observations'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.INPUT_PRESENTWEATHER,
		label: 'Present Weather',
		categoryName: 'Observations',
		units: '', 
		colorScale: {
			type: 'StringColorScale', 
			config: {legendTitle: 'weather', strArray: ['clear', 'cloudy', 'fog', 'drizzle', 'rain', 'thunderstorm', 'ice', 'snow', 'blizzard']}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.INPUT_VISIBILITY,
		label: 'Visibility',
		categoryName: 'Observations',
		units: 'km',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 100.], nDivs: 8}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.INPUT_CLOUDTYPE,
		label: 'Cloud Type',
		categoryName: 'Observations',
		units: '', 
		colorScale: {
			type: 'StringColorScale', 
			config: {legendTitle: 'cloud type', strArray: ['cirrus', 'cirrocumulus', 'cirrostratus', 'altocumulus', 'altostratus', 'nimbostratus', 'stratocumulus', 'stratus', 'cumulus', 'cumulonimbus']}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.INPUT_CLOUDHEIGHT,
		label: 'Cloud Height',
		categoryName: 'Observations',
		units: 'm',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 70000], nDivs: 8}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.INPUT_NOTES,
		label: 'Notes',
		categoryName: 'Observations'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_ORIENTATION,
		label: 'Orientation',
		categoryName: 'Other Sensor Data',
		units: 'deg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: DEFAULT_BEARING_RANGE, nDivs: DEFAULT_BEARING_DIVS}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_AZIMUTH,
		label: 'Azimuth',
		categoryName: 'Other Sensor Data',
		units: 'deg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 360.],nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_PITCH,
		label: 'Pitch',
		categoryName: 'Other Sensor Data',
		units: 'deg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [-180, 180.],nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_ROLL,
		label: 'Roll',
		categoryName: 'Other Sensor Data',
		units: 'deg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [-90., 90.],nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_ACCELEROMETER,
		label: 'Accelerometer',
		categoryName: 'Other Sensor Data',
		units: 'm/s^2'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_GRAVITY,
		label: 'Gravity',
		categoryName: 'Other Sensor Data',
		units: 'm/s^2'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_LINEAR_ACCELERATION,
		label: 'Linear Acceleration',
		categoryName: 'Other Sensor Data',
		units: 'm/s^2'
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_MAGNETIC_FIELD,
		label: 'Magnetic Field',
		categoryName: 'Other Sensor Data',
		units: ''
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.SENSOR_LIGHT,
		label: 'Illuminance', 
		categoryName: 'Other Sensor Data',
		units: 'lux',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'Illuminance (<units>)', range: [0., 10000.,], nDivs: 9}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.BATTERY_TEMPERATURE,
		label: 'Battery Temperature',
		categoryName: 'Other Sensor Data',
		units: 'C',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'T (<units>)', range: [-10., 40.], nDivs: 11}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.BATTERY_PERCENT,
		label: 'Battery Percent',
		categoryName: 'Other Sensor Data',
		units: '%',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: 'Battery (<units>)', range: [0., 100.], nDivs: 11}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.GEOLOCATION_BEARING,
		label: 'Bearing',
		categoryName: 'Geolocation',
		units: 'deg',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: DEFAULT_BEARING_RANGE, nDivs: DEFAULT_BEARING_DIVS}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.GEOLOCATION_ALTITUDE,
		label: 'Altitude',
		categoryName: 'Geolocation',
		units: 'm',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0, 1000], nDivs: 6}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.GEOLOCATION_SPEED,
		label: 'Speed',
		categoryName: 'Geolocation',
		units: 'm/s',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0, 30], nDivs: 7}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.GEOLOCATION_ACCURACY,
		label: 'Geolocation Accuracy',
		categoryName: 'Geolocation',
		units:'m',
		colorScale: {
			type: 'ColorScale', 
			config: {legendTitle: '<units>', range: [0., 10000], nDivs: 8}
		}
	}},
	{type: 'DataLayer', config: {
		dataType: wzDataEnums.FULL_REPORT,
		label: 'Any Available Data',
		categoryName: 'Misc.',
	}},
];

/* 
 * SET THE DEFAULT MAPS:
 */
let DEFAULT_MAP_DATA = [
	{dataLayer: 'Images'}
];

/*
 * THE FINAL DEFAULT CONFIG OBJECT, TO BE EXPORTED TO INDEX.TS:
 */
export const DEFAULT_CONFIG = {
	startTime: default_start_time,
	endTime: default_end_time,
	boundingBox: conus,
	sources: DEFAULT_SOURCES,
	dataLayers: DEFAULT_DATA_LAYERS,
	mapData: DEFAULT_MAP_DATA,
	timeControl: {timeShiftValue: moment.duration(1,'day').toJSON()},
	showRadar: SHOW_RADAR_BY_DEFAULT,
}